import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Container, Dropdown, Modal, Row, Tab, Tabs } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import { frontEnd_API, header, siteConfig } from '../Config/Config';
import axios from 'axios';
import { TbFileInvoice, TbMilitaryRank, TbTruckReturn } from 'react-icons/tb';
import Swal from 'sweetalert2';
import moment from 'moment';
import Loader from './Loader';
import CommonPagination from './CommonPagination';
import { IoIosNotifications, IoIosNotificationsOff } from 'react-icons/io';

function NotificationList() {

    const navigate = useNavigate();
    const [orders, setOrders] = useState();
    const [totalList, setTotalList] = useState();
    const [filters, setFilters] = useState();
    const [filterList, setFilterList] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [loader, setLoader] = useState(true);

    const getNotificationList = async () => {
        try {

            const response = await axios.put(frontEnd_API.notification, { ...filters }, header)
            if (response?.status == 200) {
                setOrders(response?.data?.data);
                setTotalList(response?.data?.totalCount);
                setLoader(false);
            }
        }
        catch (e) {
            console.log("e::", e);
            setTotalList(10);
            setOrders([]);
            setLoader(false);
        }
    }

    const getGlobalState = async () => {
        try {
            const { data } = await axios.get(frontEnd_API.getGlobal, header)
            setFilterList((prevData) => ({
                ...prevData,
                parentData: [
                    {
                        title: 'stateName',
                        data: data?.data
                    }
                ]
            }));
        }
        catch (e) {
            console.log("Err::", e);
        }
    }

    useEffect(() => {
        getNotificationList();
    }, [filters])

    useEffect(() => {
        getGlobalState();
    }, [])

    useEffect(() => {
      getNotificationList();
      const intervalId = setInterval(getNotificationList, 10000);
      return () => clearInterval(intervalId);
  }, [])

    const handleCheckOrder = (item) => {
        const tempData = filters[item?.title] ?? [];

        const newData = (tempData?.length > 0) ? tempData.find((e) => e == item?.value) : null;
        if (newData) {
            const check = filters[item?.title].filter((e) => e != item?.value);
            setFilters((prevData) => ({
                ...prevData,
                [item?.title]: check
            }))
        }
        else {
            if (item?.value == '') {
                const data = filterList?.parentData?.find((e) => e?.title == item?.title);
                if (data?.data?.length == filters[item?.title]?.length) {
                    setFilters((prevData) => ({
                        ...prevData,
                        [item?.title]: []
                    }));
                }
                else {
                    const check = data?.data.map((prevData) => (
                        prevData?.value
                    ));
                    setFilters((prevData) => ({
                        ...prevData,
                        [item?.title]: check
                    }));
                }
            }
            else {
                setFilters((prevData) => ({
                    ...prevData,
                    [item?.title]: [...tempData, item?.value]
                }))
            }
        }

    }

    const handleOrderStatus = async (item) => {
        try {
            if (item?.value == "seller-cancel") {
                Swal.fire({
                    title: `Are You sure to cancel this order!`,
                    text: `This action can't be reversed.`,
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#12715B',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const response = await axios.put(`${frontEnd_API?.orders}/status/${item?.id}`, {
                            orderStatus: item?.value,
                        }, header)
                        if (response?.status == 200) {
                            Swal.fire(
                                'Order Cancelled Successfully!',
                                'Your selected order is cancelled!',
                                'success'
                            );
                            getNotificationList();
                        }
                    }
                });
            }
            else {
                const response = await axios.put(`${frontEnd_API?.orders}/status/${item?.id}`, {
                    orderStatus: item?.value,
                }, header)
                if (response?.status == 200) {
                    Swal.fire(
                        `Order ${(item?.value == "seller-cancel") ? "Cancelled" : "Approved"} Successfully!`,
                        `Your selected order is ${(item?.value == "seller-cancel") ? "cancelled" : "approved"}!`,
                        'success'
                    );
                    getNotificationList();
                }
            }
        }
        catch (e) {
            Swal.fire(
                `Request failed`,
                `${e?.response?.data?.message}`,
                'error'
            );
            console.log("Err::", e);
        }
    }

    const handleNotificationStatus = async (id) => {
            console.log("id::", id);
            try {
            const { data } = await axios.get(`${frontEnd_API?.notification}/${id}`, header)
            getNotificationList();
            console.log("data::", data);
        }
        catch (e) {
            console.log("Err::", e);
        }
    }

    document.title = `Seller Notifications | ${siteConfig?.name}`;
    return (
        <div className='seller-order p-0' style={{ minHeight: "100vh" }}>
            <Container className='seller-order-main-container pb-5'>
                <div className=' d-grid d-sm-flex justify-content-sm-between gap-3 py-4'>
                    <h3 className='fw-semibold m-0 d-flex gap-2 align-items-center'>
                        <span className='fs-35 color-temp'><IoIosNotifications /></span>
                        Notification List :
                    </h3>
                </div>
                <div className='seller-order-container p-2 p-sm-3'>
                    <div className='py-2 d-grid gap-3 custom-btn-scrollbar'>
                        {/* Custom Filtration */}
                        {/* <div className='d-flex align-items-center gap-2 d-md-grid'>
                  <Container className='custom-seller-category-search px-0'>
                    <div className='d-flex align-items-center gap-2 gap-lg-3 px-2 px-lg-3'>
                      <label className='bi bi-search' htmlFor='text'></label>
                      <input
                        type="text"
                        className='py-2 fs-sm-14 pe-2'
                        id='text'
                        placeholder='Search by withdraw status'
                        autoComplete='off'
                        onChange={(e) => {
                          setFilters((prevData) => ({
                            ...prevData,
                            search: e?.target?.value
                          }))
                        }}
                      />
                    </div>
                  </Container>
                  <div className='d-block d-md-none'
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowFilter(!showFilter)}
                  ><i className="bi bi-funnel-fill px-3 py-2 seller-box"></i></div>


                  <Modal
                    show={showFilter}
                    onHide={() => setShowFilter(!showFilter)}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Filter Invoices</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='d-grid gap-3'>
                        {
                          (filterList?.parentData?.length > 0) &&
                          filterList?.parentData?.map((item, index) => {
                            return (
                              <div key={index} className={`d-grid w-100 custom-select-option`}>
                                <Dropdown className='d-inline w-100' autoClose="outside">
                                  <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                                    {item.title}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className='w-100 gap-1 check-holder'>
                                    {
                                      item?.data.map((subItem, subIndex) => {
                                        return (
                                          <Dropdown.Item key={subIndex} as={'div'} className='flex-between-align gap-1 gap-md-2 justify-content-start w-100'>
                                            <input type="checkbox"
                                              className='ui-checkbox'
                                              name={subItem?.label}
                                              id={subItem?.label}
                                              value={subItem?.value}
                                              onChange={() => handleCheckOrder({
                                                value: subItem?.value,
                                                title: item?.title
                                              })} />

                                            <label htmlFor={subItem?.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{subItem?.label}</label>
                                          </Dropdown.Item>
                                        )
                                      })
                                    }
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            )
                          })
                        }
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={() => setShowFilter(false)}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={() => setShowFilter(false)}>Apply Filters</Button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <div className='d-none d-md-flex'>
                  {
                    (filterList?.parentData?.length > 0) &&
                    filterList?.parentData?.map((item, index) => {
                      return (
                        <div key={index} className={`d-grid ${(filterList?.parentData?.length == 1) ? 'w-25' : 'w-100'} custom-select-option`}>
                          <Dropdown className='d-inline w-100' autoClose="outside">
                            <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                              {item.title}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className='w-100 gap-1 check-holder'>
                              {
                                item?.data.map((subItem, subIndex) => {
                                  return (
                                    <Dropdown.Item key={subIndex} as={'div'} className='flex-between-align gap-1 gap-md-2 justify-content-start w-100'>
                                      <input type="checkbox"
                                        className='ui-checkbox'
                                        name={subItem?.label}
                                        id={subItem?.label}
                                        value={subItem?.value}
                                        onChange={() => handleCheckOrder({
                                          value: subItem?.value,
                                          title: item?.title
                                        })} />

                                      <label htmlFor={subItem?.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{subItem?.label}</label>
                                    </Dropdown.Item>
                                  )
                                })
                              }
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      )
                    })
                  }
                </div> */}

                        <Container className='custom-order-list'>
                            <Row className='d-none d-md-flex custom-order-list-filter-bar py-3 flex-between-align mb-2 mb-md-0'>
                                <Col className='col-1'>Status</Col>
                                <Col className='col-2'>Date-Time</Col>
                                <Col className='col-3 text-center'>Title</Col>
                                <Col className='col-6'>Description</Col>
                            </Row>
                            {/*  */}
                            {
                                loader ?
                                    <div className='w-100 aspect-4-1 aspect-md-2-1'>
                                        <Loader />
                                    </div>
                                    :
                                    (!orders?.length > 0)
                                        ? <div className='py-5'>
                                            <div className='custom-transaction-tab d-grid justify-content-center text-center gap-2'>
                                                <div>
                                                    <IoIosNotificationsOff />
                                                </div>
                                                <h5 className='fw-semibold'>No Notification yet !</h5>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            {
                                                orders.map((item, index) => {
                                                    return (
                                                        <Link
                                                            key={index}
                                                            className='row text-dark text-decoration-none custom-order-list-bar-item py-3 flex-between-align gy-3 gy-md-0 mb-2 pointer mb-md-0'
                                                            style={{ backgroundColor: (item?.notificationStatus != "READ") ? "#d5f5edab" : "" }}
                                                            onClick={() => handleNotificationStatus(item?.id)}
                                                        >
                                                            <Col className='col-2 col-md-1 d-grid align-content-center custom-list-active'>
                                                                {
                                                                    (item?.notificationStatus != "READ") ?
                                                                        <div className="point"></div>
                                                                        : <i className="text-success text-center bi bi-check-lg"></i>
                                                                }
                                                            </Col>
                                                            <Col className='col-10 col-md-2 text-end text-md-start'>
                                                                <span className='fs-12'>{item?.notificationTime}</span>
                                                            </Col>
                                                            <Col className='col-12 col-md-3 d-flex align-items-center gap-2 fs-sm-12'>
                                                                <div className='w-100 text-center fw-semibold'>
                                                                    {item?.notificationTitle}
                                                                </div>
                                                            </Col>
                                                            <Col className='col-12 col-md-6 d-grid gap-1'>
                                                                <div className=' p-0 fs-14 fs-sm-12 text-center text-md-start'>
                                                                    {item?.notificationDescription}
                                                                </div>
                                                            </Col>
                                                        </Link>
                                                    )
                                                })
                                            }
                                        </>
                            }
                        </Container>
                        <CommonPagination
                            totalCount={totalList}
                            changePage={(data) => {
                                setFilters((prevData) => ({
                                    ...prevData,
                                    limit: data?.limit,
                                    page: data?.page
                                }))
                            }}
                        />
                    </div>
                </div>
            </Container >
        </div >
    )
}

export default NotificationList