import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Container, Dropdown, Row } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from 'react-router-dom';
import { SiRazorpay } from "react-icons/si";
import FormComponent from '../../Components/FormComponent';
import { frontEnd_API, header, siteConfig } from '../../Config/Config';
import Modal from 'react-bootstrap/Modal';
import { GiCactusPot } from "react-icons/gi";
import { TbTransactionRupee } from "react-icons/tb";
import { openRazorpay } from '../../Components/InitializeRazorPay';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { updateUser } from '../../Store/Slices/UserSlice';
import CommonPagination from '../../Components/CommonPagination';
import Swal from 'sweetalert2'
import { TiEye } from "react-icons/ti";
import { withdrawFilterListLocal } from '../../Data/localData';
import { IoBanOutline } from 'react-icons/io5';
import { GoDotFill } from 'react-icons/go';
import { MdDateRange } from 'react-icons/md';
import { FaTrashCan, FaUser } from 'react-icons/fa6';
import { Tooltip } from 'react-tooltip';
import { IoLockClosedSharp } from "react-icons/io5";
import { PiPiggyBankFill, PiPiggyBankLight } from 'react-icons/pi';
import { BsBank } from "react-icons/bs";
import Loader from '../../Components/Loader';
import { tr } from 'date-fns/locale';
import { FaTimes } from 'react-icons/fa';

function SellerWallet() {
    const [show, setShow] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [calendar, setCalendar] = useState(false);
    const [showPrice, setShowPrice] = useState(false);
    const [filters, setFilters] = useState([]);
    const [amount, setAmount] = useState(0);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const store = useSelector((state) => state.store.value);
    const user = useSelector((state) => state.user.value);
    const dispatch = useDispatch();
    const commaNumber = require('comma-number')
    const [loader, setLoader] = useState({
        bank: true,
        transaction: true,
        withdraw: true,
    });

    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const handleChange = (event) => {
        const value = event.target.value;
        setFilters((prev) =>
            prev.includes(value)
                ? prev.filter((item) => item !== value)
                : [...prev, value]
        );
    }

    const handleDateChange = (e) => {
        console.log(e);
        setState([e]);
    }

    // Razorpay Integrate Code

    const handlePrice = (amt) => {
        setAmount(amt);
        console.log(amt);
    }

    // function for send wallet request ------------
    const submitRecharge = async (transactionNumber = '') => {
        if (transactionNumber) {
            try {
                const body = {
                    relatedId: store?.id,
                    relatedName: 'store',
                    amount,
                    transactionNumber,
                    particular: `Wallet Recharge with amount of ${amount} and generated transaction number ${transactionNumber}`,
                    type: 'credit',
                    walletType: "RECHARGE",
                    date: moment()?.format('YYYY-MM-DD')
                }
                const response = await axios.post(frontEnd_API?.sellerWallet, body, header);
                console.log('response from wallet:: ', response);
                getTransactionData();
                dispatch(updateUser({
                    walletBalance: response?.data?.data?.walletBalance,
                    withDrawBalance: response?.data?.data?.withDrawBalance
                }))
                setAmount(0)
                window.location.reload();
            } catch (error) {
                console.log('console.log :: error :: ', error)
            }
        }
    }
    // ------------

    const handlePayment = () => {
        const options = {
            key: "rzp_test_pPhRuH9aNA8DOL",
            amount: (amount * 100),
            currency: "INR",
            name: store?.name,
            description: "Test Transaction",
            image: store?.logo ?? require("../../Assets/Images/try-logo.png"),
            handler: function (response) {
                submitRecharge(response.razorpay_payment_id)

            },
            prefill: {
                name: store?.name,
                email: store?.emailAddress,
                contact: store?.mobileNumber,
            },
            notes: {
                address: store?.billingAddressFirstLine + ", " + store?.billingAddressSecondLine,
            },
            theme: {
                color: "#3399cc",
            },
        };

        openRazorpay(options);
    };

    const customToggle = () => {
        handlePayment();
        setShowPrice(!showPrice);
        setAmount(0);
    }

    useEffect(() => {
        setStartDate((state[0].startDate) && `${state[0]?.startDate.getDate()}/${state[0]?.startDate.getMonth() + 1}/${state[0]?.startDate.getFullYear()}`)

        setEndDate((state[0].endDate) && `${state[0]?.endDate.getDate()}/${state[0]?.endDate.getMonth() + 1}/${state[0]?.endDate.getFullYear()}`)
    }, [state, startDate, endDate])

    const handleClick = (val) => {
        setFilters((prevData) => prevData.filter(value => value !== val))
        setState([
            {
                startDate: '',
                endDate: '',
                key: 'selection'
            }
        ]);
    }

    const getCurrentBalance = async () => {
        try {
            const { data } = await axios.get(`${frontEnd_API?.home}/balance`, header)
            console.log("Current Balance::", data);
            dispatch(updateUser({
                walletBalance: data?.data?.walletBalance,
                withDrawBalance: data?.data?.withDrawBalance
            }))
        }
        catch (e) {
            console.log("Err::", e);
        }
    }

    useEffect(() => {
        getCurrentBalance();
    }, [])

    // function for get transaction/wallet data ------------

    const [transactionData, setTransactionData] = useState()
    const [totalCount, setTotalCount] = useState()
    const [filterList, setFilterList] = useState([]);

    const getTransactionData = async () => {
        try {
            const { data } = await axios?.put(frontEnd_API?.sellerWallet, { ...filters }, header)
            setTransactionData([...data?.data])
            setTotalCount(data?.totalCount);
            setLoader((prevData) => ({
                ...prevData,
                transaction: false,
            }));
        } catch (error) {
            console.log("error::", error);
            setTransactionData([])
            setLoader((prevData) => ({
                ...prevData,
                transaction: false,
            }));
        }
    }

    useEffect(() => {
        getTransactionData()
    }, [filters])

    const getFilterData = async () => {
        try {
            const response = await axios?.get(`${frontEnd_API?.sellerWallet}/filter`, header);
            if (response?.status === 200) {
                setFilterList((prevData) => ({
                    ...prevData,
                    parentData: [
                        // {
                        //     title: 'Provider ID',
                        //     value: 'providerId',
                        //     data: response?.data?.data?.providerData
                        // },
                        {
                            title: 'Transaction Type',
                            value: 'walletType',
                            data: response?.data?.data?.walletTypeData
                        }
                    ]
                }));
                console.log("getProviderData::", response?.data?.data);
                setLoader((prevData) => ({
                    ...prevData,
                    transaction: false,
                }));
            }
        } catch (error) {
            console.log("error::", error);
            setLoader((prevData) => ({
                ...prevData,
                transaction: false,
            }));
        }
    }

    useEffect(() => {
        getFilterData();
    }, [])

    const handleCheckTransaction = (item) => {
        const tempData = filters[item?.title] ?? [];
        const newData = (tempData?.length > 0) ? tempData.find((e) => e == item?.value) : null;
        if (newData) {
            const check = filters[item?.title].filter((e) => e != item?.value);
            setFilters((prevData) => ({
                ...prevData,
                [item?.title]: check
            }))
        }
        else {
            if (item?.value == '') {
                const data = filterList?.parentData?.find((e) => e?.title == item?.title);
                if (data?.data?.length == filters[item?.title]?.length) {
                    setFilters((prevData) => ({
                        ...prevData,
                        [item?.title]: []
                    }));
                }
                else {
                    const check = data?.data.map((prevData) => (
                        prevData?.value
                    ));
                    setFilters((prevData) => ({
                        ...prevData,
                        [item?.title]: check
                    }));
                }
            }
            else {
                setFilters((prevData) => ({
                    ...prevData,
                    [item?.title]: [...tempData, item?.value]
                }))
            }
        }

    }

    // 
    // Withdraw code 
    // 

    const [withdrawList, setWithdrawList] = useState();
    const [withdrawTotalList, setWithdrawTotalList] = useState();
    const [withdrawFilters, setWithdrawFilters] = useState();
    const [withdrawData, setWithdrawData] = useState();
    const [withdrawFilterList, setWithdrawFilterList] = useState([]);
    const [showWithdrawFilter, setShowWithdrawFilter] = useState(false);
    const [withdrawBankModal, setWithdrawBankModal] = useState(false);
    const [withdrawModal, setWithdrawModal] = useState(false);
    const [withdrawModalData, setWithdrawModalData] = useState();

    const getWithdrawList = async () => {
        try {
            console.log("withdrawFilters::", { ...withdrawFilters });
            const response = await axios.put(frontEnd_API?.sellerwithdraw, { ...withdrawFilters }, header);
            if (response?.status == 200) {
                setWithdrawList(response?.data?.data);
                setWithdrawTotalList(response?.data?.totalCount);
                setWithdrawFilterList((prevData) => ({
                    ...prevData,
                    parentData: [
                        {
                            label: 'Withdraw Status',
                            title: 'withDrawStatus',
                            data: withdrawFilterListLocal?.data
                        }
                    ]
                }));
                console.log("response?.data?.data::", response?.data);
            }
        }
        catch (e) {
            console.log("Err::", e);
        }
    }

    useEffect(() => {
        getWithdrawList();
    }, [withdrawFilters])

    const handleWithdraw = async () => {
        if (withdrawData?.sellerBankId) {
            try {
                const response = await axios.post(frontEnd_API?.sellerwithdraw, {
                    amount: withdrawData?.price,
                    sellerBankId: withdrawData?.sellerBankId,
                    walletType: "WITHDRAW_REQUEST",
                    date: moment().format('YYYY-MM-DD')
                }, header)
                if (response?.status == 200) {
                    Swal.fire(
                        'Request Placed!',
                        'Your request has been successfully placed.',
                        'success'
                    );
                    setWithdrawData({
                        price: 0
                    });
                    getWithdrawList();
                    getCurrentBalance();
                }
            }
            catch (e) {
                console.log("Err::", e);
            }
        }
        else {
            Swal.fire({
                title: 'Select Bank Account!',
                text: 'Please select a bank account to proceed.',
                icon: 'error',
                confirmButtonText: 'OK'
            }).then((result) => {
                if (result.isConfirmed) {
                    setWithdrawBankModal(true);
                }
            });
        }
    }

    const handleCheckWithdraw = (item) => {
        const tempData = withdrawFilters[item?.title] ?? [];

        const newData = (tempData?.length > 0) ? tempData.find((e) => e == item?.value) : null;
        if (newData) {
            const check = withdrawFilters[item?.title].filter((e) => e != item?.value);
            setWithdrawFilters((prevData) => ({
                ...prevData,
                [item?.title]: check
            }))
        }
        else {
            if (item?.value == '') {
                const data = withdrawFilterList?.parentData?.find((e) => e?.title == item?.title);
                if (data?.data?.length == withdrawFilters[item?.title]?.length) {
                    setWithdrawFilters((prevData) => ({
                        ...prevData,
                        [item?.title]: []
                    }));
                }
                else {
                    const check = data?.data.map((prevData) => (
                        prevData?.value
                    ));
                    setWithdrawFilters((prevData) => ({
                        ...prevData,
                        [item?.title]: check
                    }));
                }
            }
            else {
                setWithdrawFilters((prevData) => ({
                    ...prevData,
                    [item?.title]: [...tempData, item?.value]
                }))
            }
        }

    }

    const hanldeWithdrawModal = async (item) => {
        console.log("item::", item?.id);
        try {
            const { data } = await axios.get(`${frontEnd_API?.sellerwithdraw}/${item?.id}`, header);
            console.log("data::", data?.data);
            setWithdrawModal(true);
            setWithdrawModalData(data?.data);
        }
        catch (e) {
            console.log("Err::", e);
            setWithdrawModal(false);
            setWithdrawModalData(null);
        }
    }

    const cancelWithdraw = async (item) => {
        if (["PENDING", "REQUEST"].includes(item?.withDrawStatus)) {
            try {
                Swal.fire({
                    title: `Are you sure to cancel request?`,
                    // text: `This action cannot be undone. Once deleted, the request will be permanently removed from the system.`,
                    text: `This action cannot be undone. Once cancelled.`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#12715B',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const response = await axios.delete(`${frontEnd_API?.sellerwithdraw}/${item?.id}`, header);
                        console.log("data::", response?.data);
                        if (response?.status == 200) {
                            Swal.fire(
                                'Request Cancelled!',
                                'Your request has been cancelled successfully.',
                                'success'
                            );
                            getCurrentBalance();
                            getWithdrawList();
                        }
                    }
                });
            }
            catch (e) {
                console.log("Err::", e);
            }
        }
    }

    // 
    // Bank code 
    // 

    const [bankList, setBankList] = useState([]);

    const getBankList = async () => {
        try {
            const { data } = await axios.put(frontEnd_API.bank, {}, header)
            console.log("ListData::", data);
            if (data?.data?.non_field_error) {
                setBankList([]);
            }
            else {
                setBankList(data?.data);
            }
            setLoader((prevData) => ({
                ...prevData,
                bank: false,
            }));
        }
        catch (e) {
            console.log("err::", e);
            setBankList([]);
            setLoader((prevData) => ({
                ...prevData,
                bank: false,
            }));
        }
        setUpdateBank();
        setUpdateBankId();
    }

    useEffect(() => {
        getBankList();
    }, []);

    useEffect(() => {
        console.log("bannerList::", bankList);
    }, [bankList]);

    const deleteBank = async (item) => {
        Swal.fire({
            title: `Are you sure to remove Bank Account?`,
            text: `This action cannot be undone.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#12715B',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoader((prevData) => ({
                    ...prevData,
                    bank: true,
                }));
                try {
                    const response = await axios.delete(`${frontEnd_API.bank}/${item.id}`, header)
                    if (response?.status == 200) {
                        Swal.fire(
                            'Back Account Removed Successfully!',
                            'Your bank account has been removed from store successfully.',
                            'success'
                        );
                        getBankList();
                        setLoader((prevData) => ({
                            ...prevData,
                            bank: false,
                        }));
                    }
                }
                catch (e) {
                    console.log("Err::", e);
                    setLoader((prevData) => ({
                        ...prevData,
                        bank: false,
                    }));
                }
            }
        });

    }

    const [updateBank, setUpdateBank] = useState();
    const [updateBankId, setUpdateBankId] = useState();

    const handleUpdateBank = (item) => {
        setUpdateBank({
            'accountHolderName': item?.accountHolderName,
            'accountNumber': item?.accountNumber,
            'bankName': item?.bankName,
            'ifscCode': item?.ifscCode
        })
        setUpdateBankId(item.id);
        console.log("item::", item);
        return item.id;
    }

    useEffect(() => {
        console.log(updateBank);
    }, [updateBank]);

    const handleSelectBank = () => {
        // if (withdrawData?.price > 0 && withdrawData?.price <= user?.withDrawBalance) {
        getBankList();
        setWithdrawBankModal(true);
        // }
        // else {
        //     if (withdrawData?.price > user?.withDrawBalance) {
        //         Swal.fire('Enter currect amount!', 'Your entered amount is greater then withdraw balance.', 'error');
        //     }
        //     else {
        //         Swal.fire('Enter currect amount!', 'For create withdraw request, enter currect amount.', 'error');
        //     }
        // }
    }

    useEffect(() => {
        console.log("withdrawData::", withdrawData);
    }, [withdrawData])

    const [changeTab, setChangeTab] = useState("payment-details");

    document.title = `Seller Wallet | ${siteConfig?.name}`;

    return (
        <div className='seller-wallet p-0'>
            <Container className='seller-wallet-main-container pb-5'>
                <h2 className='fw-bold py-4 m-0'>Payments</h2>
                <div className='seller-wallet-container p-2 p-sm-3'>
                    <Tabs
                        activeKey={changeTab}
                        onSelect={(key) => setChangeTab(key)}
                        id="fill-tab-example"
                        className="mb-3 custom-tabs"
                        fill
                    >
                        <Tab eventKey="payment-details" title="Payment Details">
                            <div className='py-2 pb-4 d-grid gap-4'>
                                <div className='d-grid gap-1'>
                                    <h5 className='fw-semibold m-0'>Printfuse  Balance</h5>
                                    <div className='d-flex align-items-start align-items-sm-center gap-2'>
                                        <i className="bi bi-check-circle color-info"></i>
                                        <span className='fs-14 fs-sm-12'>Effortlessly cover order and production costs</span>
                                    </div>
                                    <div className='d-flex align-items-start align-items-sm-center gap-2'>
                                        <i className="bi bi-check-circle color-info"></i>
                                        <span className='fs-14 fs-sm-12'>Streamlined order processing without the hassle of card payments</span>
                                    </div>
                                    <div className='d-flex align-items-start align-items-sm-center gap-2'>
                                        <i className="bi bi-check-circle color-info"></i>
                                        <span className='fs-14 fs-sm-12'>Avoid extra fees for transactions and currency conversions</span>
                                    </div>
                                </div>
                                <Container className='seller-wallet-payment pb-3'>
                                    <Row className='half-border-rad'>
                                        <Col className='seller-wallet-payment-sub p-3 col-12 col-lg-6 d-grid d-lg-none gap-1'>
                                            <div className='w-100 flex-center-align'>
                                                <div className='seller-wallet-payment-sub-img'>
                                                    <img src={require('../../Assets/Images/try-logo.png')} alt="" />
                                                </div>
                                            </div>
                                            <h4 className='fw-semibold fs-lg-18 m-0'>Printfuse Balance</h4>
                                            <h6 className='fw-medium fs-14'>Trusted by 1K+ merchants</h6>
                                        </Col>
                                        <Col className='seller-wallet-payment-main p-3 col-12 col-lg-6 '>
                                            <h6 className='fw-semibold fs-14 '>Current Balance</h6>
                                            <h4 className='fw-semibold fs-lg-18'>₹{commaNumber(user?.walletBalance) ?? 0}</h4>
                                            <h6 className='fw-semibold fs-14 '>Recharge with:</h6>

                                            {/*  */}
                                            {/* RazorPay Link */}
                                            {/*  */}
                                            <Link className='flex-center-align fw-semibold gap-2' onClick={() => setShowPrice(!showPrice)}><SiRazorpay />RazorPay</Link>
                                            <Modal
                                                show={showPrice}
                                                size="lg"
                                                onHide={() => setShowPrice(!showPrice)}
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered>
                                                <Modal.Header closeButton>
                                                    <Modal.Title> <div className='fw-semibold fs-20 fs-lg-16'><SiRazorpay className='color-info' /><span className='ps-2'>RazorPay</span></div> </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className='d-grid'>
                                                        <label htmlFor="price">Enter Amount :</label>
                                                        <input
                                                            type="number"
                                                            className='py-2 px-3 seller-box'
                                                            autoComplete='off'
                                                            placeholder='Enter min - ₹500'
                                                            id='price'
                                                            onChange={(e) => handlePrice(e.target.value)}
                                                        />
                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer className='d-grid' style={{ justifyContent: 'normal' }}>

                                                    <div className='d-grid gap-2'>
                                                        <Button variant="primary" className='fw-semibold' onClick={customToggle} disabled={(amount < 500)}>
                                                            Confirm
                                                        </Button>
                                                        <Button variant="dark" className='fw-semibold' onClick={() => setShowPrice(!showPrice)}>
                                                            Close
                                                        </Button>
                                                    </div>

                                                </Modal.Footer>
                                            </Modal>
                                        </Col>
                                        <Col className='seller-wallet-payment-sub p-3 col-12 col-lg-6 d-none d-lg-grid gap-1'>
                                            <div className='w-100 flex-center-align'>
                                                <div className='seller-wallet-payment-sub-img'>
                                                    <img src={require('../../Assets/Images/try-logo.png')} alt="" />
                                                </div>
                                            </div>
                                            <h4 className='fw-semibold fs-lg-18 m-0'>Printfuse Balance</h4>
                                            <h6 className='fw-medium fs-14'>Trusted by 1K+ merchants</h6>
                                        </Col>
                                    </Row>
                                </Container>
                                {/* <div className='seller-wallet-card pb-4 d-grid'>
                                    <h5 className='fw-semibold'>Payment Card</h5>
                                    <p className='fs-14 fs-sm-12'>Add a credit/debit card for use when there are not enough funds in your Printfuse balance..</p>
                                    <Link className='fw-semibold d-flex gap-2' onClick={() => setShow(!show)}><i className="bi bi-plus-lg"></i>Add a credit/debit card</Link>
                                    <Modal
                                        show={show}
                                        onHide={() => setShow(!show)}
                                        backdrop="static"
                                        keyboard={false}
                                        className='seller-wallet-card'
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title> <span className='fw-semibold fs-18'>Add a credit/debit card to your account</span> </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className='custom-trial-modal'>
                                            <FormComponent
                                                action={frontEnd_API.signup}
                                                submitFullWidth={true}
                                                fields={[
                                                    {
                                                        name: 'card number',
                                                        key: 'cardNumber',
                                                        placeholder: '0000 0000 0000 0000',
                                                        required: true,
                                                        pattern: pattern.cardNumber?.ptr,
                                                        mask: pattern.cardNumber?.mask
                                                    },
                                                    {
                                                        divClass: 'col-12 col-lg-6',
                                                        name: 'Expiry date',
                                                        key: 'expiryDate',
                                                        placeholder: 'MM/YY',
                                                        required: true,
                                                        pattern: pattern.expiryDate?.ptr,
                                                        mask: pattern.expiryDate?.mask
                                                    },
                                                    {
                                                        divClass: 'col-12 col-lg-6',
                                                        name: 'Security code (CVV)',
                                                        key: 'cvvNumber',
                                                        placeholder: '***',
                                                        required: true,
                                                        pattern: pattern.cvvNumber,
                                                    },
                                                    {
                                                        divClass: 'col-12',
                                                        name: 'Cardholder name',
                                                        key: 'cardHolderName',
                                                        placeholder: 'Name written on the card',
                                                        required: true,
                                                        pattern: pattern.cardHolderName,
                                                    },
                                                    {
                                                        divClass: 'col-12 col-lg-6',
                                                        name: 'Country',
                                                        key: 'country',
                                                        required: true,
                                                    },
                                                    {
                                                        divClass: 'col-12 col-lg-6',
                                                        name: 'State / Province',
                                                        key: 'state',
                                                        required: true,
                                                    },
                                                    {
                                                        name: 'Address 1',
                                                        key: 'address1',
                                                        required: true,
                                                    },
                                                    {
                                                        name: 'Address 2',
                                                        key: 'address2',
                                                        required: false,
                                                    },
                                                    {
                                                        divClass: 'col-12 col-lg-6',
                                                        name: 'City',
                                                        key: 'city',
                                                        required: true,
                                                    },
                                                    {
                                                        divClass: 'col-12 col-lg-6',
                                                        name: 'ZIP code',
                                                        key: 'ZIPCode',
                                                        required: true,
                                                        pattern: pattern.zipCode
                                                    },
                                                ]}
                                            />
                                        </Modal.Body>
                                    </Modal>
                                </div> */}
                                <div className='d-grid d-lg-flex gap-4 gap-lg-2'>
                                    <div className='seller-wallet-prm w-100'>
                                        <h5 className='fw-semibold'>Printfuse  Premium</h5>
                                        <p className='fs-14 fs-sm-12'>Earn more with Printfuse Premium. Subscribe today to get up to 20% discount on all products, reduced shipping costs and more snap store features.</p>
                                        <Link className='fw-semibold fs-sm-14' to={'/subscription'}>Subscribe</Link>
                                    </div>
                                    {/* <div className='seller-wallet-prm w-100 d-grid align-content-between'>
                                        <h5 className='fw-semibold'>Coupons</h5>
                                        <p className='fs-14 fs-sm-12'>Add and store your coupon codes here.</p>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <input type="text" placeholder='Enter Code' className='fs-sm-14 w-100 seller-box p-2' />
                                            <Link className='fw-semibold fs-sm-14'>Apply</Link>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="bank-accounts" title="Bank Accounts" className='p-2 p-sm-3 custom-store-setting'
                            style={{ backgroundColor: "transparent", minHeight: "auto" }}>
                            <div className='d-grid gap-3'>
                                {/* Top Content */}
                                <div className='d-grid gap-2 px-2'>
                                    <h4 className='fw-semibold m-0'>
                                        Manage Bank Accounts
                                    </h4>
                                    <p className='fs-sm-12 fs-lg-14 m-0 p-color'>
                                        {/* {
                                            (bankList?.length > 4) ?
                                                <span>You can't add more bank accounts.</span>
                                                :
                                                <span>
                                                    You can add {5 - bankList?.length} more bank accounts.
                                                </span>
                                        }
                                        The maximum limit is 5. */}
                                        You can add up to 5 bank account details only.
                                    </p>
                                </div>
                                <Container className='px-sm-0 py-sm-4 '>
                                    <Row className='gy-4'>
                                        {
                                            (bankList?.length < 5) &&
                                            <Col className='col-12 d-grid gap-3 pb-3'>
                                                <h5 className='fw-semibold d-flex gap-2 align-items-end fs-sm-16'>
                                                    <BsBank className='fs-30 fs-sm-20 color-temp' />
                                                    Add New Bank Account :
                                                </h5>
                                                <div className='custom-border-box p-3'>
                                                    <FormComponent
                                                        action={frontEnd_API.bank}
                                                        valueData={(updateBank) && updateBank}
                                                        actionType={`${(updateBank) ? 'put' : 'post'}`}
                                                        actionValue={(updateBank) && updateBankId}
                                                        submitFullWidth={false}
                                                        onSubmit={(e) => getBankList(e)}
                                                        formMessage={(updateBank) ? "Bank Updated in store !" : "Bank Added to store !"}
                                                        fields={[
                                                            {
                                                                divClass: 'col-12 col-md-6',
                                                                name: 'Bank Name',
                                                                key: 'bankName',
                                                                required: true,
                                                                placeholder: 'Your name',
                                                                placeholder: 'Enter Valid Bank Name',
                                                            },
                                                            {
                                                                divClass: 'col-12 col-md-6',
                                                                name: 'Holder Name',
                                                                key: 'accountHolderName',
                                                                required: true,
                                                                placeholder: 'Enter Valid Holder Name',
                                                                placeholder: 'Your name',
                                                            },
                                                            {
                                                                divClass: 'col-12 col-md-6',
                                                                name: 'Account Number',
                                                                key: 'accountNumber',
                                                                placeholder: 'Enter Account No.',
                                                                required: true,
                                                            },
                                                            {
                                                                divClass: 'col-12 col-md-6',
                                                                name: 'IFSC Code',
                                                                key: 'ifscCode',
                                                                placeholder: 'Enter IFSC Code',
                                                                required: true,
                                                            }
                                                        ]}
                                                    />
                                                </div>
                                            </Col>
                                        }
                                        <Col className='col-12 d-grid px-0 px-sm-2 align-content-start overflow-scroll'>
                                            {
                                                (bankList.length == 0) ?
                                                    <h4 className='text-center p-color flex-center-align gap-3'>
                                                        <PiPiggyBankLight className='fs-45' />
                                                        No Bank Added
                                                    </h4>
                                                    : <div className='d-grid gap-3 mt-2'>
                                                        <h5 className='fw-semibold d-flex gap-2 align-items-end fs-sm-16'>
                                                            <PiPiggyBankFill className='fs-30 fs-sm-20 color-temp' />
                                                            Your Bank Accounts :
                                                        </h5>
                                                        <table className='cs-table'>
                                                            <thead>
                                                                <tr className='fs-sm-12'>
                                                                    <th className='text-center'>Bank Name</th>
                                                                    <th className='text-center'>Holder</th>
                                                                    <th className='text-center'>Acc. No</th>
                                                                    <th className='text-center'>IFSC Code</th>
                                                                    <th className='text-center'>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    loader?.bank ?
                                                                        <tr>
                                                                            <td colSpan={5}>
                                                                                <div className='w-100 aspect-4-1 aspect-md-2-1'>
                                                                                    <Loader />
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        : (bankList?.length > 0) &&
                                                                        bankList?.map((item, index) => {
                                                                            return (

                                                                                <tr key={index}>
                                                                                    <td className='text-center text-capitalize fs-14 fs-sm-8'>
                                                                                        {item?.bankName}
                                                                                    </td>
                                                                                    <td className='text-center text-capitalize fs-14 fs-sm-8'>
                                                                                        {item?.accountHolderName}
                                                                                    </td>
                                                                                    <td className='text-center text-capitalize fs-12 fs-sm-8'>
                                                                                        {item?.accountNumber}
                                                                                    </td>
                                                                                    <td className='text-center text-capitalize fs-12 fs-sm-8'>
                                                                                        {item?.ifscCode}
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className='flex-between-align custom-btns'>
                                                                                            <i className="fs-14 custom-btns-edit bi bi-pen-fill"
                                                                                                onClick={() => {
                                                                                                    handleUpdateBank(item)
                                                                                                }} />
                                                                                            <i className="fs-14 custom-btns-delete bi bi-trash-fill" onClick={() => {
                                                                                                deleteBank(item)
                                                                                            }} />
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                            }

                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Tab>
                        <Tab eventKey="transactions" title="Transactions">
                            <div className='d-grid gap-2'>
                                <div className='py-2 d-grid gap-3 custom-btn-scrollbar'>
                                    {/* Search Button */}
                                    <div className='d-flex align-items-center gap-2 d-lg-grid'>
                                        <Container className='custom-seller-category-search px-0'>
                                            <div className='d-flex align-items-center gap-2 gap-lg-3 px-2 px-lg-3'>
                                                <label className='bi bi-search' htmlFor='text'></label>
                                                <input
                                                    type="text"
                                                    className='py-2 fs-sm-14 pe-2'
                                                    id='text'
                                                    placeholder='Search by mode name or transaction description'
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        setFilters((prevData) => ({
                                                            ...prevData,
                                                            search: e?.target?.value
                                                        }))
                                                        setLoader((prevData) => ({
                                                            ...prevData,
                                                            transaction: true,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </Container>
                                        <div className='d-block d-lg-none'
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => setShowFilter(!showFilter)}
                                        ><i className="bi bi-funnel-fill px-3 py-2 seller-box"></i></div>


                                        {/* Filters */}
                                        <Modal
                                            show={showFilter}
                                            onHide={() => setShowFilter(!showFilter)}
                                            backdrop="static"
                                            keyboard={false}
                                        >
                                            <Modal.Header closeButton>
                                                <Modal.Title>Filter Invoices</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className='d-grid gap-3'>
                                                    {
                                                        (filterList?.parentData?.length > 0) &&
                                                        filterList?.parentData?.map((item, index) => {
                                                            return (
                                                                // <div key={index} className={`d-grid ${(filterList?.parentData?.length == 1) ? 'w-25' : 'w-100'} custom-select-option`}>
                                                                <div key={index} className={`d-grid w-100 custom-select-option`}>
                                                                    <Dropdown className='d-inline w-100' autoClose="outside">
                                                                        <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                                                                            {item.title}
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className='w-100 gap-1 check-holder'>
                                                                            {
                                                                                (item?.data?.length > 0) &&
                                                                                item?.data.map((subItem, subIndex) => {
                                                                                    return (
                                                                                        <Dropdown.Item key={subIndex} as={'div'} className='flex-between-align gap-1 gap-lg-2 justify-content-start w-100'>
                                                                                            <input type="checkbox"
                                                                                                className='ui-checkbox'
                                                                                                name={subItem.label}
                                                                                                id={subItem.label}
                                                                                                value={subItem.value}
                                                                                                checked={filters[item?.value]?.includes(subItem.value)}
                                                                                                onChange={() => {
                                                                                                    const arr = Object?.keys(filters)?.includes(item?.value) ? (Array?.isArray(filters[item?.value]) ? filters[item?.value] : []) : []
                                                                                                    const newArr = arr?.includes(subItem?.value) ? arr?.filter(e => e != subItem?.value) : [...arr, subItem?.value]
                                                                                                    filters[item?.value] = newArr
                                                                                                    console.log('1007 :: ', item, filters, newArr)
                                                                                                    setFilters({...filters})

                                                                                                    // handleCheckTransaction({
                                                                                                    //     value: subItem?.value,
                                                                                                    //     title: item?.value
                                                                                                    // })

                                                                                                    setLoader((prevData) => ({
                                                                                                        ...prevData,
                                                                                                        transaction: true,
                                                                                                    }));
                                                                                                }} />

                                                                                            <label htmlFor={subItem.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{subItem.label}</label>
                                                                                        </Dropdown.Item>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={() => setShowFilter(false)}>
                                                    Close
                                                </Button>
                                                <Button variant="primary" onClick={() => setShowFilter(false)}>Apply Filters</Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                    <div className='d-none d-lg-flex'>
                                        {
                                            (filterList?.parentData?.length > 0) &&
                                            filterList?.parentData?.map((item, index) => {
                                                return (
                                                    // <div key={index} className={`d-grid ${(filterList?.parentData?.length == 1) ? 'w-25' : 'w-100'} custom-select-option`}>
                                                    <div key={index} className={`d-grid w-100 custom-select-option`}>
                                                        <Dropdown className='d-inline w-100' autoClose="outside">
                                                            <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                                                                {item.title}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className='w-100 gap-1 check-holder'>
                                                                {
                                                                    (item?.data?.length > 0) &&
                                                                    item?.data.map((subItem, subIndex) => {
                                                                        return (
                                                                            <Dropdown.Item key={subIndex} as={'div'} className='flex-between-align gap-1 gap-lg-2 justify-content-start w-100'>
                                                                                <input type="checkbox"
                                                                                    className='ui-checkbox'
                                                                                    name={subItem.label}
                                                                                    id={subItem.label}
                                                                                    value={subItem.value}
                                                                                    // checked={filters[item?.title]?.includes(subItem.value) || (filters[item?.title]?.length - 1) == filterList?.parentData[item?.title]?.length}
                                                                                    checked={filters[item?.value]?.includes(subItem.value)}
                                                                                    onChange={() => {
                                                                                        const arr = Object?.keys(filters)?.includes(item?.value) ? (Array?.isArray(filters[item?.value]) ? filters[item?.value] : []) : []
                                                                                        const newArr = arr?.includes(subItem?.value) ? arr?.filter(e => e != subItem?.value) : [...arr, subItem?.value]
                                                                                        filters[item?.value] = newArr
                                                                                        console.log('1007 :: ', item, filters, newArr)
                                                                                        setFilters({...filters})

                                                                                        // handleCheckTransaction({
                                                                                        //     value: subItem?.value,
                                                                                        //     title: item?.title
                                                                                        // })

                                                                                        setLoader((prevData) => ({
                                                                                            ...prevData,
                                                                                            transaction: true,
                                                                                        }));
                                                                                    }} />

                                                                                <label htmlFor={subItem.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{subItem.label}</label>
                                                                            </Dropdown.Item>
                                                                        )
                                                                    })
                                                                }
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                {
                                    (transactionData?.length > 0) ? (
                                        <div className='pb-2'>
                                            <div className='custom-transaction-tab d-grid text-start text-capitalize'>
                                                {/* <h5 className='fw-semibold'>Transaction History</h5> */}
                                                {/* <hr className='m-0' /> */}
                                                <div className='d-grid gap-2 gap-sm-3'>
                                                    <div className='custom-withdrawlist g-0 d-grid'>
                                                        <div className='g-0 p-0 col-12 d-none d-lg-grid'>
                                                            <Row className='flex-between-align custom-withdrawlist-item py-3 px-3 fw-semibold text-capitalize'>
                                                                <Col className='col-8 d-grid d-lg-flex gap-2'>
                                                                    <span style={{ minWidth: '125px' }}>Date</span>
                                                                    <span className='w-100'>particular</span>
                                                                </Col>
                                                                <Col className='col-2'>Mode</Col>
                                                                <Col className='col-1'>debit</Col>
                                                                <Col className='col-1'>credit</Col>
                                                            </Row>
                                                            <hr className={`m-0`} />
                                                        </div>
                                                        {
                                                            loader?.transaction ?
                                                                <div className='w-100 aspect-4-1 aspect-md-2-1'>
                                                                    <Loader />
                                                                </div>
                                                                : transactionData?.length > 0 &&
                                                                transactionData.map((item, index) => (
                                                                    <div key={index} className='g-0 p-0 col-12 d-grid'>
                                                                        <Row className='flex-between-align custom-withdrawlist-item py-3 py-lg-2 px-3 gy-2 gy-lg-2'>
                                                                            <Col className='col-12 col-lg-8 d-grid d-lg-flex gap-2 fs-14 fs-lg-12 fs-sm-10'>
                                                                                <div className='flex-between-align gap-2' style={{ minWidth: '125px' }}>
                                                                                    <div>
                                                                                        <span className='d-lg-none fw-semibold'>Last Update on: </span>
                                                                                        {moment(item?.date).format('DD, MMM YYYY')}
                                                                                    </div>
                                                                                    <div className='d-flex d-lg-none align-items-center justify-content-end justify-content-lg-start'>
                                                                                        <Alert
                                                                                            className='m-0 text-center fw-semibold py-1 px-2 fs-12 fs-sm-10'
                                                                                            variant={
                                                                                                (item?.amount >= 0) ? "success" :
                                                                                                    (item?.amount <= -1) ? "danger" :
                                                                                                        "secondary"
                                                                                            }
                                                                                        >
                                                                                            {item?.walletType}
                                                                                        </Alert>

                                                                                    </div>
                                                                                </div>
                                                                                <div className='d-grid gap-1'>
                                                                                    <span className='d-lg-none fw-semibold'>Particular: </span>
                                                                                    {item?.particular}
                                                                                </div>
                                                                            </Col>
                                                                            <Col className='col-6 col-lg-2 d-none d-lg-grid'>
                                                                                <div className='d-flex align-items-center justify-content-end justify-content-lg-start'>
                                                                                    <Alert
                                                                                        className='m-0 text-center fw-semibold py-1 px-2 fs-12 fs-sm-10'
                                                                                        variant={
                                                                                            (item?.amount >= 0) ? "success" :
                                                                                                (item?.amount <= -1) ? "danger" :
                                                                                                    "secondary"
                                                                                        }
                                                                                    >
                                                                                        {item?.walletType}
                                                                                    </Alert>
                                                                                </div>
                                                                            </Col>
                                                                            <Col className='col-6 col-lg-1 fs-14 d-flex gap-1 fw-semibold'>
                                                                                <span className='d-lg-none'>Debit : </span>
                                                                                <span>
                                                                                    {item?.amount <= -1 ? Math.abs(item?.amount) : 0}
                                                                                </span>
                                                                            </Col>
                                                                            <Col className='col-6 col-lg-1 fs-14 d-flex gap-1 fw-semibold'>
                                                                                <span className='d-lg-none'>Credit : </span>
                                                                                <span>
                                                                                    {item?.amount >= 0 ? item?.amount : 0}
                                                                                </span>
                                                                            </Col>
                                                                        </Row>
                                                                        <hr className={`m-0 ${(withdrawList?.length == index + 1) ? "d-none" : ''}`} />
                                                                    </div>
                                                                ))
                                                        }
                                                    </div>
                                                    <CommonPagination
                                                        totalCount={totalCount}
                                                        changePage={(data) => {
                                                            setFilters((prevData) => ({
                                                                ...prevData,
                                                                limit: data?.limit,
                                                                page: data?.page
                                                            }))
                                                            setLoader((prevData) => ({
                                                                ...prevData,
                                                                transaction: true,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='py-2 flex-center-align py-5'>
                                            <div className='custom-transaction-tab d-grid justify-content-center text-center'>
                                                <div>
                                                    <GiCactusPot />
                                                </div>
                                                <h5 className='fw-semibold'>No transactions have yet been made</h5>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </Tab>
                        {/* <Tab eventKey="order-invoices" title="Order Invoices">
                            <div className='py-2 d-grid gap-3 custom-btn-scrollbar'>
                                <div>
                                    <h5 className='fw-semibold'>Order invoices</h5>
                                    <p className='p-color m-0 fs-14 fs-sm-12'>Per order invoicing provides individual invoices for each order. You can choose the frequency and format of your invoices by switching between Summary and Per order invoicing from the Settings page .</p>
                                </div>
                                <div className='py-5'>
                                    <div className='custom-transaction-tab d-grid justify-content-center text-center gap-2'>
                                        <div>
                                            <GiNotebook />
                                        </div>
                                        <h5 className='fw-semibold'>No invoices yet</h5>
                                        <p className='p-color fs-12'>Invoices will appear here after you fulfill orders.</p>
                                    </div>
                                </div>
                            </div>
                        </Tab> */}
                        <Tab eventKey="withdrawals" title="Withdrawals">
                            <div className='py-2'>
                                <div className='d-grid gap-2'>
                                    <h5 className='fw-semibold m-0'>Withdraw Balance</h5>
                                    <p className='p-color fs-15 fs-sm-12 m-0'>Withdraw money from your balance. Your request will be reviewed within next 48 business hours and you will receive email once it has been processed.</p>
                                    <div className='custom-withdraw-tab-balance d-grid gap-4 gap-sm-3 gap-lg-0 d-lg-flex justify-content-center justify-content-lg-between px-2 px-sm-3 py-4 text-start'>
                                        <div>
                                            <div className='d-grid gap-2 align-content-start'>
                                                <h6 className='fw-semibold m-0 fs-14'>Withdrawal Balance</h6>
                                                <h3 className='fw-bold m-0'>₹{commaNumber(user?.withDrawBalance) ?? 0}</h3>
                                            </div>
                                        </div>
                                        <div className='d-grid'>
                                            <h6 className='mb-1 mb-sm-0 fs-14 fw-semibold'>Withdraw amount:</h6>
                                            <div className='d-grid d-lg-flex align-items-center gap-2 gap-sm-3'>
                                                <div className='custom-rupee-input d-grid align-content-start'>
                                                    <div className='fw-semibold d-flex gap-1 fs-sm-14 p-1 p-sm-2'>
                                                        <label htmlFor='rupee'>₹</label>
                                                        <input
                                                            type="number"
                                                            id='rupee'
                                                            className='fw-semibold'
                                                            value={withdrawData?.price}
                                                            placeholder='Enter Amount'
                                                            onChange={(e) => setWithdrawData((prevData) => ({
                                                                ...prevData,
                                                                price: e.target.value
                                                            }))}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='d-grid text-center'>
                                                    <Link
                                                        className='custom-rupee-btn fw-semibold fs-sm-14 py-1 px-2 py-sm-2 px-sm-3'
                                                        onClick={() => handleSelectBank()}
                                                    >
                                                        Send Request
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-4 pb-2 py-sm-5 d-grid gap-3 gap-sm-4'>
                                    <h4 className='fw-semibold m-0 px-2 fs-sm-20'>Withdrawal Summary</h4>
                                    {/* Filtration Data List */}
                                    <div className='py-2 d-grid gap-3 custom-btn-scrollbar'>
                                        {/* Search Button */}
                                        <div className='d-flex align-items-center gap-2 d-lg-grid'>
                                            <Container className='custom-seller-category-search px-0'>
                                                <div className='d-flex align-items-center gap-2 gap-lg-3 px-2 px-lg-3'>
                                                    <label className='bi bi-search' htmlFor='text'></label>
                                                    <input
                                                        type="text"
                                                        className='py-2 fs-sm-14 pe-2'
                                                        id='text'
                                                        placeholder='Search by withdraw status'
                                                        autoComplete='off'
                                                        onChange={(e) => {
                                                            setWithdrawFilters((prevData) => ({
                                                                ...prevData,
                                                                search: e?.target?.value
                                                            }))
                                                        }}
                                                    />
                                                </div>
                                            </Container>
                                            <div className='d-block d-lg-none'
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => setShowWithdrawFilter(!showWithdrawFilter)}
                                            ><i className="bi bi-funnel-fill px-3 py-2 seller-box"></i></div>


                                            {/* Filters */}
                                            <Modal
                                                show={showWithdrawFilter}
                                                onHide={() => setShowWithdrawFilter(!showWithdrawFilter)}
                                                backdrop="static"
                                                keyboard={false}
                                            >
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Filter Invoices</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className='d-grid gap-3'>
                                                        {
                                                            (withdrawFilterList?.parentData?.length > 0) &&
                                                            withdrawFilterList?.parentData?.map((item, index) => {
                                                                return (
                                                                    <div key={index} className={`d-grid w-100 custom-select-option`}>
                                                                        <Dropdown className='d-inline w-100' autoClose="outside">
                                                                            <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                                                                                {item?.label}
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu className='w-100 gap-1 check-holder'>
                                                                                {
                                                                                    item?.data.map((subItem, subIndex) => {
                                                                                        return (
                                                                                            <Dropdown.Item key={subIndex} as={'div'} className='flex-between-align gap-1 gap-lg-2 justify-content-start w-100'>
                                                                                                <input type="checkbox"
                                                                                                    className='ui-checkbox'
                                                                                                    name={subItem?.label}
                                                                                                    id={subItem?.label}
                                                                                                    value={subItem?.value}
                                                                                                    onChange={() => handleCheckWithdraw({
                                                                                                        value: subItem?.value,
                                                                                                        title: item?.title
                                                                                                    })} />

                                                                                                <label htmlFor={subItem?.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{subItem?.label}</label>
                                                                                            </Dropdown.Item>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={() => setShowWithdrawFilter(false)}>
                                                        Close
                                                    </Button>
                                                    <Button variant="primary" onClick={() => setShowWithdrawFilter(false)}>Apply Filters</Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </div>
                                        <div className='d-none d-lg-grid gap-2'>
                                            <span className='fs-14 ps-2 ms-1'>Sort By:</span>
                                            <div className='d-none d-lg-flex'>
                                                {
                                                    (withdrawFilterList?.parentData?.length > 0) &&
                                                    withdrawFilterList?.parentData?.map((item, index) => {
                                                        return (
                                                            <div key={index} className={`d-grid ${(withdrawFilterList?.parentData?.length == 1) ? 'w-25' : 'w-100'} custom-select-option`}>
                                                                <Dropdown className='d-inline w-100' autoClose="outside">
                                                                    <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                                                                        {item?.label}
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className='w-100 gap-1 check-holder'>
                                                                        {
                                                                            item?.data.map((subItem, subIndex) => {
                                                                                return (
                                                                                    <Dropdown.Item key={subIndex} as={'div'} className='flex-between-align gap-1 gap-lg-2 justify-content-start w-100'>
                                                                                        <input type="checkbox"
                                                                                            className='ui-checkbox'
                                                                                            name={subItem?.label}
                                                                                            id={subItem?.label}
                                                                                            value={subItem?.value}
                                                                                            onChange={() => handleCheckWithdraw({
                                                                                                value: subItem?.value,
                                                                                                title: item?.title
                                                                                            })} />

                                                                                        <label htmlFor={subItem?.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{subItem?.label}</label>
                                                                                    </Dropdown.Item>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        withdrawList?.length > 0 ?
                                            <div className=''>
                                                <div className='custom-withdrawlist g-0 d-grid'>
                                                    <div className='g-0 p-0 col-12 d-none d-lg-grid'>
                                                        <Row className='flex-between-align custom-withdrawlist-item py-3 px-3 fw-semibold'>
                                                            <Col className='col-3'>Date</Col>
                                                            <Col className='col-3'>Amount</Col>
                                                            <Col className='col-2 col-xl-3'>Status</Col>
                                                            <Col className='col-3 col-xl-2 text-center'>View History</Col>
                                                            <Col className='col-1 text-center'><FaTrashCan /></Col>
                                                        </Row>
                                                        <hr className={`m-0`} />
                                                    </div>
                                                    {
                                                        withdrawList.map((item, index) => (
                                                            <div key={index} className='g-0 p-0 col-12 d-grid'>
                                                                <Row className='flex-between-align custom-withdrawlist-item py-4 py-lg-2 px-3 gy-3 gy-lg-2'>
                                                                    <Col className='col-6 col-lg-3 fs-14 fs-lg-12 fs-sm-10'>
                                                                        <span className='d-lg-none fw-semibold'>Last Update on: </span>
                                                                        {moment(item?.date).format('DD, MMM YYYY')}
                                                                    </Col>
                                                                    <Col className='col-6 d-grid d-lg-none'>
                                                                        <div className='d-flex justify-content-end'>
                                                                            <Button
                                                                                variant={["PENDING", "REQUEST"].includes(item?.withDrawStatus) ? 'danger' : 'secondary'}
                                                                                data-tooltip-id={`${!["PENDING", "REQUEST"].includes(item?.withDrawStatus) ? "dlt-tooltip" : ""}`}
                                                                                data-tooltip-content="Unable to delete"
                                                                                style={(!["PENDING", "REQUEST"].includes(item?.withDrawStatus)) ? { cursor: "default", backgroundColor: "#6C757D" } : {}}
                                                                                className='fs-12'
                                                                                onClick={() => cancelWithdraw(item)}
                                                                            >
                                                                                {
                                                                                    (["PENDING", "REQUEST"].includes(item?.withDrawStatus)) ?
                                                                                        <FaTrashCan />
                                                                                        : <>
                                                                                            <IoLockClosedSharp />
                                                                                        </>
                                                                                }
                                                                            </Button>
                                                                            <Tooltip id="dlt-tooltip" place="right" />
                                                                        </div>
                                                                    </Col>
                                                                    <Col className='col-6 col-lg-3 fs-16 fs-lg-14 fw-semibold'>
                                                                        <span className='d-lg-none fw-semibold'>Amt. : </span>
                                                                        ₹{commaNumber(item?.amount)}
                                                                    </Col>
                                                                    <Col className='col-6 col-lg-2 col-xl-3'>
                                                                        <div className='d-flex align-items-center justify-content-end justify-content-lg-start'>
                                                                            <Alert
                                                                                className='m-0 text-center fw-semibold py-1 px-2 fs-14 fs-sm-12'
                                                                                variant={
                                                                                    ["REQUEST"]?.includes(item?.withDrawStatus) ? "primary" :
                                                                                        ["ACCEPT", "COMPLETED", "COMPLETE"]?.includes(item?.withDrawStatus) ? "success" :
                                                                                            ["PENDING", "ONHOLD"]?.includes(item?.withDrawStatus) ? "warning" :
                                                                                                ["CANCEL", "REJECTED", "CANCELLED"]?.includes(item?.withDrawStatus) ? "danger" :
                                                                                                    "secondary"
                                                                                }
                                                                            >
                                                                                {item?.withDrawStatus}
                                                                            </Alert>

                                                                        </div>
                                                                    </Col>
                                                                    <Col className='col-12 col-lg-3 col-xl-2 fs-14 fs-lg-12 fs-sm-10 d-grid gap-1'>
                                                                        {/* <span className='d-lg-none fw-semibold'>Remark : </span> */}
                                                                        <span className='d-grid d-lg-flex justify-content-lg-center'>
                                                                            <Button
                                                                                variant="success"
                                                                                className='flex-center-align gap-2'
                                                                                onClick={() => { hanldeWithdrawModal(item) }}
                                                                            >
                                                                                <TiEye />
                                                                                View History
                                                                            </Button>
                                                                        </span>
                                                                    </Col>
                                                                    <Col className='col-6 col-lg-1 d-none d-lg-grid'>
                                                                        <div className='flex-center-align'>
                                                                            <Button
                                                                                variant={["PENDING", "REQUEST"].includes(item?.withDrawStatus) ? 'danger' : 'secondary'}
                                                                                // data-tooltip-id={`${!["PENDING", "REQUEST"].includes(item?.withDrawStatus) ? "dlt-tooltip" : ""}`}
                                                                                // data-tooltip-content="Request cancelled"
                                                                                style={(!["PENDING", "REQUEST"].includes(item?.withDrawStatus)) ? { cursor: "default", backgroundColor: "#6C757D" } : {}}
                                                                                onClick={() => cancelWithdraw(item)}
                                                                            >
                                                                                {
                                                                                    (["PENDING", "REQUEST"].includes(item?.withDrawStatus)) ?
                                                                                        <FaTimes />
                                                                                        : <>-</>
                                                                                }
                                                                            </Button>
                                                                            <Tooltip id="dlt-tooltip" place="right" />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <hr className={`m-0 ${(withdrawList?.length == index + 1) ? "d-none" : ''}`} />
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                <div className='g-0 p-0 d-grid py-3'>
                                                    <CommonPagination
                                                        totalCount={withdrawTotalList}
                                                        changePage={(data) => {
                                                            setWithdrawFilters((prevData) => ({
                                                                ...prevData,
                                                                limit: data?.limit,
                                                                page: data?.page
                                                            }))
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            : <div className='custom-transaction-tab d-grid gap-2 justify-content-center text-center py-5'>
                                                <div>
                                                    <TbTransactionRupee />
                                                </div>
                                                <h5 className='fw-semibold fs-sm-12'>No transactions have yet been made</h5>
                                            </div>
                                    }
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="taxes" title="Taxes">
                            <div className='py-2'>
                                <div className="taxes-page d-grid gap-3">
                                    <h2 className='fw-bold'>GST in India</h2>

                                    <section>
                                        <h4 className='fw-bold'>Introduction to GST</h4>
                                        <ul>
                                            <li>The Goods and Services Tax (GST) is an indirect tax used in India on the supply of goods and services.</li>
                                            <li>It is a comprehensive, multi-stage, destination-based tax.</li>
                                            <li>GST is categorized into CGST (Central GST), SGST (State GST), and IGST (Integrated GST).</li>
                                        </ul>
                                    </section>

                                    <section>
                                        <h4 className='fw-bold'>GST Rates for Different Products</h4>
                                        <ul>
                                            <li>
                                                <h6 className='fw-bold'>Clothing</h6>
                                                <ul>
                                                    <li>Garments and Apparel (below ₹1000): 5%</li>
                                                    <li>Garments and Apparel (above ₹1000): 12%</li>
                                                    <li>Fabric: 5%</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h6 className='fw-bold'>Mugs</h6>
                                                <ul>
                                                    <li>Ceramic mugs: 12%</li>
                                                    <li>Glass mugs: 18%</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </section>

                                    <section>
                                        <h4 className='fw-bold'>State-wise GST Variations</h4>
                                        <ul>
                                            <li>
                                                <h6 className='fw-bold'>Maharashtra</h6>
                                                <ul>
                                                    <li>Standard GST rates apply.</li>
                                                    <li>Additional requirements: E-way bill for movement of goods.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h6 className='fw-bold'>Karnataka</h6>
                                                <ul>
                                                    <li>Standard GST rates apply.</li>
                                                    <li>Additional requirements: Timely filing of GST returns to avoid penalties.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h6 className='fw-bold'>Delhi</h6>
                                                <ul>
                                                    <li>Standard GST rates apply.</li>
                                                    <li>Additional requirements: Local compliance checks.</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </section>

                                    <section>
                                        <h4 className='fw-bold'>How GST is Applied</h4>
                                        <ul>
                                            <li>
                                                <h6 className='fw-bold'>Product-wise Application</h6>
                                                <ul>
                                                    <li>GST is applied based on the type of product as mentioned above.</li>
                                                    <li>For clothing, different rates are applied based on the value of the item.</li>
                                                    <li>For mugs, the material of the mug determines the GST rate.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h6 className='fw-bold'>State-wise Compliance</h6>
                                                <ul>
                                                    <li>Ensure that the correct GST is charged based on the destination state.</li>
                                                    <li>Follow any additional state-specific compliance requirements.</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </section>

                                    <section>
                                        <h4 className='fw-bold'>How to Calculate GST</h4>
                                        <ul>
                                            <li>
                                                <h6 className='fw-bold'>Example for Clothing</h6>
                                                <ul>
                                                    <li>If a shirt costs ₹800, GST at 5% = ₹40.</li>
                                                    <li>If a shirt costs ₹1500, GST at 12% = ₹180.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h6 className='fw-bold'>Example for Mugs</h6>
                                                <ul>
                                                    <li>If a ceramic mug costs ₹200, GST at 12% = ₹24.</li>
                                                    <li>If a glass mug costs ₹200, GST at 18% = ₹36.</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </section>

                                    <section>
                                        <h4 className='fw-bold'>Filing GST</h4>
                                        <ul>
                                            <li>Monthly and Annual Returns: Regular filing of GSTR-1, GSTR-3B, and annual GSTR-9.</li>
                                            <li>E-way Bill: Required for the transportation of goods worth more than ₹50,000.</li>
                                        </ul>
                                    </section>

                                    <section>
                                        <h4 className='fw-bold'>Frequently Asked Questions (FAQs)</h4>
                                        <ul>
                                            <li>
                                                <h6 className='fw-bold'>What is the penalty for late filing?</h6>
                                                <p>Penalty is ₹100 per day under CGST and SGST each (total ₹200 per day).</p>
                                            </li>
                                            <li>
                                                <h6 className='fw-bold'>How to claim a GST refund?</h6>
                                                <p>File GST RFD-01 for claiming refunds.</p>
                                            </li>
                                        </ul>
                                    </section>

                                    <section>
                                        <h4 className='fw-bold'>Contact Information</h4>
                                        <p>For any queries, contact our customer support at [contact details].</p>
                                    </section>
                                </div>
                            </div>
                        </Tab>
                        {/* <Tab eventKey="rewards" title="Rewards">
                            <div className='py-2 flex-center-align py-5'>
                                <div className='custom-reward-tab d-grid justify-content-center text-center'>
                                    <div>
                                        <CiGift />
                                    </div>
                                    <h5 className='fw-semibold'>No rewards have been received yet</h5>
                                </div>
                            </div>
                        </Tab> */}
                    </Tabs>

                </div>
            </Container >
            {/* Withdraw Bank Selection */}
            <Modal
                show={withdrawBankModal}
                onHide={() => setWithdrawBankModal(false)}
                aria-labelledby="contained-modal-title-vcenter"
                size="md"
                centered
            >
                <Modal.Header>
                    <Modal.Title className='text-center w-100 fs-sm-14'>Select Bank Account</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custom-modal-radio-select'>
                    <div className="custom-radio-group">
                        {
                            bankList?.length > 0 ?
                                bankList.map((item, index) => (
                                    <label key={index} className="custom-radio-container">
                                        <input
                                            type="radio"
                                            name="custom-radio"
                                            defaultValue="option2"
                                            onChange={() => setWithdrawData((prevData) => ({
                                                ...prevData,
                                                sellerBankId: item?.id
                                            }))}
                                        />
                                        <span className="custom-radio-checkmark" />
                                        <div className='d-grid w-100 flex-wrap gap-1 fs-14 fs-sm-12 text-uppercase'>
                                            <span className='d-flex align-items-center flex-wrap gap-2 gap-sm-2'><BsBank /> {item?.bankName} <span className='d-flex gap-2'> - <span>{item?.accountNumber}</span></span></span>
                                            <span className='d-flex align-items-center gap-2'><FaUser /> {item?.accountHolderName}</span>
                                        </div>
                                    </label>
                                ))
                                : <h5
                                    className='text-decoration-none pointer m-0 flex-center-align gap-2'
                                    onClick={() => {
                                        setWithdrawBankModal(false)
                                        setChangeTab("bank-accounts")
                                    }}
                                >
                                    <BsBank className='color-temp' />Add Bank Account
                                </h5>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer className='flex-center-align'>
                    <Button variant="success" onClick={() => {
                        if (bankList?.length > 0) {
                            handleWithdraw();
                            setWithdrawBankModal(false);
                        }
                        else {
                            setWithdrawBankModal(false);
                            setChangeTab("bank-accounts");
                        }
                    }}
                    >
                        {
                            (bankList?.length > 0)
                                ? "Confirm"
                                : "Add Bank"
                        }
                    </Button>
                    <Button variant="secondary" onClick={() => setWithdrawBankModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Withdraw Status Modal */}
            <Modal
                show={withdrawModal}
                onHide={() => setWithdrawModal(false)}
                aria-labelledby="contained-modal-title-vcenter"
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className='text-center w-100 fs-sm-14'>Withdrawal History</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        (withdrawModalData) ?
                            <div className='d-grid gap-2'>
                                <div className='flex-center-align fs-sm-12'>
                                    Requested Date : {withdrawModalData?.date}
                                </div>
                                <div className='d-grid d-sm-flex gap-1 text-center justify-content-sm-between align-items-sm-center pb-2'>
                                    <span className=''>Amount : <b className='fw-semibold'>₹{withdrawModalData?.amount}</b></span>
                                    {/* <span className='flex-center-align gap-2'>
                                        Current Status :
                                        <Alert
                                            className='m-0 text-center fw-semibold py-1 px-2 fs-14 fs-sm-12'
                                            variant={
                                                withdrawModalData?.withDrawStatus === "REQUEST" ? "primary" :
                                                    withdrawModalData?.withDrawStatus === "ACCEPT" ? "success" :
                                                        withdrawModalData?.withDrawStatus === "ONHOLD" ? "warning" :
                                                            withdrawModalData?.withDrawStatus === "COMPLETE" ? "info" :
                                                                withdrawModalData?.withDrawStatus === "CANCEL" ? "danger" :
                                                                    withdrawModalData?.withDrawStatus === "PENDING" ? "secondary" :
                                                                        "secondary" // Default variant if none of the conditions match
                                            }
                                        >
                                            {withdrawModalData?.withDrawStatus}
                                        </Alert>
                                    </span> */}
                                </div>
                                {
                                    (withdrawModalData?.historyData?.length > 0) &&
                                    withdrawModalData?.historyData?.map((item, index) => (
                                        <div key={index} className='d-grid gap-2 gap-sm-1 bi-border-rad p-3 border-color' style={{ border: "1px solid grey" }}>
                                            {/* <div className='d-flex flex-column-reverse flex-sm-row gap-1 text-center justify-content-sm-between align-items-sm-center'> */}
                                            <div className='d-flex flex-row-reverse flex-sm-row gap-1 text-center justify-content-around justify-content-sm-between align-items-center'>
                                                <Alert
                                                    className='m-0 text-center fw-semibold py-1 px-2 fs-14 fs-sm-12'
                                                    variant={
                                                        ["REQUEST"]?.includes(item?.name) ? "primary" :
                                                            ["ACCEPT", "COMPLETED", "COMPLETE"]?.includes(item?.name) === "" ? "success" :
                                                                ["PENDING", "ONHOLD"]?.includes(item?.name) ? "warning" :
                                                                    ["CANCEL", "REJECTED"]?.includes(item?.name) ? "danger" :
                                                                        "secondary"
                                                    }
                                                >
                                                    {item?.name}
                                                </Alert>
                                                {/* <span className=''>By : <b className='fw-semibold'>{item?.from}</b></span> */}
                                            </div>
                                            <p className='m-0 fs-14 fs-sm-12 m-0 text-center text-sm-start'><GoDotFill className='fs-sm-10' /> {item?.remark}.</p>
                                            <div className='fs-12 fs-sm-10 text-center text-sm-start d-flex gap-1 align-items-center justify-content-center justify-content-sm-start'>
                                                <MdDateRange className='fs-14 fs-sm-12' />
                                                {item?.date}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            : <h4 className='fw-semibold text-center flex-center-align gap-2 m-0 py-4 py-sm-5'><IoBanOutline className='text-danger' /> No data found !</h4>
                    }
                </Modal.Body>
                <Modal.Footer className='flex-center-align'>
                    <Button variant="success" onClick={() => setWithdrawModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </div >
    )
}

export default SellerWallet