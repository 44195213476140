// MyDocument.js
import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font, Link } from '@react-pdf/renderer';
import { siteConfig } from '../Config/Config';

const styles = StyleSheet.create({
    page: {
        padding: 15,
    },
    tableHeader: {
        flexDirection: 'row',
        borderBottom: "1px solid black",
        backgroundColor: "black",
        fontSize: "8px",
        fontWeight: "bold",
        gap: '.75px'
    },
    tableHeaderText: {
        backgroundColor: '#F5F5F5',
        padding: 8,
        width: "100%",
        fontWeight: 'bold',
        justifyContent: "space-between",
        alignItems: "center",
    },
    tableRow: {
        backgroundColor: "black",
        display: "grid",
        gap: '0px',
        borderBottom: "1px solid black",
    },
    tablePetaRow: {
        flexDirection: 'row',
        display: "flex",
        gap: "1px",
    },
    tableCell: {
        width: "100%",
        backgroundColor: "white",
        padding: "5px 3px 8px 3px",
        fontSize: "8px",
    },

    invoiceHeader: {
        display: "flex",
        flexDirection: 'row',
        height: "70px",
        padding: 15,
        gap: '75px',
    },
    invoiceHeaderText: {
        width: "91%"
    },
    invoiceHeaderLogo: {
        width: "9%",
        display: "grid",
        gap: "2px"
    },
    invoiceHeaderLogoHolder: {
        width: "100%",
        aspectRatio: "1/1",
        overflow: "hidden"
    },
    invoiceHeaderLogoHolderImage: {
        height: "100%",
        width: "100%",
        objectFit: "contain",
    },
    listContainer: {
        display: "grid",
        backgroundColor: "white",
        padding: "3px 15px",
        minHeight: "71px",
        gap: "2px",
        fontSize: '8px',
    },
    listItem: {
        flexDirection: "row",
        alignItems: "flex-start",
    },
    bulletPoint: {
        width: 10, // Adjust the width as needed
        fontWeight: 'bold',
    },
    listText: {
        flex: 1, // Ensures the text takes up the remaining space
    },
});


const Invoices = ({ data = {} }) => {

    const tempData = [
        {
            name: "Graphic Streetwear Tee",
            qty: 10,
            rate: 500,
            taxableValue: 5000,
            IGSTPercent: 18,
            IGSTAmount: 900,
            total: 5900,
        },
        {
            name: "Classic White Ceramic Mug - Morning Bliss Edition",
            qty: 5,
            rate: 300,
            taxableValue: 1500,
            IGSTPercent: 12,
            IGSTAmount: 180,
            total: 1680,
        },
        {
            name: "Hardcover Spiral Notebook - Endless Creativity",
            qty: 20,
            rate: 100,
            taxableValue: 2000,
            IGSTPercent: 5,
            IGSTAmount: 100,
            total: 2100,
        },
        {
            name: "Vintage Washed Denim Cap - Outdoor Adventure",
            qty: 8,
            rate: 200,
            taxableValue: 1600,
            IGSTPercent: 18,
            IGSTAmount: 288,
            total: 1888,
        },
        {
            name: "Drawstring Gym Bag - Fitness Enthusiast Collection Enthusiast Collection",
            qty: 2,
            rate: 1200,
            taxableValue: 2400,
            IGSTPercent: 28,
            IGSTAmount: 672,
            total: 3072,
        }
    ];

    return (
        <Document>
            <Page style={styles.page}>
                <View style={{ fontSize: "12px", border: "1px solid black" }}>
                    <View style={styles.invoiceHeader}>
                        <View style={styles.invoiceHeaderText}>
                            <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Text style={{ fontWeight: "bolder", letterSpacing: "0.5px", textTransform: "uppercase", fontSize: "12px" }}>{siteConfig.name}</Text>
                                <Text style={{ color: "#12715B", fontSize: "10px" }}>{siteConfig.tagLine}</Text>
                            </View>
                            <View style={{ display: "flex", flexDirection: "row", paddingTop: "5px", justifyContent: "space-between" }}>
                                <View style={{ fontSize: "8px", display: "grid", gap: "2px" }}>
                                    <Text>{siteConfig?.address?.addressLine1} {siteConfig?.address?.addressLine2}</Text>
                                    <Text>{siteConfig?.address?.city} - {siteConfig?.address?.pinCode}</Text>
                                </View>
                                <View style={{ fontSize: "8px", display: "grid", gap: "2px", textAlign: "right" }}>
                                    <View style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                                        <Text style={{ textAlign: "right" }}>Web : {siteConfig?.websiteLink}</Text>
                                    </View>
                                    <Text style={{ textAlign: "right" }}>Email : {siteConfig?.email}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.invoiceHeaderLogo}>
                            <View style={styles.invoiceHeaderLogoHolder}>
                                <Link src="https://printfuse.in/">
                                    <Image src={siteConfig?.logo} style={styles.invoiceHeaderLogoHolderImage} />
                                </Link>
                            </View>
                        </View>
                    </View>
                    <View style={{ borderTop: "1px solid black", borderBottom: "1px solid black", display: "grid" }}>
                        <View style={{ padding: "4px 15px", borderBottom: "1px solid black", display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between" }}>
                            <Text style={{ fontSize: "10px" }}>GSTIN : {siteConfig?.GSTIN}</Text>
                            <Text style={{ fontWeight: "bold" }}>TAX INVOICE</Text>
                            <Text style={{ fontSize: "8px" }}>ORIGINAL FOR RECIPIENT</Text>
                        </View>
                        <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor: "black", gap: "1px" }}>
                            <View style={{ display: "grid", gap: "1px", width: "40%", fontSize: "10px" }}>
                                <Text style={{ textAlign: "center", backgroundColor: "white", padding: "2px 0px 2px 0px" }}>Customer Detail</Text>
                                <View style={{ display: "grid", gap: "3px", padding: "3px 3px 3px 15px", fontSize: "8px", backgroundColor: "white" }}>
                                    <View style={{ display: "flex", flexDirection: "row", gap: "1px" }}>
                                        <Text style={{ width: "40px" }}>M/S : </Text>
                                        <Text>Kevin Motors</Text>
                                    </View>
                                    <View style={{ display: "flex", flexDirection: "row", gap: "1px", justifyContent: "flex-start" }}>
                                        <Text style={{ width: "40px" }}>Address : </Text>
                                        <View style={{ display: "grid", gap: "1px" }}>
                                            <Text>Chandani Chok, New Delhi, </Text>
                                            <Text>Opposite Statue, New Delhi, </Text>
                                            <Text>Delhi - 110014 </Text>
                                        </View>
                                    </View>
                                    <View style={{ display: "flex", flexDirection: "row", gap: "1px", justifyContent: "flex-start" }}>
                                        <Text style={{ width: "40px" }}>PHONE : </Text>
                                        <Text>9372346666</Text>
                                    </View>
                                    <View style={{ display: "flex", flexDirection: "row", gap: "1px", justifyContent: "flex-start" }}>
                                        <Text style={{ width: "40px" }}>GSTIN : </Text>
                                        <Text>07AOLCC1206D1ZG</Text>
                                    </View>
                                    <View style={{ display: "flex", flexDirection: "row", gap: "1px", justifyContent: "flex-start" }}>
                                        <Text style={{ width: "40px" }}>Place of Supply : </Text>
                                        <Text>Delhi ( 07 )</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ display: "flex", flexWrap: "wrap", width: "60%", flexDirection: "row", padding: "5px 15px 5px 5px", fontSize: "9px", rowGap: "11px", backgroundColor: "white", }}>
                                <View style={{ display: "flex", flexDirection: "row", gap: "2px", width: "50%" }}>
                                    <Text style={{ width: "100%" }}>Invoice No.</Text>
                                    <Text style={{ width: "100%" }}>GST112020</Text>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row", gap: "2px", width: "50%" }}>
                                    <Text style={{ width: "100%" }}>Invoice Date</Text>
                                    <Text style={{ width: "100%" }}>04-Mar-2020</Text>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row", gap: "2px", width: "50%" }}>
                                    <Text style={{ width: "100%" }}>Challan No.</Text>
                                    <Text style={{ width: "100%" }}>865</Text>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row", gap: "2px", width: "50%" }}>
                                    <Text style={{ width: "100%" }}>Challan Date</Text>
                                    <Text style={{ width: "100%" }}>03-Mar-2020</Text>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row", gap: "2px", width: "50%" }}>
                                    <Text style={{ width: "100%" }}>P.O. No. </Text>
                                    <Text style={{ width: "100%" }}>66</Text>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row", gap: "2px", width: "50%" }}>
                                    <Text style={{ width: "100%" }}>DELIVERY DATE</Text>
                                    <Text style={{ width: "100%" }}>04-Mar-2020</Text>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row", gap: "2px", width: "50%" }}>
                                    <Text style={{ width: "100%" }}>Reverse Charge</Text>
                                    <Text style={{ width: "100%" }}>No</Text>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row", gap: "2px", width: "50%" }}>
                                    <Text style={{ width: "100%" }}>L.R. No.</Text>
                                    <Text style={{ width: "100%" }}>958</Text>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row", gap: "2px", width: "50%" }}>
                                    <Text style={{ width: "100%" }}>Due Date</Text>
                                    <Text style={{ width: "100%" }}>19-Mar-2020</Text>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row", gap: "2px", width: "50%" }}>
                                    <Text style={{ width: "100%" }}>E-Way No</Text>
                                    <Text style={{ width: "100%" }}>EWB54864584</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={{ paddingTop: "14px", borderBottom: "1px solid black" }} />
                    <View style={styles.tableHeader}>
                        <View style={{ ...styles.tableHeaderText, width: "5%" }}>
                            <Text>Sr. No.</Text>
                        </View>
                        <View style={{ ...styles.tableHeaderText, width: "35%" }}>
                            <Text>Name</Text>
                        </View>
                        <View style={{ ...styles.tableHeaderText, width: "8%" }}>
                            <Text>Qty</Text>
                        </View>
                        <View style={{ ...styles.tableHeaderText, width: "8%" }}>
                            <Text>Rate</Text>
                        </View>
                        <View style={{ ...styles.tableHeaderText, width: "10%" }}>
                            <Text>Taxable Value</Text>
                        </View>
                        <View style={{ ...styles.tableHeaderText, width: "10%" }}>
                            <Text>IGST(%)</Text>
                        </View>
                        <View style={{ ...styles.tableHeaderText, width: "12%" }}>
                            <Text>IGST(Amount)</Text>
                        </View>
                        <View style={{ ...styles.tableHeaderText, width: "12%" }}>
                            <Text>Total</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        {tempData.map((item, index) => (
                            <View style={styles.tablePetaRow} key={item.id}>
                                <View style={{ ...styles.tableCell, width: "5%", textAlign: "center" }}>
                                    <Text>{index + 1}</Text>
                                </View>
                                <View style={{ ...styles.tableCell, width: "35%" }}>
                                    <Text>{item?.name}</Text>
                                </View>
                                <View style={{ ...styles.tableCell, width: "8%" }}>
                                    <Text>{item?.qty}</Text>
                                </View>
                                <View style={{ ...styles.tableCell, width: "8%" }}>
                                    <Text>{item?.rate}</Text>
                                </View>
                                <View style={{ ...styles.tableCell, width: "10%" }}>
                                    <Text>{item?.taxableValue}</Text>
                                </View>
                                <View style={{ ...styles.tableCell, width: "10%" }}>
                                    <Text>{item?.IGSTPercent}</Text>
                                </View>
                                <View style={{ ...styles.tableCell, width: "12%" }}>
                                    <Text>{item?.IGSTAmount}</Text>
                                </View>
                                <View style={{ ...styles.tableCell, width: "12%" }}>
                                    <Text>{item?.total}</Text>
                                </View>
                            </View>
                        ))}
                    </View>
                    <View style={{ paddingTop: "14px", borderBottom: "1px solid black" }} />
                    <View style={{ display: "flex", flexDirection: "row", fontSize: "8px" }}>
                        <View style={{ width: "60%", display: "grid", backgroundColor: "black", gap: "1px" }}>
                            <Text style={{ textAlign: "center", backgroundColor: "white", padding: "3px 15px" }}>Total in words</Text>
                            <Text style={{ textAlign: "center", backgroundColor: "white", padding: "3px 15px" }}>ONE THOUSAND TWO HUNDRED AND FIFTY-EIGHT RUPEES ONLY</Text>
                            <Text style={{ textAlign: "center", backgroundColor: "white", padding: "3px 15px" }}>Bank Details</Text>
                            <View style={{ display: "grid", backgroundColor: "white", padding: "3px 15px", gap: "2px" }}>
                                <View style={{ display: "flex", flexDirection: "row" }}>
                                    <Text style={{ width: "45%" }}>Bank Name</Text>
                                    <Text style={{ width: "55%" }}>{siteConfig?.bankName}</Text>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row" }}>
                                    <Text style={{ width: "45%" }}>Branch Name </Text>
                                    <Text style={{ width: "55%" }}>{siteConfig?.branchName}</Text>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row" }}>
                                    <Text style={{ width: "45%" }}>Bank Account Number</Text>
                                    <Text style={{ width: "55%" }}>{siteConfig?.bankAccountNumber}</Text>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row" }}>
                                    <Text style={{ width: "45%" }}>Bank Branch IFSC</Text>
                                    <Text style={{ width: "55%" }}>{siteConfig?.IFSCCode}</Text>
                                </View>
                            </View>
                            <Text style={{ textAlign: "center", backgroundColor: "white", padding: "3px 15px" }}>Terms and Conditions</Text>
                            <View style={styles.listContainer}>
                                {
                                    siteConfig?.rules.map((item) => (
                                        <View style={styles.listItem}>
                                            <Text style={styles.bulletPoint}>•</Text>
                                            <Text style={styles.listText}>{item}</Text>
                                        </View>
                                    ))
                                }
                            </View>
                        </View>
                        <View style={{ width: "40%", display: "grid", backgroundColor: "black", gap: "1px", borderLeft: "1px solid black" }}>
                            <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor: "#F5F5F5", padding: "3px 15px" }}>
                                <Text>Taxable Amount </Text>
                                <Text>1,154.00</Text>
                            </View>
                            <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor: "#F5F5F5", padding: "3px 15px" }}>
                                <Text>Add : IGST</Text>
                                <Text>103.86</Text>
                            </View>
                            <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor: "#F5F5F5", padding: "3px 15px" }}>
                                <Text>Total Tax</Text>
                                <Text>103.86</Text>
                            </View>
                            <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor: "#F5F5F5", padding: "3px 15px" }}>
                                <Text>Total Amount After Tax</Text>
                                <Text style={{ fontSize: "10px" }}>1,258.00</Text>
                            </View>
                            <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor: "white", padding: "3px 15px" }}>
                                <Text></Text>
                                <Text>(E & O.E.)</Text>
                            </View>
                            <View style={{ display: "grid", gap: "3px", backgroundColor: "white", padding: "3px 15px" }}>
                                <Text style={{ fontSize: "6px", textAlign: "center" }}>Certified that the particulars given above are true and correct.</Text>
                                <Text style={{ textAlign: "center" }}>For Gujarat Freight Tools</Text>
                            </View>
                            <View style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "3px", backgroundColor: "white", padding: "3px 65px", minHeight: "60px" }}>
                                <Text style={{ fontSize: "6px", textAlign: "center" }}>This is Computer generated invoice no signature required.</Text>
                            </View>
                            <View style={{ display: "flex", flexDirection: "row", justifyContent: "center", backgroundColor: "white", padding: "3px 15px" }}>
                                <Text>Authorised Signatory</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document >
    )
};

export default Invoices;