import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Container, Dropdown, Modal, Nav, Row, Tab, Tabs } from 'react-bootstrap'
import { GiNotebook } from 'react-icons/gi'
import { MdKeyboardDoubleArrowRight, MdOutlineDone, MdOutlineProductionQuantityLimits } from "react-icons/md";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { frontEnd_API, header, siteConfig } from '../../Config/Config';
import axios from 'axios';
import CommonPagination from '../../Components/CommonPagination';
import { TbFileInvoice } from 'react-icons/tb';
import { Tooltip } from 'react-tooltip';
import { IoCloseOutline } from "react-icons/io5";
import Swal from 'sweetalert2';
import { BiSolidEdit } from "react-icons/bi";
import moment from 'moment';
import Loader from '../../Components/Loader';
import OrderSubCategoryList from '../../Components/OrderSubCategoryList';
import Flatpickr from "react-flatpickr";
import FilterList from '../../Components/FilterList';
import { BsBank } from 'react-icons/bs';
import { Input } from 'reactstrap';
import { keyCode } from '../../Config/Constant';

function SellerOrders() {


  const navigate = useNavigate();
  const [orders, setOrders] = useState();
  const [loader, setLoader] = useState(true);
  const params = useParams();

  const [totalList, setTotalList] = useState();
  const [filters, setFilters] = useState();
  const [filterList, setFilterList] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [orderCategory, setOrderCategory] = useState([]);
  const [tabHandler, setTabHandler] = useState({
    groupStatus: "",
    orderStatus: ""
  });

  const [handleDate, setHandleDate] = useState(1);
  const tempDate = [
    {
      label: "Last 1 month",
      value: 1
    },
    {
      label: "Last 3 month",
      value: 3
    },
    {
      label: "Last 6 month",
      value: 6
    },
    {
      label: "Last 1 year",
      value: 12
    },
    {
      label: "Custom range",
      value: 0
    }
  ]

  const getOrderList = async () => {
    try {
      const body = {
        ...filters,
        groupStatus: [params?.groupStatus ?? "pending"],
        orderStatus: [params?.orderStatus ?? "all"]
      }
      const response = await axios.put(frontEnd_API.orders, body, header)
      if (response?.status == 200) {
        setOrders(response?.data?.data);
        setTotalList(response?.data?.totalCount);
        // console.log("response?.data?.data::", response?.data?.data);
        setLoader(false);
      }
    }
    catch (e) {
      // console.log("e::", e);
      setTabHandler((prevData) => ({
        ...prevData,
        // orderStatus: e
      }))
      setTotalList(10);
      setOrders([]);
      setLoader(false);
    }
  }

  const getGlobalState = async () => {
    try {
      const { data } = await axios.get(frontEnd_API.getGlobal, header)
      setFilterList((prevData) => ({
        ...prevData,
        parentData: []
      }));
      setLoader(false);
    }
    catch (e) {
      // console.log("Err::", e);
      setLoader(false);
    }
  }

  useEffect(() => {
    getOrderList();
  }, [filters, params])

  const getOrderStatusList = async () => {
    try {
      const { data } = await axios.get(frontEnd_API?.orderstatus, header)
      if (data?.data?.length > 0) {
        const tempData = data?.data.map((item) => ({
          group: item?.group,
          data: (
            delete item?.group,
            item
          )
        }))

        const groupedData = Object.values(tempData.reduce((acc, item) => {
          if (!acc[item.group]) {
            acc[item.group] = { group: item.group, data: [] };
          }
          acc[item.group].data.push(item.data);
          return acc;
        }, {}));

        setOrderCategory(groupedData)

        navigate(`/orders/${(params?.groupStatus && params?.orderStatus) ? params?.groupStatus + "/" + params?.orderStatus : groupedData[0]?.group + "/all"} `);

        setTabHandler((prevData) => ({
          ...prevData,
          groupStatus: params?.groupStatus ?? groupedData[0]?.group,
          orderStatus: params?.orderStatus ?? groupedData[0]?.data[0]?.label
        }))
      }
    }
    catch (e) {
      // console.log("Err::", e);
    }
  }

  useEffect(() => {
    if (params?.groupStatus) {
      setTabHandler((prevData) => ({
        ...prevData,
        groupStatus: params?.groupStatus,
        orderStatus: params?.orderStatus
      }))
    }
  }, [params?.groupStatus])

  useEffect(() => {
    getGlobalState();
    getOrderStatusList();
  }, [])

  const handleCheckOrder = (item) => {
    const tempData = filters[item?.title] ?? [];

    const newData = (tempData?.length > 0) ? tempData.find((e) => e == item?.value) : null;
    if (newData) {
      const check = filters[item?.title].filter((e) => e != item?.value);
      setFilters((prevData) => ({
        ...prevData,
        [item?.title]: check
      }))
    }
    else {
      if (item?.value == '') {
        const data = filterList?.parentData?.find((e) => e?.title == item?.title);
        if (data?.data?.length == filters[item?.title]?.length) {
          setFilters((prevData) => ({
            ...prevData,
            [item?.title]: []
          }));
        }
        else {
          const check = data?.data.map((prevData) => (
            prevData?.value
          ));
          setFilters((prevData) => ({
            ...prevData,
            [item?.title]: check
          }));
        }
      }
      else {
        setFilters((prevData) => ({
          ...prevData,
          [item?.title]: [...tempData, item?.value]
        }))
      }
    }
  }

  const handleOrderStatus = async (item) => {

    const data = {
      ...item,
      orderStatus: item?.value
    }
    // console.log('valuess handleOrderStatus :: ', item, data, selectedOrder)
    // return
    try {
      if (item?.value == "seller-cancelled") {
        Swal.fire({
          title: `Are you sure to cancel this order?`,
          text: `This action can't be reversed`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#12715B',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'No',
        }).then(async (result) => {
          if (result.isConfirmed) {
            setLoader(true);
            const response = await axios.put(`${frontEnd_API?.orders}/status/${item?.id}`, {
              orderStatus: item?.value,
            }, header)
            if (response?.status == 200) {
              Swal.fire(
                'Order Cancelled Successfully!',
                'Your selected order is cancelled!',
                'success'
              );
              setLoader(false);
              getOrderList();
            }

            // console.log('sss ::: ', response)
          }
          else {
            setLoader(false);
          }
        });
      }
      else {
        const response = await axios.put(`${frontEnd_API?.orders}/status/${item?.id}`, selectedOrder, header)
        console.log("response 245 :: ", response?.config?.data)
        if (response?.status == 200) {
          Swal.fire(
            `Order ${(item?.value == "seller-cancelled") ? "Cancelled" : "Approved"} Successfully!`,
            `Your selected order is ${(item?.value == "seller-cancelled") ? "cancelled" : "approved"}!`,
            'success'
          );
          setSelectedOrder({})
          setCourierModalStatus(false)
          setLoader(false);
          getOrderList();
        }
      }
    }
    catch (e) {
      // Check if the error status is 500 and response data contains a message
      // const errorMessage = e?.response?.status === 500 && e?.response?.data?.data?.message
      //   ? e.response.data.data.message
      //   : "An unexpected error occurred. Please try again later.";

      const errorMessage = e?.response?.data?.message?? "Unable to execute this action";

      Swal.fire(
        'Request failed',
        errorMessage,
        'error'
      );

      setLoader(false);
      // console.log("Err::", e);
    }
  }

  const handleDateChange = (value) => {
    const endDate = moment().format('YYYY-MM-DD');
    const startDate = moment().subtract(value, 'months').format('YYYY-MM-DD');
    setHandleDate(value);
    // console.log("[startDate, endDate]::", [startDate, endDate]);

    setFilters((prevData) => ({
      ...prevData,
      dateRange: [startDate, endDate]
    }))
  }

  // manage order status section start -----
  const [courierModalStatus, setCourierModalStatus] = useState(false)
  const [courierData, setCourierData] = useState([])
  const [selectedOrder, setSelectedOrder] = useState({})

  const manageOrderStatus = async (obj = {}) => {
    try {
      if (obj?.from != '') {

        delete obj?.orderStatus
        obj.orderStatus = obj.value
        setSelectedOrder({ ...obj })

        if ([null, undefined, 'undefined'].includes(obj?.orderCourierCompanyId)) {
          const { data } = await axios?.get(frontEnd_API?.orders + '/courier/' + obj?.orderId, header)
          setCourierData([...data?.data])
          setCourierModalStatus(true)
        }
      }
    } catch (error) {
      if (![undefined, null]?.includes(error?.status)) {

      }
      else {
        Swal.fire(
          'Request failed',
          error?.response?.data?.message,
          'error'
        );
      }
      console.log("error :: ", error, error?.status, error?.response)
    }    
  }
  // console.log('setSelectedOrder ::', selectedOrder)
  // -----

  const OrderActionButtonComp = (obj = {}) => {
    const item = obj?.item?? {}
    return (
      <>
      {
        item?.id > 0 && (
          <div className='custom-order-dropdown d-flex align-items-center gap-3'>
          {
            ["pending", "cancelled"]?.includes(item?.orderStatus) && (
              <Dropdown className="p-0"
                style={{ width: "fit-content" }}
              >
                <Dropdown.Toggle id="dropdown-basic" className='px-2 py-1' variant='secondary' disabled={item?.returnOrderId != 0}>
                  <span className='fs-16'>
                    <BiSolidEdit />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {
                    (["pending", "seller-accepted", "seller-cancelled"].includes(item?.orderStatus)) ?
                      <>
                        {

                          (["pending", !"seller-accepted"].includes(item?.orderStatus)) &&
                          < Dropdown.Item className='text-success'
                            onClick={() => manageOrderStatus({ ...item, value: 'seller-accepted', from: 'accept', id: item?.orderId })}
                          >
                            <span><MdOutlineDone /> Accept Order</span>
                          </Dropdown.Item>
                        }
                        {
                          (["pending", !"seller-cancelled", "seller-accepted"].includes(item?.orderStatus)) &&
                          <Dropdown.Item className='text-danger'
                            onClick={() => {
                              handleOrderStatus({
                                value: "seller-cancelled",
                                id: item?.orderId
                              })
                            }}
                          >
                            <span><IoCloseOutline /> Cancel Order</span>
                          </Dropdown.Item>
                        }
                        {
                          (["seller-cancelled"].includes(item?.orderStatus)) &&
                          <Dropdown.Item disabled={true} className='py-0'>
                            <span className='fs-14 text-danger'>You cancelled this order.</span>
                          </Dropdown.Item>
                        }
                      </>
                      :
                      <Dropdown.Item disabled={true} className='py-0'>
                        <span className='fs-12'>Wait untill provider change</span>
                      </Dropdown.Item>
                  }
                </Dropdown.Menu>
              </Dropdown>
            )
          }
            <Link
              data-tooltip-id="my-tooltip-edit"
              data-tooltip-content="More Details"
              variant='success'
              className='more-btn'
              // to={(params?.groupStatus) ? `/orders/${tabHandler}/${item?.orderId}` : `${item?.orderId}`}
              to={(params?.groupStatus) ? `${item?.orderId}` : `${item?.orderId}`}
              state={{ data: item }}
            >
              <TbFileInvoice className='fs-18' />
            </Link>
            <Tooltip id="my-tooltip-edit" place="top" />
          </div>
        )
      }
      </>
    )
  }

  useEffect(() => {
    // console.log("filters::", filters);
  }, [filters])

  document.title = `Seller Orders | ${siteConfig?.name}`;
  // console.log('courierData::', courierData)
  return (
    <div className='seller-order p-0' style={{ minHeight: "100vh" }}>
      <Container fluid className='seller-order-main-container pb-5 px-2 px-sm-3 px-md-5'>
        <div className=' d-grid d-sm-flex justify-content-sm-between gap-3 py-4'>
          <h3 className='fw-semibold  m-0'>Manage Orders</h3>
          <div className='flex-between-align gap-2'>
            <Dropdown className='w-100'>
              <Dropdown.Toggle id="dropdown-basic" className='custom-dropdown-two w-100 py-2 fw-semibold fs-sm-14'>
                Create order
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={'/create-orders'}>Manual</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className='seller-order-container p-2 p-sm-3'>
          <Tab.Container activeKey={tabHandler?.groupStatus ? tabHandler?.groupStatus : orderCategory[0]?.group}>
            <Nav fill className='custom-tabs mb-2' variant="pills"
              onSelect={(e) => {
                navigate(`/orders/${e}/${tabHandler?.orderStatus}`)
                setTabHandler((prevData) => ({
                  ...prevData,
                  groupStatus: e
                }))
              }}>
              {
                orderCategory?.length > 0 &&
                orderCategory.map((item, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link eventKey={item?.group} className={`flex-center-align gap-1 ${item?.group == 'cancel' ? 'text-danger cs-border-danger' : ''}`}>
                      <span className='text-capitalize'>{item?.group}</span>
                    </Nav.Link>
                  </Nav.Item>
                ))
              }
            </Nav>
            <Tab.Content>
              {/* <Tab.Pane eventKey={orderCategory[0]?.group} title={orderCategory[0]?.group}> */}
              <Tab.Pane eventKey={orderCategory[0]?.group}>
                <div className='pb-2 d-grid gap-3 custom-btn-scrollbar'>

                  {/* Sub Category */}
                  <div className='d-flex pt-2 pt-sm-3 overflow-hidden'>
                    <OrderSubCategoryList
                      listData={(orderCategory?.length > 0) && [
                        { label: "All", value: "all" },
                        { label: "COD", value: "cod" },
                        { label: "prepaid", value: "prepaid" },
                      ]}
                      onTabHandleChange={tabHandler?.groupStatus}
                      onCategoryChange={(e) => {
                        // console.log("e::", e);
                        navigate(`/orders/${tabHandler?.groupStatus}/${e}`)
                        setTabHandler((prevData) => ({
                          ...prevData,
                          orderStatus: e
                        }))
                      }}
                    />
                  </div>

                  {/* Custom Filtration */}
                  <>
                    <FilterList
                      filterList={filterList}
                      isDateFilter={true}
                      onChangeData={(e) => {
                        setFilters(e);
                      }}
                    />
                  </>

                  <div className='peta-height-fix'>
                    <div className='custom-order-list-table-overflow'>
                      <div className='custom-order-list-table-overflow-div'>
                        <table className='custom-order-list-table'>
                          <thead className=''>
                            <tr className='fs-12 fs-sm-10 fw-medium p-color'>
                              <th>Order ID</th>
                              <th>Date</th>
                              <th>Customer Detail</th>
                              <th>Order Value</th>
                              <th>Provider</th>
                              <th>Payment Mode</th>
                              <th>Status</th>
                              {/* <th className='text-center'>Action</th> */}
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              loader ?
                                <tr>
                                  <td colSpan={7}>
                                    <div className='w-100 aspect-4-1 aspect-md-2-1'>
                                      <Loader />
                                    </div>
                                  </td>
                                </tr>
                                : (!orders?.length > 0)
                                  ? <tr>
                                    <td colSpan={7} className='py-5'>
                                      <div className='custom-transaction-tab d-grid justify-content-center text-center gap-2'>
                                        <div>
                                          <GiNotebook />
                                        </div>
                                        <h5 className='fw-semibold'>No orders yet</h5>
                                        {/* <p className='p-color fs-12'>Orders will appear here after your user fulfill orders.</p> */}
                                      </div>
                                    </td>
                                  </tr>
                                  :
                                  <>
                                    {
                                      orders.map((item, index) => {
                                        return (
                                          <tr
                                            key={index}
                                            className='custom-order-list-bar-item fs-12 fs-sm-10'
                                            style={(item?.returnOrderId != 0) ? { backgroundColor: "rgb(255, 203, 203)" } : {}}
                                          >
                                            <td
                                              style={{ width: "fit-content" }}
                                            >{item?.orderNumber}</td>
                                            <td
                                              style={{ width: "fit-content" }}
                                            >{moment(item?.orderDate).format('DD, MMM YYYY')}</td>
                                            <td>
                                              {
                                                item?.userData?.name ? (
                                                  <div className='d-flex flex-column'>
                                                    {
                                                      item?.userData?.name?.length > 15 ? (
                                                        <span>{item?.userData?.name.toString().slice(0, 3) + '...'}</span>
                                                      ) : (<span>{item?.userData?.name}</span>)
                                                    }
                                                    <span>{item?.userData?.mobile}</span>
                                                  </div>
                                                ) : <><span className='text-secondary fs-14'>--Not Available--</span></>
                                              }
                                            </td>
                                            <td>₹{item?.orderNetAmount}</td>
                                            <td>
                                              {
                                                item?.providerData.map((provider, pIndex) => (
                                                  <span key={pIndex} className='text-center'>{provider?.providerBusinessName}</span>
                                                ))
                                              }
                                            </td>
                                            <td>
                                              <Alert
                                                className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-12 fs-sm-10'
                                                style={{ width: "fit-content" }}
                                                variant={(item?.orderPaymentMode == "COD") ? "warning" : "success"}>
                                                <span>{(item?.orderPaymentMode == "COD") ? item?.orderPaymentMode : "Prepaid"}</span>
                                              </Alert>
                                            </td>
                                            <td>
                                              {
                                                (item?.returnOrderId == 0) ?
                                                  <Alert
                                                    style={{ width: "fit-content" }}
                                                    className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-12 fs-sm-10'
                                                    variant={
                                                      (["delivered"].includes(item?.orderStatus)) ? "success" :
                                                        (["seller-accepted", "processing"].includes(item?.orderStatus)) ? "primary" :
                                                          (["pending"].includes(item?.orderStatus)) ? "warning" :
                                                            (["printed", "dispatch", "on-way"].includes(item?.orderStatus)) ? "info" :
                                                              (["seller-cancelled", "cancelled", "user-cancelled"].includes(item?.orderStatus)) ? "danger" :
                                                                (["provider-packed"].includes(item?.orderStatus)) ? "secondary" :
                                                                  "secondary"
                                                    }
                                                  >
                                                    {item?.orderStatus.replace("-", " ")}
                                                  </Alert>
                                                  : <Alert
                                                    className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-14 fs-sm-12'
                                                    variant={"danger"}
                                                    style={{ width: "fit-content" }}
                                                  >
                                                    Order in return
                                                  </Alert>
                                              }
                                            </td>
                                            <td>
                                              {/* <div className='custom-order-dropdown d-flex align-items-center justify-content-end justify-content-md-start justify-content-lg-center gap-3'> */}
                                              <OrderActionButtonComp item={item} />
                                            </td>
                                          </tr>
                                        )
                                      })
                                    }
                                  </>
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <CommonPagination
                    totalCount={totalList}
                    changePage={(data) => {
                      setFilters((prevData) => ({
                        ...prevData,
                        limit: data?.limit,
                        page: data?.page
                      }))
                    }}
                  />
                </div>
              </Tab.Pane>
              {/* <Tab.Pane eventKey={orderCategory[1]?.group} title={orderCategory[1]?.group}> */}
              <Tab.Pane eventKey={orderCategory[1]?.group}>
                <div className='pb-2 d-grid gap-3 custom-btn-scrollbar'>

                  {/* Sub Category */}
                  <div className='d-flex pt-2 pt-sm-3 overflow-hidden'>
                    <OrderSubCategoryList
                      listData={(orderCategory?.length > 0 && tabHandler?.groupStatus && tabHandler?.groupStatus) && [
                        { label: "All", value: "all" },
                        ...orderCategory.find((e) => e?.group == tabHandler?.groupStatus)?.data
                      ]}
                      onTabHandleChange={tabHandler?.groupStatus}
                      onCategoryChange={(e) => {
                        // console.log("e::", e);
                        navigate(`/orders/${tabHandler?.groupStatus}/${e}`)
                        setTabHandler((prevData) => ({
                          ...prevData,
                          orderStatus: e
                        }))
                      }}
                    />
                  </div>

                  {/* Custom Filtration */}
                  <>
                    <FilterList
                      filterList={filterList}
                      isDateFilter={true}
                      onChangeData={(e) => {
                        setFilters(e);
                      }}
                    />
                  </>

                  <div className='custom-order-list-table-overflow'>
                    <div className='custom-order-list-table-overflow-div'>
                      <table className='custom-order-list-table'>
                        <thead className=''>
                          <tr className='fs-12 fs-sm-10 fw-medium p-color'>
                            <th>Order ID</th>
                            <th>Date</th>
                            <th>Customer Detail</th>
                            <th>Order Value</th>
                            <th>Provider</th>
                            <th>Payment Mode</th>
                            <th>Status</th>
                            {/* <th className='text-center'>Action</th> */}
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            loader ?
                              <tr>
                                <td colSpan={7}>
                                  <div className='w-100 aspect-4-1 aspect-md-2-1'>
                                    <Loader />
                                  </div>
                                </td>
                              </tr>
                              : (!orders?.length > 0)
                                ? <tr>
                                  <td colSpan={7} className='py-5'>
                                    <div className='custom-transaction-tab d-grid justify-content-center text-center gap-2'>
                                      <div>
                                        <GiNotebook />
                                      </div>
                                      <h5 className='fw-semibold'>No orders yet</h5>
                                      {/* <p className='p-color fs-12'>Orders will appear here after your user fulfill orders.</p> */}
                                    </div>
                                  </td>
                                </tr>
                                :
                                <>
                                  {
                                    orders.map((item, index) => {
                                      return (
                                        <tr
                                          key={index}
                                          className='custom-order-list-bar-item fs-12 fs-sm-10'
                                          style={(item?.returnOrderId != 0) ? { backgroundColor: "rgb(255, 203, 203)" } : {}}
                                        >
                                          <td
                                            style={{ width: "fit-content" }}
                                          >{item?.orderNumber}</td>
                                          <td
                                            style={{ width: "fit-content" }}
                                          >{moment(item?.orderDate).format('DD, MMM YYYY')}</td>
                                          <td>
                                            {
                                              item?.userData?.name ? (
                                                <div className='d-flex flex-column'>
                                                  {
                                                    item?.userData?.name?.length > 15 ? (
                                                      <span>{item?.userData?.name.toString().slice(0, 3) + '...'}</span>
                                                    ) : (<span>{item?.userData?.name}</span>)
                                                  }
                                                  <span>{item?.userData?.mobile}</span>
                                                </div>
                                              ) : <><span className='text-secondary fs-14'>--Not Available--</span></>
                                            }
                                          </td>
                                          <td>₹{item?.orderNetAmount}</td>
                                          <td>
                                            {
                                              item?.providerData.map((provider, pIndex) => (
                                                <span key={pIndex} className='text-center'>{provider?.providerBusinessName}</span>
                                              ))
                                            }
                                          </td>
                                          <td>
                                            <Alert
                                              className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-12 fs-sm-10'
                                              style={{ width: "fit-content" }}
                                              variant={(item?.orderPaymentMode == "COD") ? "warning" : "success"}>
                                              <span>{(item?.orderPaymentMode == "COD") ? item?.orderPaymentMode : "Prepaid"}</span>
                                            </Alert>
                                          </td>
                                          <td>
                                            {
                                              (item?.returnOrderId == 0) ?
                                                <Alert
                                                  style={{ width: "fit-content" }}
                                                  className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-12 fs-sm-10'
                                                  variant={
                                                    (["delivered"].includes(item?.orderStatus)) ? "success" :
                                                      (["seller-accepted", "processing"].includes(item?.orderStatus)) ? "primary" :
                                                        (["pending"].includes(item?.orderStatus)) ? "warning" :
                                                          (["printed", "dispatch", "on-way"].includes(item?.orderStatus)) ? "info" :
                                                            (["seller-cancelled", "cancelled", "user-cancelled"].includes(item?.orderStatus)) ? "danger" :
                                                              (["provider-packed"].includes(item?.orderStatus)) ? "secondary" :
                                                                "secondary"
                                                  }
                                                >
                                                  {item?.orderStatus.replace("-", " ")}
                                                </Alert>
                                                : <Alert
                                                  className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-14 fs-sm-12'
                                                  variant={"danger"}
                                                  style={{ width: "fit-content" }}
                                                >
                                                  Order in return
                                                </Alert>
                                            }
                                          </td>
                                          <td>
                                            {/* <div className='custom-order-dropdown d-flex align-items-center justify-content-end justify-content-md-start justify-content-lg-center gap-3'> */}
                                            <OrderActionButtonComp item={item} />
                                          </td>
                                        </tr>
                                      )
                                    })
                                  }
                                </>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <CommonPagination
                    totalCount={totalList}
                    changePage={(data) => {
                      setFilters((prevData) => ({
                        ...prevData,
                        limit: data?.limit,
                        page: data?.page
                      }))
                    }}
                  />
                </div>
              </Tab.Pane>
              {/* <Tab.Pane eventKey={orderCategory[2]?.group} title={orderCategory[2]?.group}> */}
              <Tab.Pane eventKey={orderCategory[2]?.group}>
                <div className='pb-2 d-grid gap-3 custom-btn-scrollbar'>

                  {/* Sub Category */}
                  <div className='d-flex pt-2 pt-sm-3 overflow-hidden'>
                    <OrderSubCategoryList
                      listData={(orderCategory?.length > 0 && tabHandler?.groupStatus) && [
                        { label: "All", value: "all" },
                        ...orderCategory.find((e) => e?.group == tabHandler?.groupStatus)?.data
                      ]}
                      onTabHandleChange={tabHandler?.groupStatus}
                      onCategoryChange={(e) => {
                        // console.log("e::", e);
                        navigate(`/orders/${tabHandler?.groupStatus}/${e}`)
                        setTabHandler((prevData) => ({
                          ...prevData,
                          orderStatus: e
                        }))
                      }}
                    />
                  </div>

                  {/* Custom Filtration */}
                  <>
                    <FilterList
                      filterList={filterList}
                      isDateFilter={true}
                      onChangeData={(e) => {
                        setFilters(e);
                      }}
                    />
                  </>

                  <div className='custom-order-list-table-overflow'>
                    <div className='custom-order-list-table-overflow-div'>
                      <table className='custom-order-list-table'>
                        <thead className=''>
                          <tr className='fs-12 fs-sm-10 fw-medium p-color'>
                            <th>Order ID</th>
                            <th>Date</th>
                            <th>Customer Detail</th>
                            <th>Order Value</th>
                            <th>Provider</th>
                            <th>Payment Mode</th>
                            <th>Status</th>
                            {/* <th className='text-center'>Action</th> */}
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            loader ?
                              <tr>
                                <td colSpan={7}>
                                  <div className='w-100 aspect-4-1 aspect-md-2-1'>
                                    <Loader />
                                  </div>
                                </td>
                              </tr>
                              : (!orders?.length > 0)
                                ? <tr>
                                  <td colSpan={7} className='py-5'>
                                    <div className='custom-transaction-tab d-grid justify-content-center text-center gap-2'>
                                      <div>
                                        <GiNotebook />
                                      </div>
                                      <h5 className='fw-semibold'>No orders yet</h5>
                                      {/* <p className='p-color fs-12'>Orders will appear here after your user fulfill orders.</p> */}
                                    </div>
                                  </td>
                                </tr>
                                :
                                <>
                                  {
                                    orders.map((item, index) => {
                                      return (
                                        <tr
                                          key={index}
                                          className='custom-order-list-bar-item fs-12 fs-sm-10'
                                          style={(item?.returnOrderId != 0) ? { backgroundColor: "rgb(255, 203, 203)" } : {}}
                                        >
                                          <td
                                            style={{ width: "fit-content" }}
                                          >{item?.orderNumber}</td>
                                          <td
                                            style={{ width: "fit-content" }}
                                          >{moment(item?.orderDate).format('DD, MMM YYYY')}</td>
                                          <td>
                                            {
                                              item?.userData?.name ? (
                                                <div className='d-flex flex-column'>
                                                  {
                                                    item?.userData?.name?.length > 15 ? (
                                                      <span>{item?.userData?.name.toString().slice(0, 3) + '...'}</span>
                                                    ) : (<span>{item?.userData?.name}</span>)
                                                  }
                                                  <span>{item?.userData?.mobile}</span>
                                                </div>
                                              ) : <><span className='text-secondary fs-14'>--Not Available--</span></>
                                            }
                                          </td>
                                          <td>₹{item?.orderNetAmount}</td>
                                          <td>
                                            {
                                              item?.providerData.map((provider, pIndex) => (
                                                <span key={pIndex} className='text-center'>{provider?.providerBusinessName}</span>
                                              ))
                                            }
                                          </td>
                                          <td>
                                            <Alert
                                              className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-12 fs-sm-10'
                                              style={{ width: "fit-content" }}
                                              variant={(item?.orderPaymentMode == "COD") ? "warning" : "success"}>
                                              <span>{(item?.orderPaymentMode == "COD") ? item?.orderPaymentMode : "Prepaid"}</span>
                                            </Alert>
                                          </td>
                                          <td>
                                            {
                                              (item?.returnOrderId == 0) ?
                                                <Alert
                                                  style={{ width: "fit-content" }}
                                                  className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-12 fs-sm-10'
                                                  variant={
                                                    (["delivered"].includes(item?.orderStatus)) ? "success" :
                                                      (["seller-accepted", "processing"].includes(item?.orderStatus)) ? "primary" :
                                                        (["pending"].includes(item?.orderStatus)) ? "warning" :
                                                          (["printed", "dispatch", "on-way"].includes(item?.orderStatus)) ? "info" :
                                                            (["seller-cancelled", "cancelled", "user-cancelled"].includes(item?.orderStatus)) ? "danger" :
                                                              (["provider-packed"].includes(item?.orderStatus)) ? "secondary" :
                                                                "secondary"
                                                  }
                                                >
                                                  {item?.orderStatus.replace("-", " ")}
                                                </Alert>
                                                : <Alert
                                                  className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-14 fs-sm-12'
                                                  variant={"danger"}
                                                  style={{ width: "fit-content" }}
                                                >
                                                  Order in return
                                                </Alert>
                                            }
                                          </td>
                                          <td>
                                            {/* <div className='custom-order-dropdown d-flex align-items-center justify-content-end justify-content-md-start justify-content-lg-center gap-3'> */}
                                            <OrderActionButtonComp item={item} />
                                          </td>
                                        </tr>
                                      )
                                    })
                                  }
                                </>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <CommonPagination
                    totalCount={totalList}
                    changePage={(data) => {
                      setFilters((prevData) => ({
                        ...prevData,
                        limit: data?.limit,
                        page: data?.page
                      }))
                    }}
                  />
                </div>
              </Tab.Pane>
              {/* <Tab.Pane eventKey={orderCategory[3]?.group} title={orderCategory[3]?.group}> */}
              <Tab.Pane eventKey={orderCategory[3]?.group}>

                <div className='pb-2 d-grid gap-3 custom-btn-scrollbar'>
                  {/* Sub Category */}
                  <div className='d-flex pt-2 pt-sm-3 overflow-hidden'>
                    <OrderSubCategoryList
                      listData={(orderCategory?.length > 0 && tabHandler?.groupStatus) && [
                        { label: "All", value: "all" },
                        ...orderCategory.find((e) => e?.group == tabHandler?.groupStatus)?.data
                      ]}
                      onTabHandleChange={tabHandler?.groupStatus}
                      onCategoryChange={(e) => {
                        // console.log("e::", e);
                        navigate(`/orders/${tabHandler?.groupStatus}/${e}`)
                        setTabHandler((prevData) => ({
                          ...prevData,
                          orderStatus: e
                        }))
                      }}
                    />
                  </div>

                  {/* Custom Filtration */}
                  <>
                    <FilterList
                      filterList={filterList}
                      isDateFilter={true}
                      onChangeData={(e) => {
                        setFilters(e);
                      }}
                    />
                  </>

                  <div className='custom-order-list-table-overflow'>
                    <div className='custom-order-list-table-overflow-div'>
                      <table className='custom-order-list-table'>
                        <thead className=''>
                          <tr className='fs-12 fs-sm-10 fw-medium p-color'>
                            <th>Order ID</th>
                            <th>Date</th>
                            <th>Customer Detail</th>
                            <th>Order Value</th>
                            <th>Provider</th>
                            <th>Payment Mode</th>
                            <th>Status</th>
                            {/* <th className='text-center'>Action</th> */}
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            loader ?
                              <tr>
                                <td colSpan={7}>
                                  <div className='w-100 aspect-4-1 aspect-md-2-1'>
                                    <Loader />
                                  </div>
                                </td>
                              </tr>
                              : (!orders?.length > 0)
                                ? <tr>
                                  <td colSpan={7} className='py-5'>
                                    <div className='custom-transaction-tab d-grid justify-content-center text-center gap-2'>
                                      <div>
                                        <GiNotebook />
                                      </div>
                                      <h5 className='fw-semibold'>No orders yet</h5>
                                      {/* <p className='p-color fs-12'>Orders will appear here after your user fulfill orders.</p> */}
                                    </div>
                                  </td>
                                </tr>
                                :
                                <>
                                  {
                                    orders.map((item, index) => {
                                      return (
                                        <tr
                                          key={index}
                                          className='custom-order-list-bar-item fs-12 fs-sm-10'
                                          style={(item?.returnOrderId != 0) ? { backgroundColor: "rgb(255, 203, 203)" } : {}}
                                        >
                                          <td
                                            style={{ width: "fit-content" }}
                                          >{item?.orderNumber}</td>
                                          <td
                                            style={{ width: "fit-content" }}
                                          >{moment(item?.orderDate).format('DD, MMM YYYY')}</td>
                                          <td>
                                            {
                                              item?.userData?.name ? (
                                                <div className='d-flex flex-column'>
                                                  {
                                                    item?.userData?.name?.length > 15 ? (
                                                      <span>{item?.userData?.name.toString().slice(0, 3) + '...'}</span>
                                                    ) : (<span>{item?.userData?.name}</span>)
                                                  }
                                                  <span>{item?.userData?.mobile}</span>
                                                </div>
                                              ) : <><span className='text-secondary fs-14'>--Not Available--</span></>
                                            }
                                          </td>
                                          <td>₹{item?.orderNetAmount}</td>
                                          <td>
                                            {
                                              item?.providerData.map((provider, pIndex) => (
                                                <span key={pIndex} className='text-center'>{provider?.providerBusinessName}</span>
                                              ))
                                            }
                                          </td>
                                          <td>
                                            <Alert
                                              className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-12 fs-sm-10'
                                              style={{ width: "fit-content" }}
                                              variant={(item?.orderPaymentMode == "COD") ? "warning" : "success"}>
                                              <span>{(item?.orderPaymentMode == "COD") ? item?.orderPaymentMode : "Prepaid"}</span>
                                            </Alert>
                                          </td>
                                          <td>
                                            {
                                              (item?.returnOrderId == 0) ?
                                                <Alert
                                                  style={{ width: "fit-content" }}
                                                  className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-12 fs-sm-10'
                                                  variant={
                                                    (["delivered"].includes(item?.orderStatus)) ? "success" :
                                                      (["seller-accepted", "processing"].includes(item?.orderStatus)) ? "primary" :
                                                        (["pending"].includes(item?.orderStatus)) ? "warning" :
                                                          (["printed", "dispatch", "on-way"].includes(item?.orderStatus)) ? "info" :
                                                            (["seller-cancelled", "cancelled", "user-cancelled"].includes(item?.orderStatus)) ? "danger" :
                                                              (["provider-packed"].includes(item?.orderStatus)) ? "secondary" :
                                                                "secondary"
                                                  }
                                                >
                                                  {item?.orderStatus.replace("-", " ")}
                                                </Alert>
                                                : <Alert
                                                  className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-14 fs-sm-12'
                                                  variant={"danger"}
                                                  style={{ width: "fit-content" }}
                                                >
                                                  Order in return
                                                </Alert>
                                            }
                                          </td>
                                          <td>
                                            {/* <div className='custom-order-dropdown d-flex align-items-center justify-content-end justify-content-md-start justify-content-lg-center gap-3'> */}
                                            <OrderActionButtonComp item={item} />
                                          </td>
                                        </tr>
                                      )
                                    })
                                  }
                                </>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <CommonPagination
                    totalCount={totalList}
                    changePage={(data) => {
                      setFilters((prevData) => ({
                        ...prevData,
                        limit: data?.limit,
                        page: data?.page
                      }))
                    }}
                  />
                </div>
              </Tab.Pane>
              {/* <Tab.Pane eventKey={orderCategory[4]?.group} title={orderCategory[4]?.group}> */}
              <Tab.Pane eventKey={orderCategory[4]?.group}>
                <div className='pb-2 d-grid gap-3 custom-btn-scrollbar'>
                  {/* Sub Category */}
                  <div className='d-flex pt-2 pt-sm-3 overflow-hidden'>
                    <OrderSubCategoryList
                      listData={(orderCategory?.length > 0 && tabHandler?.groupStatus) && [
                        { label: "All", value: "all" },
                        ...orderCategory.find((e) => e?.group == tabHandler?.groupStatus)?.data
                      ]}
                      onTabHandleChange={tabHandler?.groupStatus}
                      onCategoryChange={(e) => {
                        // console.log("e::", e);
                        navigate(`/orders/${tabHandler?.groupStatus}/${e}`)
                        setTabHandler((prevData) => ({
                          ...prevData,
                          orderStatus: e
                        }))
                      }}
                    />
                  </div>

                  {/* Custom Filtration */}
                  <>
                    <FilterList
                      filterList={filterList}
                      isDateFilter={true}
                      onChangeData={(e) => {
                        setFilters(e);
                      }}
                    />
                  </>

                  <div className='custom-order-list-table-overflow'>
                    <div className='custom-order-list-table-overflow-div'>
                      <table className='custom-order-list-table'>
                        <thead className=''>
                          <tr className='fs-12 fs-sm-10 fw-medium p-color'>
                            <th>Order ID</th>
                            <th>Date</th>
                            <th>Customer Detail</th>
                            <th>Order Value</th>
                            <th>Provider</th>
                            <th>Payment Mode</th>
                            <th>Status</th>
                            {/* <th className='text-center'>Action</th> */}
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            loader ?
                              <tr>
                                <td colSpan={7}>
                                  <div className='w-100 aspect-4-1 aspect-md-2-1'>
                                    <Loader />
                                  </div>
                                </td>
                              </tr>
                              : (!orders?.length > 0)
                                ? <tr>
                                  <td colSpan={7} className='py-5'>
                                    <div className='custom-transaction-tab d-grid justify-content-center text-center gap-2'>
                                      <div>
                                        <GiNotebook />
                                      </div>
                                      <h5 className='fw-semibold'>No orders yet</h5>
                                      {/* <p className='p-color fs-12'>Orders will appear here after your user fulfill orders.</p> */}
                                    </div>
                                  </td>
                                </tr>
                                :
                                <>
                                  {
                                    orders.map((item, index) => {
                                      return (
                                        <tr
                                          key={index}
                                          className='custom-order-list-bar-item fs-12 fs-sm-10'
                                          style={(item?.returnOrderId != 0) ? { backgroundColor: "rgb(255, 203, 203)" } : {}}
                                        >
                                          <td
                                            style={{ width: "fit-content" }}
                                          >{item?.orderNumber}</td>
                                          <td
                                            style={{ width: "fit-content" }}
                                          >{moment(item?.orderDate).format('DD, MMM YYYY')}</td>
                                          <td>
                                            {
                                              item?.userData?.name ? (
                                                <div className='d-flex flex-column'>
                                                  {
                                                    item?.userData?.name?.length > 15 ? (
                                                      <span>{item?.userData?.name.toString().slice(0, 3) + '...'}</span>
                                                    ) : (<span>{item?.userData?.name}</span>)
                                                  }
                                                  <span>{item?.userData?.mobile}</span>
                                                </div>
                                              ) : <><span className='text-secondary fs-14'>--Not Available--</span></>
                                            }
                                          </td>
                                          <td>₹{item?.orderNetAmount}</td>
                                          <td>
                                            {
                                              item?.providerData.map((provider, pIndex) => (
                                                <span key={pIndex} className='text-center'>{provider?.providerBusinessName}</span>
                                              ))
                                            }
                                          </td>
                                          <td>
                                            <Alert
                                              className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-12 fs-sm-10'
                                              style={{ width: "fit-content" }}
                                              variant={(item?.orderPaymentMode == "COD") ? "warning" : "success"}>
                                              <span>{(item?.orderPaymentMode == "COD") ? item?.orderPaymentMode : "Prepaid"}</span>
                                            </Alert>
                                          </td>
                                          <td>
                                            {
                                              (item?.returnOrderId == 0) ?
                                                <Alert
                                                  style={{ width: "fit-content" }}
                                                  className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-12 fs-sm-10'
                                                  variant={
                                                    (["delivered"].includes(item?.orderStatus)) ? "success" :
                                                      (["seller-accepted", "processing"].includes(item?.orderStatus)) ? "primary" :
                                                        (["pending"].includes(item?.orderStatus)) ? "warning" :
                                                          (["printed", "dispatch", "on-way"].includes(item?.orderStatus)) ? "info" :
                                                            (["seller-cancelled", "cancelled", "user-cancelled"].includes(item?.orderStatus)) ? "danger" :
                                                              (["provider-packed"].includes(item?.orderStatus)) ? "secondary" :
                                                                "secondary"
                                                  }
                                                >
                                                  {item?.orderStatus.replace("-", " ")}
                                                </Alert>
                                                : <Alert
                                                  className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-14 fs-sm-12'
                                                  variant={"danger"}
                                                  style={{ width: "fit-content" }}
                                                >
                                                  Order in return
                                                </Alert>
                                            }
                                          </td>
                                          <td>
                                            {/* <div className='custom-order-dropdown d-flex align-items-center justify-content-end justify-content-md-start justify-content-lg-center gap-3'> */}
                                            <OrderActionButtonComp item={item} />
                                          </td>
                                        </tr>
                                      )
                                    })
                                  }
                                </>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <CommonPagination
                    totalCount={totalList}
                    changePage={(data) => {
                      setFilters((prevData) => ({
                        ...prevData,
                        limit: data?.limit,
                        page: data?.page
                      }))
                    }}
                  />
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>

        </div>
      </Container >

      {/* courier list modal start */}
      <Modal
        show={courierModalStatus}
        onHide={() => setCourierModalStatus(!courierModalStatus)}
        aria-labelledby="contained-modal-title-vcenter"
        size="md"
        // className='mh-75'
        style={{
          maxHeight: '75vh'
        }}
        centered
        scrollable
      >
        <Modal.Header className='d-flex flex-column'>
          <Modal.Title className='text-center w-100 fs-sm-14'>Select Courier</Modal.Title>
          <div className='d-flex justify-content-between align-items-center w-100'>
            <div className='d-flex flex-column jusity-content-start align-items-start'>
              <label className='fs-12 fw-bold text-secondary'>Order Number</label>
              <div className='fs-12 fw-bold text-success'>{selectedOrder?.orderNumber}</div>
            </div>
            <div className='d-flex flex-column jusity-content-end align-items-start'>
              <div className='d-flex flex-column jusity-content-end align-items-end'>
                <label className='fs-12 fw-bold text-secondary'>Order Date</label>
                <div className='fs-12 fw-bold text-success'>{selectedOrder?.orderDate}</div>
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className='custom-modal-radio-select'>
          <div className="custom-radio-group">
            {
              courierData?.map((row, index) => <>
                <Card
                  className={`pointer border ${selectedOrder?.orderCourierCompanyId == row?.id ? 'border-2 border-success' : 'border-secondary'} rounded-2 py-1 px-2 position-relative`}
                  onClick={() => {
                    selectedOrder.orderCourierCompanyId = row?.id
                    selectedOrder.shippingCharge = row?.rate
                    selectedOrder.orderCourierDetail = row
                    setSelectedOrder({ ...selectedOrder })
                  }}
                >
                  <label className='position-absolute top-0 start-0 translate-middle'>
                    <Input
                      type='radio'
                      className={selectedOrder?.orderCourierCompanyId != row?.id ? 'd-none' : ''}
                      checked={selectedOrder?.orderCourierCompanyId == row?.id}
                    />
                  </label>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='fs-14 fw-bold'>{row?.courierName}</div>
                    <div className='d-flex gap-3'>                      
                      {
                        row?.codCharges > 0 && (
                          <div className='d-flex flex-column align-items-end text-secondary'>
                            <div className='fs-12 fw-bold'>{keyCode?.inr}{row?.codCharges?.toFixed(2)}</div>
                            <div className='fs-10 fw-semibold'>COD Charge</div>
                          </div>
                        )
                      }
                      <div className='d-flex flex-column align-items-end text-secondary'>
                        <>
                          <div className='fs-12 fw-bold'>{keyCode?.inr}{row?.rate}</div>
                          <div className='fs-10 fw-semibold'>Courier charge</div>
                        </>
                      </div>
                      <div className='d-flex flex-column align-items-end text-secondary'>
                        <>
                          <div className='fs-12 fw-bold'>{row?.rating}</div>
                          <div className='fs-10 fw-semibold'>Rating</div>
                        </>
                      </div>
                    </div>
                  </div>
                  {/*
                  <div className='d-flex justify-content-between align-items-center py-1'>
                    <div className='d-flex flex-column jusity-content-end align-items-start'>
                      <label className='fs-12 fw-bold text-secondary'>Estimated Date</label>
                      <div className='fs-12 fw-bold text-success'>{row?.estimatedDate}</div>
                    </div>
                    <div className='d-flex flex-column jusity-content-end align-items-end'>
                      <label className='fs-12 fw-bold text-secondary'>Estimated Days</label>
                      <div className='fs-12 fw-bold text-success'>{row?.estimatedDays}</div>
                    </div>
                  </div>
                  */}
                </Card>
              </>)
            }
          </div>
        </Modal.Body>
        <Modal.Footer className='flex-center-align'>
          <Button
            variant="success"
            onClick={() => {
              if (selectedOrder?.orderCourierCompanyId > 0) {
                handleOrderStatus(selectedOrder)
              }
            }}
            disabled={selectedOrder?.orderCourierCompanyId < 1}
            className='text-capitalize'
          >
            Click here to confirm
          </Button>
          <Button variant="secondary" onClick={() => setCourierModalStatus(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
      {/* courier list modal close */}
    </div>
  )
}

export default SellerOrders