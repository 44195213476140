import React, { useState } from 'react'
import { Container, Nav, NavItem, NavLink, Tab, TabContainer, TabContent, TabPane, Tabs } from 'react-bootstrap'
import { siteConfig } from '../../Config/Config';
import OrderSubCategoryList from '../../Components/OrderSubCategoryList';

function SellerReturns() {

    const returnOrderStatusGroup = [
        {
            label: 'Return',
            value: 'return',
        },
        {
            label: 'RTO/RTS',
            value: 'rto-rts'
        }
    ]



    document.title = `Seller Return Orders | ${siteConfig?.name}`;
    return (
        <div className='seller-return-order p-0' style={{ minHeight: "100vh" }}>
            <Container fluid className='seller-return-order-main-container pb-5 px-2 px-sm-3 px-md-5'>
                <div className=' d-grid d-sm-flex justify-content-sm-between gap-3 py-4'>
                    <h3 className='fw-semibold  m-0'>Manage Return Orders</h3>
                </div>
                <div className='seller-return-order-container p-2 p-sm-3'>
                    <TabContainer activeKey="return">
                        <Nav fill className='custom-tabs mb-2' variant="pills">
                        {
                            returnOrderStatusGroup.map((row, index) => (
                                <NavItem key={index}>
                                    <NavLink
                                        eventKey={row?.value}
                                        className="flex-center-align gap-1"
                                    >
                                        <span className='text-capitalize'>{row?.label}</span>
                                    </NavLink>
                                </NavItem>
                            ))
                        }
                        </Nav>
                        <TabContent>
                            <TabPane eventKey="return">
                                <div className='pb-2 d-grid gap-3 custom-btn-scrollbar'>
                                    <div className='d-flex pt-2 pt-sm-3 overflow-hidden'>
                                        <div className='main-custom-order-sub-category'>
                                            <div className='d-flex custom-order-sub-category'>
                                            {
                                                [
                                                    { label: "All", value: "all" },
                                                    { label: "COD", value: "cod" },
                                                    { label: "prepaid", value: "prepaid" },
                                                ]?.map((item, index) => (
                                                    <span
                                                        key={index}
                                                        className="pointer text-center text-capitalize px-2 px-sm-3 py-1 fs-14 fs-md-12 fs-sm-10"
                                                        title={item?.label}
                                                    >
                                                        {item?.label}
                                                    </span>
                                                ))
                                            }
                                            </div>
                                        </div>
                                    </div>

                                    <div className='peta-height-fix'>
                                        <div className='custom-order-list-table-overflow'>
                                            <div className='custom-order-list-table-overflow-div'>
                                                <table className='custom-order-list-table'>
                                                    <thead className=''>
                                                        <tr className='fs-12 fs-sm-10 fw-medium p-color'>
                                                            <th>Order ID</th>
                                                            <th>Date</th>
                                                            <th>Customer Detail</th>
                                                            <th>Order Value</th>
                                                            <th>Provider</th>
                                                            <th>Payment Mode</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                        </TabContent>
                    </TabContainer>
                </div>
            </Container >
        </div>
    )
}

export default SellerReturns