import axios from "axios";
import React, { useEffect, useState } from "react";
import { frontEnd_API, header, storage } from "./Config/Config";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";

const BlockPopUp = () => {
    const user = useSelector((state) => state.user.value);
    const [showPopup, setShowPopup] = useState(false);
    const [waitlistCount, setWaitlistCount] = useState(125);
    const [sellerStatus, setSellerStatus] = useState(-1);

    // Function to get the seller status
    const getSellerStatus = async () => {
        try {
            const { data } = await axios.put(`${frontEnd_API?.status}`, { status: 1 }, header);
            setSellerStatus(data?.data?.status ?? -1);
        } catch (error) {
            console.error("Error fetching seller status:", error);
        }
    };

    useEffect(() => {
        getSellerStatus();

        // Retrieve the waitlist count from localStorage or set the initial value
        const storedCount = localStorage.getItem("waitlistCount");
        const initialCount = storedCount ? parseInt(storedCount) : 125;

        // Update the state with the current count
        setWaitlistCount(initialCount);

        // Save the count to localStorage
        localStorage.setItem("waitlistCount", initialCount);

        // Show the popup
        setShowPopup(true);

        // Prevent body scrolling when popup is visible
        document.body.style.overflow = "hidden";

        // Auto-increment the waitlist count
        const interval = setInterval(() => {
            setWaitlistCount((prevCount) => {
                const newCount = prevCount + 10;
                localStorage.setItem("waitlistCount", newCount); // Save updated count
                return newCount;
            });
        }, 3000); // Increment every 5 seconds

        // Cleanup on component unmount
        return () => {
            clearInterval(interval);
            document.body.style.overflow = "auto";
        };
    }, []);

    return (
        <div className="block-box">
            <div id="waitlist-popup" className={`popup ${showPopup ? "show" : ""}`}>
                <div className="popup-content">
                    <h2>You're on the Waitlist!</h2>
                    <p>
                        Thanks for signing up! You're now part of our exclusive waitlist.
                        We are reviewing sign-ups and will reach out to you soon.
                    </p>
                    <div className="waitlist-info">
                        <span>Waitlist Count: </span>
                        <span id="waitlist-count">{waitlistCount}</span>
                    </div>
                    <p>We'll notify you when it's your turn!</p>
                    {sellerStatus === 1 && (
                        <Button
                            color="transparent"
                            className="unblock-btn border-2 border-white text-white fw-semibold"
                            onClick={() => {
                                const data = { ...user, status: 1 };
                                localStorage?.setItem(storage?.user, JSON.stringify(data));
                                setShowPopup(false); // Close popup instead of reloading
                            }}
                        >
                            Close
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BlockPopUp;
