import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './Assets/sass/style.scss';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import routes from './Routes/routes';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fontsource/poppins";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/900.css";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-tooltip/dist/react-tooltip.css'
import 'flatpickr/dist/flatpickr.min.css';
import SellerSideBar from './View/SellerDashboard/SellerSideBar';
import axios from 'axios';
import { updateUser } from './Store/Slices/UserSlice';
import { BASE_URL, frontEnd_API, header, storeCode, token } from './Config/Config';
import Loader from './Components/Loader';
import { storeLoader } from './Store/Slices/homeLoader';
import NotificationBell from './Components/NotificationBell';
import InterComChatBot from './Components/InterComChatBot';
import BlockPopUp from './BlockPopUp';
import StatusCheckComponent from './StatusCheckComponent';
import moment from 'moment';

function App() {
    const user = useSelector((state) => state.user.value);
    const localLoader = useSelector((state) => state.loader.value);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        if (user) {
            if (user?.storeId !== 0) {
                dispatch(storeLoader(1))
                if (localLoader != 1) {
                    setLoader(true);
                    setTimeout(() => {
                        setLoader(false);
                    }, 3000)
                }
                else {
                    setLoader(false);
                }
            }
            else {
                setLoader(false);
            }
        }
        else {
            setLoader(false);
        }
    }, [])

    useEffect(() => {
        if (!user) {
            const { pathname } = location;

            const isRestrictedPath =
                pathname.startsWith('/') && !pathname.startsWith('/view') ||
                pathname === '/create-store';

            const isSignUpOrSignInPage =
                pathname.endsWith('/signup') ||
                pathname.endsWith('/signin');

            if (isRestrictedPath && !isSignUpOrSignInPage) {
                navigate('/signin');
            }
        }
        else {
            const { pathname } = location;
            if (localLoader == 1 && (pathname.endsWith('/signup') || pathname.endsWith('/signin'))) {
                navigate('/');
            }
        }
    }, [user, location, navigate]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const showHeaderFooter = (!location.pathname.startsWith('/signin') && !location.pathname.startsWith('/signup') && !location.pathname.startsWith('/view') && user?.storeId !== 0 && !location.pathname.endsWith('/canvas') && !location.pathname.endsWith('/app/create-store'));
    const [childStateValue, setChildStateValue] = useState();

    const handleChildStateChange = (value) => {
        setChildStateValue(value);
    };

    const getWalletBalance = async () => {
        if (user?.id > 0) {
            try {
                const { data } = await axios.get(frontEnd_API?.walletBalance, header)
                console.log("data balance::", data);
                dispatch(updateUser({ walletBalance: data?.data?.walletBalance }))
            }
            catch (e) {
                console.log("Err::", e);
            }
        }
    }

    // function for get status of seller -----
    const getSellerStatus = async () => {
        if (user?.id > 0) {
            const { data } = await axios.put(`${frontEnd_API?.status}`, { status:1 }, header)
            console.log("data 15 15 15 :: ", data, data?.data?.status != user?.status)
            if (user?.status != data?.data?.status) {
                dispatch(updateUser({ status: data?.data?.status }))
                window.location = BASE_URL
            }
        }
    }
    // -----

    useEffect(() => {
        console.log("showHeaderFooter ::: ", showHeaderFooter, showHeaderFooter && user?.id > 0)
        if (showHeaderFooter && user?.id > 0) {
            getSellerStatus()
        }
    }, [location.pathname])


    useEffect(() => {
        getWalletBalance();
    }, [])

    useEffect(() => {
        if (user) {
            if (user?.storeId === 0) {
                navigate('/app/create-store');
            }
        }
    }, [navigate, user]);

    return (
        <>
        {
            loader ? (
                <div className='main-loader'>
                    <Loader />
                </div>
            ) : (
                <div className={`${user?.status <= 0 ? 'position-relative block-panel' : ''}`}>
                    <div className={(showHeaderFooter) && `d-lg-flex custom-dashboard-panel ${childStateValue ? 'is-collapsed' : ''}`}>
                        {
                            (showHeaderFooter) &&
                            <div className='dashboard-panel-sidebar'>
                                <SellerSideBar onStateChange={handleChildStateChange} />
                            </div>
                        }
                        <div className={(showHeaderFooter) && 'dashboard-panel-content'}>
                            <div>
                                <Routes>
                                    {
                                        routes.map((item, index) => {
                                            return (
                                                <Route key={index} path={item.path} element={item.element} />
                                            )
                                        })
                                    }
                                </Routes>
                            </div>
                            {
                                (user?.id > 0 && !location?.pathname?.startsWith('/view') && !location?.pathname?.endsWith('/canvas')) &&
                                <InterComChatBot id={user?.id} name={user?.name} email={user?.email} createdAt={user?.createdAt} />
                            }
                        </div>
                        {
                            (user?.id > 0 && !location?.pathname?.startsWith('/view') && !location?.pathname?.endsWith('/canvas')) &&
                            <NotificationBell />
                        }
                        {/* <StatusCheckComponent status={user?.status} date={moment().toNow()} /> */}
                    </div>
                    {
                        (user?.status <= 0) && (
                            <><BlockPopUp/></>
                        )
                    }
                </div>
            )
        }
        </>
    );
}

export default App;