import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { frontEnd_API, header, storage, token } from '../../Config/Config';
import { useSelector } from 'react-redux';

function SellerBranding() {

    const activeStore = useSelector((state) => state.store.value)

    const [storeBrandingStatus, setStoreBrandingStatus] = useState(activeStore?.brandingStatus)
    const [newBrandingDarkLogo, setNewBrandingDarkLogo] = useState(null);
    const [newBrandingLightLogo, setNewBrandingLightLogo] = useState(null);

    const [brandList, setBrandList] = useState([]);
    const sellerDetail = JSON.parse(localStorage?.getItem(storage.store)?? '{}')
    
    console.log("serrler storeBrandingStatus :: ", storeBrandingStatus)

    const getBrandingList = async () => {
        try {
            const { data } = await axios.get(frontEnd_API?.brandingList, header)
            console.log("data::", data);
            setBrandList(data?.data);
        }
        catch (e) {
            console.log("Err::", e);
        }
    }

    // function for manage branding files -----
    const updateStoreBrandingDetail = (obj = {from:'', value:''}) => {

        const body = {}
        
        if (obj?.from === 'brandingStatus')
            body.brandingStatus = storeBrandingStatus == 0 ? 1 : 0

        if (obj?.from === 'brandingDarkLogo')
            body.brandingDarkLogo = obj?.value

        if (obj?.from === 'brandingLightLogo')
            body.brandingLightLogo = obj?.value
        
        axios.put(`${frontEnd_API.postStore}/branding/${activeStore.id}`, body, {
            headers: {
                'token': token,
                'Content-Type': (body?.brandingDarkLogo?.name?.length > 0 || body?.brandingLightLogo?.name?.length > 0) ? 'multipart/form-data' : 'application/json'
            }
        })?.then((res) => {
            console.log("res 49 :: ", res?.data, res?.data?.brandingStatus)
            const storeDetail = {...activeStore, ...res?.data?.data}
            setNewBrandingDarkLogo(storeDetail?.brandingDarkLogo)
            setNewBrandingLightLogo(storeDetail?.brandingLightLogo)
            localStorage?.setItem(storage?.store, JSON.stringify(storeDetail))
        })
    }
    // -----

    useEffect(() => {
        getBrandingList();
    }, [])

    return (
        <div className='seller-branding py-4 py-sm-5' style={{ minHeight: "100vh" }}>
            <Container>
                <div className='d-grid gap-4'>
                    <div className='d-grid gap-2'>
                        <h1 className='m-0 fs-25 fw-semibold'>Branding & gift messages</h1>
                        <p className='m-0 fs-sm-12 fs-14 p-color'>Unlock the power of unforgettable branding to resonate with your audience and elevate your business to new heights!</p>
                    </div>
                    {
                        sellerDetail?.brandingStatus <= 0 && (
                            <div className='bg-warning rounded fs-16 px-2 py-1 fw-semibold'>Kindly Allow branding feature for your store from store setting form</div>
                        )
                    }
                    <div className='d-flex flex-column gap-2'>
                    {
                        brandList?.map((row, index) =>
                        <Card className='custom-branding-card border-0'>
                            <CardBody>
                                <Row xl='2' lg='2' md='1'>
                                    <Col>
                                        <div className='w-100 aspect-4-5'>
                                            <Image
                                                src={require('../../Assets/Images/neck-label.png')}
                                                alt={'Inside neck labels'}
                                                className='w-100 h-100 rounded-2 object-contain'
                                            />
                                        </div>                                            
                                    </Col>
                                    <Col>
                                        <div className='h-100'>
                                            <div className='d-flex flex-column gap-3'>
                                                <h2 className='fw-bold'>Inside neck labels</h2>
                                                <p>Give your clothing a unique touch with custom neck tags from Printfuse. These tags add a personalized and professional appearance to your products, enhancing your brand identity. They also convey product information such as the country of origin, and material, and can even include discounts, personal messages, and promotions.</p>
                                                <div className='d-flex gap-2 flex-column'>
                                                    <div className='d-flex align-items-center'>
                                                        <span className='fw-semibold fs-14'>Price&nbsp;:&nbsp;</span>
                                                        <span>Rs. 5 per label</span>
                                                    </div>
                                                    <div className='d-flex align-items-center'>
                                                        <span className='fw-semibold fs-14'>MOQ&nbsp;:&nbsp;</span>
                                                        <span>On Demand</span>
                                                    </div>
                                                    <div className='d-flex align-items-center'>
                                                        <span className='fw-semibold fs-14'>Size&nbsp;:&nbsp;</span>
                                                        <span>Max 3 x 3 inch</span>
                                                    </div>
                                                </div>
                                                <div className='mt-3 d-flex flex-column gap-2'>
                                                    <Accordion defaultActiveKey="0">
                                                        <AccordionItem className='border-0 border-bottom' eventKey="0">
                                                            <AccordionHeader className='fw-semibold'>1. Guidelines</AccordionHeader>
                                                            <AccordionBody>
                                                                <div className='gap-2 d-flex flex-column'>
                                                                    <li>Do not add size in your neck label</li>
                                                                    <li>One standard color neck label for all colored garments</li>
                                                                    <li>Design file must be PNG</li>
                                                                </div>
                                                            </AccordionBody>
                                                        </AccordionItem>
                                                        <AccordionItem className='border-0 border-bottom' eventKey="1">
                                                            <AccordionHeader className='fw-semibold'>2. Features</AccordionHeader>
                                                            <AccordionBody>
                                                                <div className='gap-2 d-flex flex-column'>
                                                                    <li>Customize: Add your brand logo, slogan, discount codes, or request feedback.</li>
                                                                    <li>Refine: Achieve a polished, professional look that rivals top retail brands.</li>
                                                                    <li>Stand Out: Include memorable details that encourage customer loyalty and repeat purchases.</li>
                                                                </div>
                                                            </AccordionBody>
                                                        </AccordionItem>
                                                    </Accordion>
                                                </div>
                                                <div className='d-flex flex-column gap-2'>
                                                    <div className='branding'>
                                                        <span>Do you want to enable custom branding services for this product</span><br />
                                                        <label className="switch">
                                                            <input
                                                                type="checkbox"
                                                                value="1"
                                                                checked={storeBrandingStatus == 1}
                                                                onChange={() => {
                                                                    setStoreBrandingStatus(storeBrandingStatus == 0 ? 1 : 0)
                                                                    console.log("::::: ", storeBrandingStatus == 1, storeBrandingStatus)
                                                                    updateStoreBrandingDetail({from:'brandingStatus', value:storeBrandingStatus })
                                                                }}
                                                            />
                                                            <span className="slider"></span>
                                                        </label>
                                                    </div>
                                                    <span className='fw-bold'>Branding File</span>
                                                    <div className='d-flex align-items-center gap-4'>
                                                        <div>
                                                            <div className='d-grid custom-img-selector'>
                                                                <label htmlFor="brandingDarkLogo">
                                                                    <span>Dark Logo</span>
                                                                    {
                                                                        (activeStore?.brandingDarkLogo || newBrandingDarkLogo)
                                                                            ? <div>
                                                                                <img
                                                                                    src={newBrandingDarkLogo?? activeStore.brandingDarkLogo}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            : <div><i className="bi bi-image fs-40" /></div>
                                                                    }
                                                                </label>
                                                                <input
                                                                    type="file"
                                                                    name='brandingDarkLogo'
                                                                    id='brandingDarkLogo'
                                                                    onChange={(e) => {
                                                                        updateStoreBrandingDetail({from:'brandingDarkLogo', value:e.target.files[0] })
                                                                        setNewBrandingDarkLogo(e.target.files[0])
                                                                    }}
                                                                    accept=".png, .jpg, .jpeg"
                                                                    autoComplete='off'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='d-grid custom-img-selector'>
                                                                <label htmlFor="brandingLightLogo">
                                                                    <span>Light Logo</span>
                                                                    {
                                                                        (activeStore?.brandingLightLogo)
                                                                            ? <div>
                                                                                <img
                                                                                    src={newBrandingLightLogo?? activeStore.brandingLightLogo}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            : <div><i className="bi bi-image fs-40" /></div>
                                                                    }
                                                                </label>
                                                                <input
                                                                    type="file"
                                                                    name='brandingLightLogo'
                                                                    id='brandingLightLogo'
                                                                    onChange={(e) => {
                                                                        updateStoreBrandingDetail({from:'brandingLightLogo', value:e.target.files[0] })
                                                                        setNewBrandingDarkLogo(e.target.files[0])
                                                                    }}
                                                                    accept=".png, .jpg, .jpeg"
                                                                    autoComplete='off'
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>)
                    }
                    </div>

                    <Row>
                    {/* {
                        brandList?.length > 0 &&
                        brandList.map((item, index) => (
                            <Col key={index} className='col-12 col-sm-6'>
                                <div className='custom-branding-card d-grid gap-2 gap-sm-3 p-3 p-sm-4'>
                                    <div className='d-grid gap-2'>
                                        
                                        <h5 className='m-0 fw-semibold first-cap'>{item?.name}</h5>
                                        <p className='fs-14 fs-sm-12 m-0 p-color'>{item?.description}</p>
                                    </div>
                                    <Row className='d-flex pt-3 gy-3'>
                                        <Col className="col-12">
                                            <div className='d-grid d-sm-flex gap-1'>
                                                <div className="w-100 aspect-2-1 half-border-rad overflow-hidden">
                                                    <img src={item?.thumbnail} className='w-100 h-100 object-cover' alt="" />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className="col-12">
                                            <Row className='gy-3'>
                                                <Col className='col-12'>
                                                    <div className="d-grid gap-1">
                                                        <h6 className='m-0 fw-semibold'>Cost</h6>
                                                        <div className='d-grid'>
                                                            <p className='fs-14 fs-sm-12 m-0 p-color'>Normal cost : ₹{item?.cost}</p>
                                                            <p className='fs-14 fs-sm-12 m-0 color-temp'>Premium cost : ₹{item?.premiumCost}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className='col-12'>
                                                    <div className="d-grid gap-1">
                                                        <h6 className='m-0 fw-semibold'>Availability</h6>
                                                        <p className='fs-14 fs-sm-12 m-0 p-color'>{item?.availability}</p>
                                                    </div>
                                                </Col>
                                                <Col className='col-12'>
                                                    <div className="d-grid gap-1">
                                                        <h6 className='m-0 fw-semibold'>How it works</h6>
                                                        <div className='fs-14 fs-sm-12 m-0 p-color' dangerouslySetInnerHTML={{ __html: item?.howItWorks }}>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className='col-12'>
                                                    <button variant='secondary' className='cs-temp-btn' as={Link} to={'/search-result'}>Browse eligible products</button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        ))
                    } */}
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default SellerBranding