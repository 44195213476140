import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Row, Spinner, Tab, Tabs } from 'react-bootstrap'
import { CiLocationOn } from 'react-icons/ci';
import { IoStorefrontOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { pattern } from '../../Config/Constant';
import { SiReacthookform } from 'react-icons/si';
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';
import { frontEnd_API, header, token } from '../../Config/Config';
import axios from 'axios';
import { addStore, updateStore } from '../../Store/Slices/StoreSlice';
import { RiShareBoxLine } from 'react-icons/ri';
import FormComponent from '../../Components/FormComponent';
import moment from 'moment/moment';
import { GiTakeMyMoney } from "react-icons/gi";
import Swal from 'sweetalert2';
import Loader from '../../Components/Loader';
import { Tooltip } from 'react-tooltip';

function SellerStoreSetting() {
    const [storeData, setStoreData] = useState({});
    const [stateDataShop, setStateDataShop] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const dispatch = useDispatch();
    const [err, setErr] = useState({});
    const activeStore = useSelector((state) => state.store.value)
    const [themeValue, setThemeValue] = useState((activeStore?.currentTheme) ?? 'theme-one');
    const [newLogo, setNewLogo] = useState(null);
    const [newBrandingDarkLogo, setNewBrandingDarkLogo] = useState(null);
    const [newBrandingLightLogo, setNewBrandingLightLogo] = useState(null);
    const [loader, setLoader] = useState({
        store: false,
        theme: false,
        social: false,
        coupon: false,
        page: false,
        banner: false
    });
    const [tabHandler, setTabHandler] = useState("updateDetails");
    const [returnToStore, setReturnToStore] = useState(activeStore?.isReturnToStore ?? 0);

    useEffect(() => {
        axios.get(frontEnd_API.getGlobal, header)
            .then((res) => {
                setStateDataShop(res?.data?.data);
                setStateData(res?.data?.data);
            })
            .catch((e) => {
                console.log(e);
            })
    }, [])

    const getSingleStore = async () => {
        setLoader({
            ...loader,
            store: true,
        })
        try {
            const { data } = await axios.get(`${frontEnd_API.getSingleStore}/${activeStore.id}`, header)
            setStoreData(data?.data);
            dispatch(addStore(data?.data));
            console.log(data?.data);
            setLoader({
                ...loader,
                store: false,
            })
        }
        catch (e) {
            console.log(e);
            setLoader({
                ...loader,
                store: false,
            })
        }
    }

    useEffect(() => {
        getSingleStore();
    }, [activeStore.id, dispatch])

    console.log("storeData :: ", storeData)
    const handleInputChange = (val) => {
        delete err[val?.name];

        // For Chacking Required value
        if (val?.pattern || val?.required) {
            if (val?.required && val?.value?.length <= 0) {
                err[val?.name] = `${val?.name} is required`
            }

            if (val?.pattern && val?.value?.length > 0) {
                const patternCheck = new RegExp(val?.pattern);
                if (!patternCheck.test(val?.value))
                    err[val?.name] = `Invalid pattern for ${val?.name}`;
            }
        }

        const { name, value } = val;
        setStoreData((prevData) => ({
            ...prevData,
            [name]: value
        }))

        if (val?.file) {
            const fileType = val?.file?.type;
            const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp', 'image/svg+xml'];

            if (!allowedTypes.includes(fileType)) {
                console.log(fileType);
                setErr({ logo: 'Only PNG, JPG, JPEG, WEBP, and SVG files are allowed.' });

                if (name == 'brandingDarkLogo') 
                    setNewBrandingDarkLogo(null)

                if (name == 'brandingLightLogo') 
                    setNewBrandingLightLogo(null)

                if (name == 'logo') 
                    setNewLogo(null)
                    
                setStoreData((prevData) => ({
                    ...prevData,
                    [name]: null
                }))
            } else {
                if (name == 'brandingDarkLogo') 
                    setNewBrandingDarkLogo(val?.value)

                if (name == 'brandingLightLogo') 
                    setNewBrandingLightLogo(val?.value)

                if (name == 'logo') 
                    setNewLogo(val?.value)
                    
                setStoreData((prevData) => ({
                    ...prevData,
                    [name]: val?.file
                }))
            }
        }
    }

    const manageSameAddress = (obj = {}) => {
        if (obj?.value !== storeData[obj?.compareKey]) {
            setIsChecked(false)
        }
    }

    const handleAddressChange = () => {
        if (
            storeData?.addressFirstLine &&
            storeData?.addressSecondLine &&
            storeData?.state &&
            storeData?.city &&
            storeData?.pinCode &&
            storeData?.mobileNumber &&
            storeData?.emailAddress
        ) {
            setIsChecked(!isChecked);
            if (!isChecked) {
                setStoreData((prevData) => ({
                    ...prevData,
                    billingAddressFirstLine: storeData?.addressFirstLine,
                    billingAddressSecondLine: storeData?.addressSecondLine,
                    billingState: storeData?.state,
                    billingCity: storeData?.city,
                    billingPinCode: storeData?.pinCode,
                    billingMobileNumber: storeData?.mobileNumber,
                    billingEmailAddress: storeData?.emailAddress
                }))
            }
            else {
                setStoreData((prevData) => ({
                    ...prevData,
                    billingAddressFirstLine: '',
                    billingAddressSecondLine: '',
                    billingState: '',
                    billingCity: '',
                    billingPinCode: '',
                    billingMobileNumber: '',
                    billingEmailAddress: ''
                }))
            }
        } else {
            toast("Fill Shop Address First !")
            setIsChecked(false);
        }

    }

    const submitStoreData = () => {
        Swal.fire({
            title: `Are you sure to update store details?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#12715B',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (Object.values(err).length === 0) {
                    // Add Country in data
                    setLoader({
                        ...loader,
                        store: true
                    })
                    const addCountry = {
                        ...storeData,
                        country: 'India',
                        billingCountry: 'India'
                    };
                    delete addCountry.storeCode;

                    addCountry.brandingStatus = parseInt(addCountry?.brandingStatus) > 0 ? 1 : 0
                    
                    console.log("addCountry:: 217 :: ", addCountry);
                    axios.put(`${frontEnd_API.postStore}/${addCountry.id}`, addCountry, {
                        headers: {
                            'token': token,
                            'Content-Type': (storeData?.logo?.name?.length > 0) ? 'multipart/form-data' : 'application/json'
                        }
                    })
                        .then((res) => {
                            console.log("res::", res);
                            getSingleStore();
                            setStoreData(res?.data?.data);
                            setErr({});
                            setLoader({
                                ...loader,
                                store: false,
                            })
                            Swal.fire(
                                'Store details updated successfully!',
                                '',
                                'success'
                            );
                        })
                        .catch((e) => {
                            console.log("error::", e);
                            setErr(e?.response?.data?.data)
                            setLoader({
                                ...loader,
                                store: false,
                            })
                            Swal.fire(
                                e?.response?.data?.message,
                                "",
                                'error'
                            );
                        })
                }
            }
        });
    }

    useEffect(() => {
        if (storeData?.billingAddressFirstLine ||
            storeData?.billingAddressSecondLine ||
            storeData?.billingState ||
            storeData?.billingCity ||
            storeData?.billingPinCode ||
            storeData?.billingMobileNumber ||
            storeData?.billingEmailAddress
        ) {
            if (
                storeData?.billingAddressFirstLine === storeData?.addressFirstLine &&
                storeData?.billingAddressSecondLine === storeData?.addressSecondLine &&
                storeData?.billingState === storeData?.state &&
                storeData?.billingCity === storeData?.city &&
                storeData?.billingPinCode === storeData?.pinCode &&
                storeData?.billingMobileNumber === storeData?.mobileNumber &&
                storeData?.billingEmailAddress === storeData?.emailAddress
            ) {
                setIsChecked(true);
            }
            else {
                setIsChecked(false);
            }
        }
    }, [storeData]);

    // Change Theme Code

    const themeOption = [
        {
            image: require('../../Assets/Images/store-theme-1.png'),
            value: 'theme-one'
        },
        {
            image: require('../../Assets/Images/store-theme-2.png'),
            value: 'theme-two'
        },
        {
            image: require('../../Assets/Images/store-theme-3.png'),
            value: 'theme-three'
        },
        {
            image: require('../../Assets/Images/store-theme-4.png'),
            value: 'theme-four'
        },
        {
            image: require('../../Assets/Images/store-theme-5.png'),
            value: 'theme-five'
        },
    ]

    const submitThemeData = async () => {
        setLoader({
            ...loader,
            theme: true
        })
        try {
            const { data } = await axios.put(`${frontEnd_API.themeChange}/${activeStore.id}`, { currentTheme: themeValue }, header)
            console.log(themeValue);
            console.log(data);
            dispatch(updateStore(data?.data))
            Swal.fire(
                'Store theme updated successfully!',
                '',
                'success'
            );
            setLoader({
                ...loader,
                theme: false
            })
        }
        catch (e) {
            console.log("err::", e);
            setLoader({
                ...loader,
                theme: false
            })
        }
    }

    // Update Social Media

    const socialLinks = [
        {
            value: 'whatsapp',
            label: 'WhatsApp'
        },
        {
            value: 'facebook',
            label: 'Facebook'
        },
        {
            value: 'instagram',
            label: 'Instagram'
        },
        {
            value: 'linkedin',
            label: 'Linkedin'
        },
        {
            value: 'twitter-x',
            label: 'Twitter'
        },
        {
            value: 'pinterest',
            label: 'Pinterest'
        }
    ];

    const [listData, setListData] = useState([]);

    const callSocialList = async () => {
        setLoader({
            ...loader,
            social: true
        })
        try {
            const { data } = await axios.put(frontEnd_API.snapStore, {}, header)
            console.log("ListData::", data);
            if (data?.data?.non_field_error) {
                setListData([]);
            }
            else {
                setListData(data?.data);
            }
            setLoader({
                ...loader,
                social: false
            })
        }
        catch (e) {
            console.log("err::", e);
            setListData([]);
            setLoader({
                ...loader,
                social: false
            })
        }
        setUpdateSocialLink();
        setUpdateSocialLinkId();
    }

    // useEffect(() => {
    //     callSocialList();
    // }, []);

    const deleteLink = async (item) => {
        Swal.fire({
            title: `Are you sure to delete ${item.icon}'s data?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#12715B',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoader({
                    ...loader,
                    social: true
                })
                try {
                    const { data } = await axios.delete(`${frontEnd_API.snapStore}/${item.id}`, header)
                    console.log(data);
                    Swal.fire(
                        'Data Deleted successfully!',
                        '',
                        'success'
                    );
                    callSocialList();
                    setLoader({
                        ...loader,
                        social: false
                    })
                }
                catch (e) {
                    console.log(e);
                    setLoader({
                        ...loader,
                        social: false
                    })
                }
            }
        });
    }

    const handleLinkStatus = async (item) => {
        console.log("update Status::", (item.status === 1) ? 0 : 1);
        try {
            const { data } = await axios.put(`${frontEnd_API.snapStore}/status/${item.id}`,
                { status: (item.status === 1) ? 0 : 1 },
                header
            )
            console.log("update Status::", data);
            toast(`${socialLinks?.filter(e => item?.icon == e?.value)[0]?.label}'s link is ${(data.data.status === 0) ? 'inactive' : 'active'} !`, { className: (data.data.status === 0) ? 'text-danger' : 'text-success' })
            callSocialList();
        }
        catch (e) {
            console.log(e);
        }
    }

    const [updateSocialLink, setUpdateSocialLink] = useState();
    const [updateSocialLinkId, setUpdateSocialLinkId] = useState();

    const handleUpdateLink = (item) => {
        // const check = 
        setUpdateSocialLink({ 'icon': item.icon, 'link': item.link })
        setUpdateSocialLinkId(item.id);
        return item.id;
    }


    // coupon section ------------
    const [couponListData, setCouponListData] = useState([]);
    const callCouponList = async () => {
        setLoader({
            ...loader,
            coupon: true
        })
        try {
            const { data } = await axios.put(frontEnd_API.coupon, {}, header)
            console.log("CouponListData::", data);
            if (data?.data?.non_field_error) {
                setCouponListData([]);
            }
            else {
                setCouponListData(data?.data);
            }
            setLoader({
                ...loader,
                coupon: false
            })
        }
        catch (e) {
            console.log("err::", e);
            setCouponListData([]);
            setLoader({
                ...loader,
                coupon: false
            })
        }
        setUpdateCoupon();
        setUpdateCouponId();
    }

    // useEffect(() => {
    //     callCouponList();
    // }, []);

    const deleteCoupon = async (item) => {
        Swal.fire({
            title: `Are you sure to delete coupon data?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#12715B',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoader({
                    ...loader,
                    coupon: true
                })
                try {
                    const { data } = await axios.delete(`${frontEnd_API.coupon}/${item.id}`, header)
                    console.log(data);
                    Swal.fire(
                        'Data Deleted successfully!',
                        '',
                        'success'
                    );
                    callCouponList();
                    setLoader({
                        ...loader,
                        coupon: false
                    })
                }
                catch (e) {
                    console.log(e);
                    setLoader({
                        ...loader,
                        coupon: false
                    })
                }
            }
        });
    }

    const handleCouponStatus = async (item) => {
        try {
            const { data } = await axios.put(`${frontEnd_API.coupon}/status/${item.id}`,
                { status: (item.status === 1) ? 0 : 1 },
                header
            )
            console.log("update Status::", data);
            toast(`Coupon code is ${(item.status === 1) ? 'inactive' : 'active'} !`, { className: (item.status === 1) ? 'text-danger' : 'text-success' })
            callCouponList();
        }
        catch (e) {
            console.log(e);
        }
    }

    const [updateCoupon, setUpdateCoupon] = useState();
    const [updateCouponId, setUpdateCouponId] = useState();

    const handleUpdateCoupon = (item) => {
        const obj = item

        delete obj?.status
        delete obj?.totalUsed
        
        setUpdateCoupon({
            ...obj,
            expireDate: moment(item.expireDate)?.format('YYYY-MM-DD')
        })
        setUpdateCouponId(obj.id);
        return item.id;
    }

    // ------------


    // page section ------------
    const [pageListData, setPageListData] = useState([]);
    const callPageList = async () => {
        setLoader({
            ...loader,
            page: true
        })
        try {
            const { data } = await axios.put(frontEnd_API.page, {}, header)
            console.log("PageListData::", data);
            if (data?.data?.non_field_error) {
                setPageListData([]);
            }
            else {
                setPageListData(data?.data);
            }
            setLoader({
                ...loader,
                page: false
            })
        }
        catch (e) {
            console.log("err::", e);
            setPageListData([]);
            setLoader({
                ...loader,
                page: false
            })
        }
        setUpdatePage();
        setUpdatePageId();
    }

    // useEffect(() => {
    //     callPageList();
    // }, []);

    const deletePage = async (item) => {
        console.log(item);
        Swal.fire({
            title: `Are you sure to delete ${item?.name}'s data?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#12715B',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoader({
                    ...loader,
                    page: true
                })
                try {
                    const { data } = await axios.delete(`${frontEnd_API.page}/${item.id}`, header)
                    console.log(data);
                    Swal.fire(
                        'Data Deleted successfully!',
                        '',
                        'success'
                    );
                    callPageList();
                    setLoader({
                        ...loader,
                        page: false
                    })
                }
                catch (e) {
                    console.log(e);
                    setLoader({
                        ...loader,
                        page: false
                    })
                }
            }
        });
    }

    const handlePageStatus = async (item) => {
        console.log("update Status::", (item.status === 1) ? 0 : 1);
        try {
            const { data } = await axios.put(`${frontEnd_API.page}/status/${item.id}`,
                { status: (item.status === 1) ? 0 : 1 },
                header
            )
            console.log("update Status::", data);
            toast(`page is now ${(data.data.status === 0) ? 'inactive' : 'active'} !`, { className: (data.data.status === 0) ? 'text-danger' : 'text-success' })
            callPageList();
        }
        catch (e) {
            console.log(e);
        }
    }

    const [updatePage, setUpdatePage] = useState();
    const [updatePageId, setUpdatePageId] = useState();
    console.log('updatePageId :: ', updatePageId)
    const handleUpdatePage = (item) => {
        // const check = 
        setUpdatePage({ 'name': item.name, 'description': item.description })
        setUpdatePageId(item.id);
        return item.id;
    }

    // ------------

    // 
    // Banner Tab code
    // 

    const [storeCategoryData, setStoreCategoryData] = useState([]);
    const [bannerList, setBannerList] = useState([]);
    

    const callBannerList = async () => {
        setLoader({
            ...loader,
            banner: true
        })
        try {
            const { data } = await axios.put(frontEnd_API.banner, {}, header)
            console.log("ListData::", data);
            if (data?.data?.non_field_error) {
                setBannerList([]);
                setLoader({
                    ...loader,
                    banner: false
                })
            }
            else {
                setBannerList(data?.data);
                setLoader({
                    ...loader,
                    banner: false
                })
            }
        }
        catch (e) {
            console.log("err::", e);
            setBannerList([]);
        }
        setUpdateBanner();
        setUpdateBannerId();
    }


    const deleteBanner = async (item) => {
        console.log(item);
        Swal.fire({
            title: `Are you sure to delete ${item?.name}'s data?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#12715B',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoader({
                    ...loader,
                    banner: true
                })
                try {
                    const { data } = await axios.delete(`${frontEnd_API.banner}/${item.id}`, header)
                    console.log(data);
                    Swal.fire(
                        'Data Deleted successfully!',
                        '',
                        'success'
                    );
                    callBannerList();
                    setLoader({
                        ...loader,
                        banner: false
                    })
                }
                catch (e) {
                    console.log(e);
                    setLoader({
                        ...loader,
                        banner: false
                    })
                }
            }
        });
    }

    const handleBannerStatus = async (item) => {
        console.log("update Status::", (item.status === 1) ? 0 : 1);
        try {
            const { data } = await axios.put(`${frontEnd_API.banner}/status/${item.id}`,
                { status: (item.status === 1) ? 0 : 1 },
                header
            )
            console.log("update Status::", data);
            toast(`${item.name} is ${(data.data.status === 0) ? 'inactive' : 'active'} !`, { className: (data.data.status === 0) ? 'text-danger' : 'text-success' })
            callBannerList();
        }
        catch (e) {
            console.log(e);
        }
    }

    const [updateBanner, setUpdateBanner] = useState();
    const [updateBannerId, setUpdateBannerId] = useState();

    const handleUpdateBanner = (item) => {
        setUpdateBanner({ 'relatedId': item.relatedId, 'relatedName': item.relatedName, 'name': item.name, 'thumbnail': item.thumbnail })
        setUpdateBannerId(item.id);
        return item.id;
    }

    // function for get store category dropdown data
    const fetchStoreCategoryDropDown = async () => {
        try {
            const { data } = await axios.get(`${frontEnd_API.home}/storecategory`, header)
            setStoreCategoryData([...(data?.data?? [])])   
        }
        catch (e) {
            console.log(e);
            setStoreCategoryData([])
        }
    }

    useEffect(() => {
        callSocialList();
        callCouponList();
        callPageList();
        callBannerList();
        fetchStoreCategoryDropDown()
    }, []);

    const submitReturnDetails = async () => {
        try {
            const response = await axios.put(`${frontEnd_API?.storeReturnDetails}/${activeStore?.id}`, { isReturnToStore: returnToStore }, header)
            console.log("response?.data::", response?.data);
            if (response?.status == 200) {
                dispatch(updateStore(response?.data?.data))
                toast("Return Deatils Update successfully!")
            }
        }
        catch (e) {
            console.log("Err::", e);
        }
    }

    return (
        <div className='custom-store-setting'>
            <Container className='pb-5'>
                <div className='flex-between-align'>
                    <h2 className='m-0 fw-semibold py-4'>Store Setting</h2>
                    <Link
                        to={`https://${activeStore.domain}`}
                        target='_blanck'
                        className='custom-store-btn fw-semibold flex-center-align gap-1'
                    >
                        <span className='d-none d-sm-block'>Open Store</span>
                        <RiShareBoxLine className='flex-center-align' />
                    </Link>
                </div>
                <div className='seller-store-setting-container p-2 p-sm-3'>
                    <Tabs
                        defaultActiveKey="updateDetails"
                        id="fill-tab-example"
                        className="mb-3 custom-tabs"
                        activeKey={tabHandler}
                        onSelect={(e) => setTabHandler(e)}
                        fill
                    >
                        <Tab eventKey="updateDetails" title="Update Details" className='p-2 p-sm-3'>
                            <Container className='custom-store-detail container d-grid gap-4 gap-sm-5 position-relative'>
                                {/* <div className='d-grid align-content-center position-absolute w-100 top-0'>
                                    <div className='w-100 aspect-4-1 aspect-md-2-1'>
                                        <Loader />
                                    </div>
                                </div> */}
                                {
                                    loader?.store &&
                                    <div className='position-absolute w-100 h-100'>
                                        <div
                                            className='position-sticky top-0 start-0 w-100 flex-center-align'
                                            style={{
                                                backgroundColor: 'rgba(255,255,255,0.85)',
                                                zIndex: "9999",
                                                height: "100vh"
                                            }}
                                        >
                                            <div className='d-grid text-center text-success gap-1'>
                                                <div className='w-100 aspect-2-1 aspect-md-2-1'>
                                                    <Loader />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <Row className='custom-contain-align-center py-3'>
                                    <h3 className='fw-semibold text-center py-3'>Store Details</h3>
                                    <Col className='col-0 d-none d-md-block col-md-3 custom-store-image'>
                                        <IoStorefrontOutline />
                                    </Col>
                                    <Col className='col-12 col-md-9 d-grid gap-1 gap-sm-2 gap-lg-3 custom-store-detail-content'>
                                        <div className='d-grid'>
                                            <label htmlFor="name">Store Name</label>
                                            <input
                                                type="text"
                                                value={storeData?.name}
                                                name='name'
                                                id='name'
                                                className={(err?.name && !storeData?.name) && 'border-red'}
                                                onChange={(e) => {
                                                    const { name, value } = e.target;
                                                    handleInputChange({ name, value });
                                                }}
                                                required={true}
                                                autoComplete='off' />
                                            <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height flex-between-align'>{err?.name}</span>
                                        </div>
                                        <div className='d-grid'>
                                            <label htmlFor="shortDescription">Description <span className='fs-12'>(Optional)</span></label>
                                            <textarea
                                                name="shortDescription"
                                                id="shortDescription"
                                                rows={2}
                                                value={storeData?.shortDescription}
                                                onChange={(e) => handleInputChange({ name: e.target.name, value: e.target.value })}
                                                autoComplete='off'>
                                            </textarea>
                                            <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>
                                            </span>
                                        </div>
                                        <div className='d-grid custom-img-selector'>
                                            <label htmlFor="logo">
                                                <span>Store Logo</span>
                                                {
                                                    (storeData?.logo)
                                                        ? <div>
                                                            <img src={
                                                                newLogo
                                                                    ? URL.createObjectURL(newLogo)
                                                                    : storeData.logo
                                                            } alt="" />
                                                        </div>
                                                        : <div>
                                                            <i className="bi bi-image fs-40" />
                                                        </div>
                                                }
                                            </label>
                                            <input
                                                type="file"
                                                // value={storeData?.logo}
                                                name='logo'
                                                id='logo'
                                                className={(err?.logo && !storeData?.logo) && 'border-red'}
                                                onChange={(e) => {
                                                    handleInputChange({ name: 'logo', file: e.target.files[0] });
                                                    setNewLogo(e.target.files[0])
                                                }}
                                                required={true}
                                                accept=".png, .jpg, .jpeg, .webp, .svg"
                                                autoComplete='off' />
                                            <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height flex-between-align'>{err?.logo}</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='custom-contain-align-center py-3'>
                                    <h3 className='fw-semibold text-center py-3'>Is COD available ?</h3>
                                    <Col className='col-0 d-none d-md-block col-md-3 custom-store-image'>
                                        <GiTakeMyMoney />
                                    </Col>
                                    <Col className='col-12 col-md-9 d-grid gap-1 gap-sm-2 gap-lg-3'>
                                        <div className='d-grid d-sm-flex align-items-center check-holder gap-2'>
                                            <input
                                                type="checkbox"
                                                name='isCodAvailable'
                                                id='isCodAvailable'
                                                checked={storeData?.isCodAvailable == 1 ? true : false}
                                                className={`${(err?.isCodAvailable && !storeData?.isCodAvailable) && 'border-red'} ui-checkbox`}
                                                onChange={(e) => {
                                                    setStoreData((prevData) => ({
                                                        ...prevData,
                                                        isCodAvailable: e.target.checked ? 1 : 0
                                                    }))
                                                }}
                                                autoComplete='off' />
                                            <label htmlFor="isCodAvailable" className='fs-16 fs-sm-12'>Enable this checkbox to offer Cash on Delivery (COD) as a payment option for your customers.</label>
                                        </div>
                                    </Col>
                                </Row>
                                {/*
                                <Row className='custom-contain-align-center py-3'>
                                    <h3 className='fw-semibold text-center py-3'>About Branding</h3>
                                    <Col className='col-0 d-none d-md-block col-md-3 custom-store-image'>
                                        <GiTakeMyMoney />
                                    </Col>
                                    <Col className='col-12 col-md-9 d-grid gap-1 gap-sm-2 gap-lg-3'>
                                        <div className='d-flex flex-column gap-2 check-holder gap-2'>
                                            <label className='fs-16 fs-sm-12'>Do you want to enable custom branding services for this product?</label>
                                            <div className='d-flex align-items-center gap-2'>
                                                <input
                                                    type="checkbox"
                                                    name='brandingStatus'
                                                    id='brandingStatus'
                                                    checked={storeData?.brandingStatus == 1 ? true : false}
                                                    className={`${(err?.brandingStatus && !storeData?.brandingStatus) && 'border-red'} ui-checkbox`}
                                                    onChange={(e) => {
                                                        setStoreData((prevData) => ({
                                                            ...prevData,
                                                            brandingStatus: e.target.checked ? 1 : 0
                                                        }))
                                                    }}
                                                    autoComplete='off'
                                                />
                                                <label htmlFor="brandingStatus">Yes</label>
                                            </div>
                                            <div className='d-flex gap-4 align-items-center mt-2'>
                                                <div className='d-grid custom-img-selector'>
                                                    <label htmlFor="brandingDarkLogo">
                                                        <span>Branding Dark Logo</span>
                                                        {
                                                            (storeData?.brandingDarkLogo)
                                                                ? <div>
                                                                    <img src={
                                                                        newBrandingDarkLogo
                                                                            ? URL.createObjectURL(newBrandingDarkLogo)
                                                                            : storeData.brandingDarkLogo
                                                                    } alt="" />
                                                                </div>
                                                                : <div>
                                                                    <i className="bi bi-image fs-40" />
                                                                </div>
                                                        }
                                                    </label>
                                                    <input
                                                        type="file"
                                                        name='brandingDarkLogo'
                                                        id='brandingDarkLogo'
                                                        className={(err?.brandingDarkLogo && !storeData?.brandingDarkLogo) && 'border-red'}
                                                        onChange={(e) => {
                                                            handleInputChange({ name: 'brandingDarkLogo', file: e.target.files[0] });
                                                            setNewBrandingDarkLogo(e.target.files[0])
                                                        }}
                                                        accept=".png, .jpg, .jpeg"
                                                        autoComplete='off' />
                                                    <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height flex-between-align'>{err?.brandingDarkLogo}</span>
                                                </div>
                                                <div className='d-grid custom-img-selector'>
                                                    <label htmlFor="brandingLightLogo">
                                                        <span>Branding Light Logo</span>
                                                        {
                                                            (storeData?.brandingLightLogo)
                                                                ? <div>
                                                                    <img src={
                                                                        newBrandingLightLogo
                                                                            ? URL.createObjectURL(newBrandingLightLogo)
                                                                            : storeData.brandingLightLogo
                                                                    } alt="" />
                                                                </div>
                                                                : <div>
                                                                    <i className="bi bi-image fs-40" />
                                                                </div>
                                                        }
                                                    </label>
                                                    <input
                                                        type="file"
                                                        name='brandingLightLogo'
                                                        id='brandingLightLogo'
                                                        className={(err?.brandingLightLogo && !storeData?.brandingLightLogo) && 'border-red'}
                                                        onChange={(e) => {
                                                            handleInputChange({ name: 'brandingLightLogo', file: e.target.files[0] });
                                                            setNewBrandingLightLogo(e.target.files[0])
                                                        }}
                                                        accept=".png, .jpg, .jpeg"
                                                        autoComplete='off' />
                                                    <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height flex-between-align'>{err?.brandingLightLogo}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                */}
                                <Row className='custom-contain-align-center py-3'>
                                    <h3 className='fw-semibold text-center py-3 py-sm-4'>Shop Address Details</h3>
                                    <Col className='d-none d-md-block col-md-3 custom-store-image'>
                                        <CiLocationOn className='mt-4' />
                                    </Col>
                                    <Col className='col-12 col-md-9 custom-store-detail-content d-grid gap-3'>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6 d-grid'>
                                                <label htmlFor="addressFirstLine">Address First Line</label>
                                                <textarea
                                                    name="addressFirstLine"
                                                    id="addressFirstLine"
                                                    rows={2}
                                                    className={(err?.addressFirstLine && !storeData?.addressFirstLine) && 'border-red'}
                                                    value={storeData?.addressFirstLine}
                                                    autoComplete='off'
                                                    onChange={(e) => handleInputChange({ name: e.target.name, value: e.target.value })}
                                                    placeholder="Ex. 'Shop no., Building name'" ></textarea>
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{(!storeData?.addressFirstLine) && err?.addressFirstLine}</span>
                                            </div>
                                            <div className='col-12 col-sm-6 d-grid'>
                                                <label htmlFor="addressSecondLine">Address Second Line</label>
                                                <textarea
                                                    name="addressSecondLine"
                                                    id="addressSecondLine"
                                                    rows={2}
                                                    className={(err?.addressSecondLine && !storeData?.addressSecondLine) && 'border-red'}
                                                    value={storeData?.addressSecondLine}
                                                    autoComplete='off'
                                                    onChange={(e) => handleInputChange({ name: e.target.name, value: e.target.value })}
                                                    placeholder="Ex. 'Landmark'"></textarea>
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{(!storeData?.addressSecondLine) && err?.addressSecondLine}</span>
                                            </div>
                                        </div>
                                        <div className='row d-flex'>
                                            <div className='col-6 col-sm-4 custom-over-hidden d-grid'>
                                                <label htmlFor="state">State</label>
                                                <select
                                                    value={storeData?.state}
                                                    name='state'
                                                    id='state'
                                                    className={`text-capitalize ${(err?.state && !storeData?.state) && 'border-red'}`}
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        handleInputChange({ name: e.target.name, value: e.target.value })
                                                    }}>
                                                    <option value={0}>Select</option>
                                                    {
                                                        stateDataShop.map((item, index) => {
                                                            return (
                                                                <option key={index}>{item?.label}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{(!storeData?.state) && err?.state}</span>
                                            </div>
                                            <div className='col-6 col-sm-4 custom-over-hidden d-grid'>
                                                <label htmlFor="city">City</label>
                                                <select
                                                    value={storeData?.city}
                                                    name='city'
                                                    id='city'
                                                    className={`text-capitalize ${(err?.city && !storeData?.city) && 'border-red'}`}
                                                    autoComplete='off'
                                                    onChange={(e) => handleInputChange({
                                                        name: e.target.name, value: e.target.value
                                                    })}>
                                                    <option value={0}>Select</option>
                                                    {
                                                        stateDataShop.filter(item => item.value === storeData?.state)[0]?.children.map((item, index) => {
                                                            return (
                                                                <option key={index}>{item?.label}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{(!storeData?.city) && err?.city}</span>
                                            </div>
                                            <div className='col-12 col-sm-4 custom-over-hidden d-grid'>
                                                <label htmlFor="pinCode">Pincode</label>
                                                <input
                                                    type="number"
                                                    value={storeData?.pinCode}
                                                    name='pinCode'
                                                    id='pinCode'
                                                    className={(err?.pinCode && !storeData?.pinCode) && 'border-red'}
                                                    autoComplete='off'
                                                    onChange={(e) => handleInputChange({
                                                        name: e.target.name,
                                                        value: e.target.value,
                                                        pattern: pattern.pinCode
                                                    })} />
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{err?.pinCode}</span>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6 d-grid'>
                                                <label htmlFor="mobileNumber">Mobile</label>
                                                <input
                                                    type="number"
                                                    value={storeData?.mobileNumber}
                                                    name='mobileNumber'
                                                    id='mobileNumber'
                                                    className={(err?.mobileNumber && !storeData?.mobileNumber) && 'border-red'}
                                                    autoComplete='off'
                                                    onChange={(e) => handleInputChange({
                                                        name: e.target.name,
                                                        value: e.target.value,
                                                        pattern: pattern.mobile
                                                    })} />
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{err?.mobileNumber}</span>
                                            </div>
                                            <div className='col-12 col-sm-6 d-grid'>
                                                <label htmlFor="emailAddress">Email</label>
                                                <input
                                                    type="email"
                                                    value={storeData?.emailAddress}
                                                    name='emailAddress'
                                                    id='emailAddress'
                                                    className={(err?.emailAddress && !storeData?.emailAddress) && 'border-red'}
                                                    autoComplete='off'
                                                    onChange={(e) => handleInputChange({
                                                        name: e.target.name,
                                                        value: e.target.value,
                                                        pattern: pattern.email
                                                    })} />
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{err?.emailAddress}</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='custom-contain-align-center py-3'>
                                    <h3 className='fw-semibold text-center py-3 py-sm-4'>Billing Address Details</h3>
                                    <Col className='d-none d-md-block col-md-3 custom-store-image'>
                                        <SiReacthookform className='mt-4' />
                                    </Col>
                                    <Col className='col-12 col-md-9 custom-store-detail-content d-grid gap-3'>
                                        <div className='row'>
                                            <div className='col-12 pb-3'>
                                                <div className='d-flex py-2 gap-2 align-items-center check-holder'>
                                                    <input type="checkbox" id='same' className='ui-checkbox' autoComplete='off' style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                                                        onChange={handleAddressChange}
                                                        checked={isChecked}
                                                    />
                                                    <label htmlFor='same' className='fs-20 fw-semibold' style={{ cursor: 'pointer' }}>Same as shop address</label>
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-6 d-grid'>
                                                <label htmlFor="billingAddressFirstLine">Address First Line</label>
                                                <textarea
                                                    name="billingAddressFirstLine"
                                                    id="billingAddressFirstLine"
                                                    rows={2}
                                                    className={(err?.billingAddressFirstLine && !storeData?.billingAddressFirstLine) && 'border-red'}
                                                    value={storeData?.billingAddressFirstLine}
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        handleInputChange({ name: e.target.name, value: e.target.value });
                                                        manageSameAddress({ value: e.target.value, compareKey: 'addressFirstLine' })
                                                    }}
                                                    placeholder="Ex. 'Shop no., Building name'" ></textarea>
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{(!storeData?.billingAddressFirstLine) && err?.billingAddressFirstLine}</span>
                                            </div>
                                            <div className='col-12 col-sm-6 d-grid'>
                                                <label htmlFor="billingAddressSecondLine">Address Second Line</label>
                                                <textarea
                                                    name="billingAddressSecondLine"
                                                    id="billingAddressSecondLine"
                                                    rows={2}
                                                    className={(err?.billingAddressSecondLine && !storeData?.billingAddressSecondLine) && 'border-red'}
                                                    value={storeData?.billingAddressSecondLine}
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        handleInputChange({ name: e.target.name, value: e.target.value })
                                                        manageSameAddress({ value: e.target.value, compareKey: 'addressSecondLine' })
                                                    }}
                                                    placeholder="Ex. 'Landmark'"></textarea>
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{(!storeData?.billingAddressSecondLine) && err?.billingAddressSecondLine}</span>
                                            </div>
                                        </div>
                                        <div className='row d-flex'>
                                            <div className='col-6 col-sm-4 custom-over-hidden d-grid'>
                                                <label htmlFor="billingState">State</label>
                                                <select
                                                    value={storeData?.billingState}
                                                    name='billingState'
                                                    id='billingState'
                                                    className={`text-capitalize ${(err?.billingState && !storeData?.billingState) && 'border-red'}`}
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        handleInputChange({ name: e.target.name, value: e.target.value })
                                                        manageSameAddress({ value: e.target.value, compareKey: 'state' })
                                                    }}>
                                                    <option value={0}>Select</option>
                                                    {
                                                        stateData.map((item, index) => {
                                                            return (
                                                                <option key={index}>{item?.label}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{(!storeData?.billingState) && err?.billingState}</span>
                                            </div>
                                            <div className='col-6 col-sm-4 custom-over-hidden d-grid'>
                                                <label htmlFor="billingCity">City</label>
                                                <select
                                                    name='billingCity'
                                                    id='billingCity'
                                                    className={`text-capitalize ${(err?.billingCity && !storeData?.billingCity) && 'border-red'}`}
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        handleInputChange({
                                                            name: e.target.name,
                                                            value: e.target.value
                                                        })
                                                        manageSameAddress({ value: e.target.value, compareKey: 'city' })
                                                    }}
                                                    value={storeData?.billingCity}
                                                >
                                                    <option value={0}>Select</option>
                                                    {
                                                        stateData.filter(item => item.value === storeData?.billingState)[0]?.children.map((item, index) => {
                                                            return (
                                                                <option key={index}>{item?.label}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{(!storeData?.billingCity) && err?.billingCity}</span>
                                            </div>
                                            <div className='col-12 col-sm-4 custom-over-hidden d-grid'>
                                                <label htmlFor="billingPinCode">Pincode</label>
                                                <input
                                                    type="number"
                                                    value={storeData?.billingPinCode}
                                                    name='billingPinCode'
                                                    id='billingPinCode'
                                                    className={(err?.billingPinCode && !storeData?.billingPinCode) && 'border-red'}
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        handleInputChange({
                                                            name: e.target.name,
                                                            value: e.target.value,
                                                            pattern: pattern.pinCode
                                                        })
                                                        manageSameAddress({ value: e.target.value, compareKey: 'pinCode' })
                                                    }} />
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{err?.billingPinCode}</span>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6 d-grid'>
                                                <label htmlFor="billingMobileNumber">Mobile</label>
                                                <input
                                                    type="number"
                                                    value={storeData?.billingMobileNumber}
                                                    name='billingMobileNumber'
                                                    id='billingMobileNumber'
                                                    className={(err?.billingMobileNumber && !storeData?.billingMobileNumber) && 'border-red'}
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        handleInputChange({
                                                            name: e.target.name,
                                                            value: e.target.value,
                                                            pattern: pattern.mobile
                                                        })
                                                        manageSameAddress({ value: e.target.value, compareKey: 'mobileNumber' })
                                                    }} />
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{err?.billingMobileNumber}</span>
                                            </div>
                                            <div className='col-12 col-sm-6 d-grid'>
                                                <label htmlFor="billingEmailAddress">Billing Email</label>
                                                <input
                                                    type="email"
                                                    value={storeData?.billingEmailAddress}
                                                    name='billingEmailAddress'
                                                    id='billingEmailAddress'
                                                    className={(err?.billingEmailAddress && !storeData?.billingEmailAddress) && 'border-red'}
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        handleInputChange({
                                                            name: e.target.name,
                                                            value: e.target.value,
                                                            pattern: pattern.email
                                                        })
                                                        manageSameAddress({ value: e.target.value, compareKey: 'emailAddress' })
                                                    }} />
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{err?.billingEmailAddress}</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className='d-flex justify-content-center pt-3 pt-md-4'>
                                    <Button
                                        className={`next-button text-decoration-none fw-bold d-grid p-0`}
                                        onClick={() => {
                                            submitStoreData();
                                        }}

                                        style={{ border: "0px", background: "transparent", pointerEvents: (err) && (Object.values(err)?.length !== 0) ? 'none' : 'auto', opacity: (err) && (Object.values(err)?.length !== 0) ? '.1' : '1' }}
                                    >
                                        <button
                                            type="button"
                                            className={`button active-btn ${(loader?.store ? "final-active-btn" : '')}`}
                                            disabled={loader?.store}
                                        >
                                            <span className="button__text">Save</span>
                                            <span className="button__icon">
                                                <MdKeyboardDoubleArrowRight className="svg" />
                                            </span>
                                        </button>
                                    </Button>
                                </div>
                            </Container>
                        </Tab>
                        <Tab eventKey="changeTheme" title="Change Theme" className='p-2 p-sm-3'>
                            <div className='d-grid gap-2'>
                                <div className='d-grid gap-2 px-2'>
                                    <h4 className='fw-semibold m-0'>
                                        Select theme
                                    </h4>
                                    <p className='fs-sm-12 fs-lg-14 m-0 p-color'>Personalize your store's look. Select a theme, implement it, and switch it whenever you wish.</p>
                                </div>
                                <Container className='py-4'>
                                    <Row className='gy-4 position-relative'>
                                        {
                                            loader?.theme &&
                                            <div className='position-absolute w-100 h-100'>
                                                <div
                                                    className='position-sticky top-0 start-0 w-100 flex-center-align'
                                                    style={{
                                                        backgroundColor: 'rgba(255,255,255,0.85)',
                                                        zIndex: "9999",
                                                        height: "100vh"
                                                    }}
                                                >
                                                    <div className='d-grid text-center text-success gap-1'>
                                                        <div className='w-100 aspect-2-1 aspect-md-2-1'>
                                                            <Loader />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            themeOption.map((item, index) => {
                                                return (
                                                    <Col
                                                        key={index}
                                                        className={'col-12 col-sm-6 col-lg-4 d-grid'}
                                                        onClick={() => setThemeValue(item.value)}
                                                    >
                                                        <div className={`
                                                            custom-img-holder 
                                                            ${(themeValue === item.value) && 'custom-img-holder-active'}`}>
                                                            <img src={item.image} alt="" />
                                                            <span className={`custom-span ${(themeValue === item.value) && 'custom-span-active'}`}>
                                                                <i className={`bi bi-check-lg 
                                                                    ${(themeValue !== item.value) && 'd-none'}`}></i>
                                                            </span>
                                                        </div>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                                    <div className='d-flex justify-content-center pt-3 pt-md-4'>
                                        <Link
                                            className={`next-button text-decoration-none fw-bold d-grid`}
                                            onClick={submitThemeData}>
                                            <button type="button" className={`button active-btn`}>
                                                <span className="button__text">Save</span>
                                                <span className="button__icon">
                                                    <MdKeyboardDoubleArrowRight className="svg" />
                                                </span>
                                            </button>
                                        </Link>
                                    </div>
                                </Container>
                            </div>
                        </Tab>
                        <Tab eventKey="manageMedia" title="Social Media" className='p-2 p-sm-3'>
                            <div className='d-grid gap-3'>
                                {/* Top Content */}
                                <div className='d-grid gap-2 px-2'>
                                    <h4 className='fw-semibold m-0'>
                                        Manage Social Media
                                    </h4>
                                    <p className='fs-sm-12 fs-lg-14 m-0 p-color'>Connect your social media accounts for your customers to find you easily.</p>
                                </div>
                                <Container className='px-sm-0 py-sm-4'>
                                    <Row className='gy-4'>
                                        {/* <Col className='col-12 col-md-5 d-grid py-3 custom-border-box'> */}
                                        <Col className='col-12 col-md-5 px-0'>
                                            <div className='custom-border-box p-3'>
                                                <FormComponent
                                                    action={frontEnd_API.snapStore}
                                                    valueData={(updateSocialLink) && updateSocialLink}
                                                    actionType={`${(updateSocialLink) ? 'put' : 'post'}`}
                                                    actionValue={(updateSocialLink) && updateSocialLinkId}
                                                    submitFullWidth={false}
                                                    onSubmit={(e) => callSocialList(e)}
                                                    formMessage={(updateSocialLink) ? "Link Updated in store !" : "Link Added to store !"}
                                                    fields={[
                                                        {
                                                            type: 'select',
                                                            options: socialLinks,
                                                            divClass: 'col-12',
                                                            name: 'icon',
                                                            key: 'icon',
                                                            required: true,
                                                        },
                                                        {
                                                            divClass: 'col-12',
                                                            name: 'link',
                                                            key: 'link',
                                                            required: true,
                                                            pattern: pattern.link,
                                                            placeholder: "Enter link"
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </Col>
                                        <Col className='col-12 col-md-7 d-grid px-0 px-sm-2 align-content-start'>
                                            {
                                                (listData.length === 0) ?
                                                    <h4 className='text-center p-color'>No Links Added</h4>
                                                    : <table className='cs-table'>
                                                        <thead>
                                                            <tr className='fs-sm-12'>
                                                                <th className='text-center'>Media</th>
                                                                <th className='text-center'>Link</th>
                                                                <th className='text-center'>Status</th>
                                                                <th className='text-center'>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                loader?.social ?
                                                                    <tr>
                                                                        <td colSpan={4}>
                                                                            <div className='w-100 aspect-2-1 aspect-md-2-1'>
                                                                                <Loader />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    : (listData?.length > 0) &&
                                                                    listData?.map((item, index) => {
                                                                        return (

                                                                            <tr key={index}>
                                                                                <td className='text-center'>
                                                                                    <i className={`fs-20 bi bi-${item.icon}`}></i>
                                                                                </td>
                                                                                <td className='fs-14 fs-sm-10'>
                                                                                    <Link className='d-block d-sm-none' to={item.link} target='_black'>
                                                                                        {
                                                                                            (item.link.length > 15)
                                                                                                ? item.link.substring(0, 15)
                                                                                                + '...'
                                                                                                : item.link
                                                                                        }
                                                                                    </Link>
                                                                                    <Link className='d-none d-sm-block' to={item.link} target='_black'>
                                                                                        {
                                                                                            (item.link.length > 30)
                                                                                                ? item.link.substring(0, 30)
                                                                                                + '...'
                                                                                                : item.link
                                                                                        }
                                                                                    </Link>
                                                                                </td>
                                                                                <td className='text-center'>
                                                                                    <label className="switch">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={(item.status === 1) ? true : false}
                                                                                            value={item.status}
                                                                                            onChange={() => handleLinkStatus(item)}
                                                                                        />
                                                                                        <span className="slider"></span>
                                                                                    </label>
                                                                                </td>
                                                                                <td>
                                                                                    <div className='flex-between-align custom-btns'>
                                                                                        <i className="fs-14 custom-btns-edit bi bi-pen-fill"
                                                                                            onClick={() => handleUpdateLink(item)} />
                                                                                        <i className="fs-14 custom-btns-delete bi bi-trash-fill" onClick={() => deleteLink(item)} />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                            }
                                                        </tbody>
                                                    </table>
                                            }

                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Tab>
                        <Tab eventKey="coupon" title="Add Coupon" className='p-2 p-sm-3'>
                            <div className='d-grid gap-3'>
                                {/* Top Content */}
                                <div className='d-grid gap-2 px-2'>
                                    <h4 className='fw-semibold m-0'>
                                        Store Coupon
                                    </h4>
                                    <p className='fs-sm-12 fs-lg-14 m-0 p-color'>Manage coupons for your snap store.</p>
                                </div>
                                <Container className='px-sm-0 py-sm-4'>
                                    <Row className='gy-4'>
                                        <Col className='col-12 col-md-8 d-grid py-3 custom-border-box'>
                                            <FormComponent
                                                action={frontEnd_API.coupon}
                                                valueData={(updateCoupon) && updateCoupon}
                                                actionType={`${(updateCoupon) ? 'put' : 'post'}`}
                                                actionValue={(updateCoupon) && updateCouponId}
                                                submitFullWidth={false}
                                                onSubmit={(e) => callCouponList(e)}
                                                formMessage={(updateCoupon) ? "Coupon Updated in store !" : "Coupon Added to store !"}
                                                fields={[
                                                    {
                                                        divClass: 'col-12 col-md-6',
                                                        name: 'coupon name',
                                                        key: 'name',
                                                        required: true,
                                                        pattern: pattern.textNumber,
                                                        placeholder: "Enter coupon name"
                                                    },
                                                    {
                                                        type: 'select',
                                                        options: [
                                                            {
                                                                value: 'PER',
                                                                label: 'percentage'
                                                            },
                                                            {
                                                                value: 'AMT',
                                                                label: 'amount'
                                                            }
                                                        ],
                                                        divClass: 'col-12 col-md-6',
                                                        name: 'type',
                                                        key: 'type',
                                                        required: true,
                                                    },
                                                    {
                                                        divClass: 'col-12 col-md-6',
                                                        name: 'value',
                                                        key: 'value',
                                                        required: true,
                                                        placeholder: "Enter amount or percentager value"
                                                    },
                                                    {
                                                        type: 'date',
                                                        divClass: 'col-12 col-md-6',
                                                        name: 'expire Date',
                                                        key: 'expireDate',
                                                        required: true,
                                                    },
                                                    {
                                                        divClass: 'col-12 col-md-6',
                                                        name: 'limit Condition',
                                                        key: 'limitCondition',
                                                        required: true,
                                                        placeholder: "Enter limit condition"
                                                    },
                                                    {
                                                        divClass: 'col-12 col-md-6',
                                                        name: 'max Off Amount',
                                                        key: 'maxOffAmount',
                                                        required: true,
                                                        placeholder: "Enter maximum discount in amount"
                                                    },
                                                    {
                                                        divClass: 'col-12 col-md-6',
                                                        name: 'user limit',
                                                        key: 'userLimit',
                                                        required: true,
                                                    },
                                                    {
                                                        divClass: 'col-12 col-md-6',
                                                        name: 'coupon limit',
                                                        key: 'couponLimit',
                                                        required: true,
                                                    },
                                                ]}
                                            />
                                        </Col>
                                        <Col className='col-12 d-grid px-0 px-sm-2 align-content-start'>
                                            {
                                                (couponListData.length === 0) ?
                                                    <h4 className='text-center p-color'>No Coupon Added Yet!</h4>
                                                    : <table className='cs-table'>
                                                        <thead>
                                                            <tr className='fs-sm-12 text-center text-capitalize'>
                                                                <th>Name</th>
                                                                <th>value</th>
                                                                <th>Type</th>
                                                                <th>Expire On</th>
                                                                <th>limit</th>
                                                                <th>max Off</th>
                                                                <th>user limit</th>
                                                                <th>coupon limit</th>
                                                                <th>total used</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            loader?.coupon ?
                                                                <tr>
                                                                    <td colSpan={11}>
                                                                        <div className='w-100 aspect-4-1 aspect-md-2-1'>
                                                                            <Loader />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                :
                                                                (couponListData?.length > 0) &&
                                                                couponListData?.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td className='fs-14 fs-sm-10'>{item?.name}</td>
                                                                            <td className='fs-14 fs-sm-10'>{item?.value}</td>
                                                                            <td className='fs-14 fs-sm-10'>{item?.type}</td>
                                                                            <td className='fs-14 fs-sm-10'>{moment(item?.expireDate)?.format('DD, MMM YYYY')}</td>
                                                                            <td className='fs-14 fs-sm-10'>{item?.limitCondition}</td>
                                                                            <td className='fs-14 fs-sm-10'>{item?.maxOffAmount}</td>
                                                                            <td className='fs-14 fs-sm-10'>{item?.userLimit}</td>
                                                                            <td className='fs-14 fs-sm-10'>{item?.couponLimit}</td>
                                                                            <td className='fs-14 fs-sm-10'>{item?.totalUsed}</td>
                                                                            <td className='text-center'>
                                                                                <label className="switch">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={(item.status === 1) ? true : false}
                                                                                        value={item.status}
                                                                                        onChange={() => handleCouponStatus(item)}
                                                                                    />
                                                                                    <span className="slider"></span>
                                                                                </label>
                                                                            </td>
                                                                            <td>
                                                                                <div className='flex-between-align custom-btns'>
                                                                                    <i className="fs-14 custom-btns-edit bi bi-pen-fill"
                                                                                        onClick={() => handleUpdateCoupon(item)} />
                                                                                    <i className="fs-14 custom-btns-delete bi bi-trash-fill" onClick={() => deleteCoupon(item)} />
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                        }
                                                        </tbody>
                                                    </table>
                                            }

                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Tab>
                        {/*
                        <Tab eventKey="pages" title="Pages Detail" className='p-2 p-sm-3'>
                            <div className='d-grid gap-3'>
                                <div className='d-grid gap-2 px-2'>
                                    <h4 className='fw-semibold m-0'>
                                        Store Informative Page
                                    </h4>
                                    <p className='fs-sm-12 fs-lg-14 m-0 p-color'>Manage your store Terms and Conditions and Privacy Policy</p>
                                </div>
                                <Container className='px-sm-0 py-sm-4'>
                                    <Row className='gy-4'>
                                        <Col className='col-12 d-grid py-3 custom-border-box'>
                                            <FormComponent
                                                action={frontEnd_API.page}
                                                valueData={(updatePage) && updatePage}
                                                actionType={`${(updatePage) ? 'put' : 'post'}`}
                                                actionValue={(updatePage) && updatePageId}
                                                submitFullWidth={false}
                                                onSubmit={(e) => callPageList(e)}
                                                formMessage={(updatePage) ? "Page Updated in store !" : "Page Added to store !"}
                                                fields={[
                                                    {
                                                        type: 'select',
                                                        options: [
                                                            {
                                                                value: 'privacy policy',
                                                                label: 'privacy policy'
                                                            },
                                                            {
                                                                value: 'terms and condition',
                                                                label: 'terms and condition'
                                                            },
                                                            {
                                                                value: 'return policy',
                                                                label: 'return policy'
                                                            },
                                                        ],
                                                        divClass: 'col-12',
                                                        name: 'name',
                                                        key: 'name',
                                                        required: true,
                                                    },
                                                    {
                                                        type: 'editor',
                                                        divClass: 'col-12',
                                                        name: 'description',
                                                        key: 'description',
                                                        required: true,
                                                    },
                                                ]}
                                            />
                                        </Col>
                                        <Col className='col-12 d-grid px-0 px-sm-2 align-content-start'>
                                            {(pageListData.length === 0) ?
                                                <h4 className='text-center p-color pt-4 pt-sm-5'>No Page Added Yet!</h4>
                                                :
                                                <table className='cs-table'>
                                                    <thead>
                                                        <tr className='fs-sm-12'>
                                                            <th className='text-center'>Name</th>
                                                            <th className='text-center'>Status</th>
                                                            <th className='text-center'>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            loader?.page ?
                                                                <tr>
                                                                    <td colSpan={11}>
                                                                        <div className='w-100 aspect-4-1 aspect-md-2-1'>
                                                                            <Loader />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                :
                                                                (pageListData?.length > 0) &&
                                                                pageListData?.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td className='fs-14 fs-sm-10'>{item?.name}</td>
                                                                            <td className='text-center'>
                                                                                <label className="switch">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={(item.status === 1) ? true : false}
                                                                                        value={item.status}
                                                                                        onChange={() => handlePageStatus(item)}
                                                                                    />
                                                                                    <span className="slider"></span>
                                                                                </label>
                                                                            </td>
                                                                            <td>
                                                                                <div className='flex-between-align custom-btns'>
                                                                                    <i className="fs-14 custom-btns-edit bi bi-pen-fill"
                                                                                        onClick={() => handleUpdatePage(item)} />
                                                                                    <i className="fs-14 custom-btns-delete bi bi-trash-fill" onClick={() => deletePage(item)} />
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                        }
                                                    </tbody>
                                                </table>
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Tab>
                        */}
                        <Tab eventKey="banner" title="Store Banner" className='p-2 p-sm-3'>
                            <div className='d-grid gap-3'>
                                {/* Top Content */}
                                <div className='d-grid gap-2 px-2'>
                                    <h4 className='fw-semibold m-0'>
                                        Manage Store Banner
                                    </h4>
                                    <p className='fs-sm-12 fs-lg-14 m-0 p-color'>Add a stunning banner to your homepage to captivate visitors and showcase your brand instantly.</p>
                                </div>
                                <Container className='px-sm-0 py-sm-4'>
                                    <Row className='gy-4'>
                                        <Col className='col-12 col-md-5 d-grid py-3 custom-border-box'>
                                            <FormComponent
                                                action={frontEnd_API.banner}
                                                valueData={(updateBanner) ? updateBanner : { relatedName: "category" }}
                                                actionType={`${(updateBanner) ? 'put' : 'post'}`}
                                                actionValue={(updateBanner) && updateBannerId}
                                                submitFullWidth={false}
                                                onSubmit={(e) => callBannerList(e)}
                                                formMessage={(updateBanner) ? "Banner Updated in store !" : "Banner Added to store !"}
                                                fields={[
                                                    {
                                                        type: 'select',
                                                        options: storeCategoryData,
                                                        divClass: 'col-12',
                                                        label: 'related',
                                                        name: 'relatedId',
                                                        key: 'category',
                                                        showData: '',
                                                    },
                                                    {
                                                        divClass: 'col-12',
                                                        type: 'file',
                                                        name: 'image',
                                                        key: 'thumbnail',
                                                        required: parseInt(updateBannerId) <= 0,
                                                    },
                                                    {
                                                        divClass: 'col-12',
                                                        name: 'banner name',
                                                        key: 'name',
                                                        required: true,
                                                        placeholder: "Enter banner name",
                                                        pattern:pattern?.textNumWithSpace
                                                    },
                                                ]}
                                            />
                                        </Col>
                                        <Col className='col-12 col-md-7 d-grid px-0 px-sm-2 align-content-start'>
                                            {
                                                (bannerList.length === 0) ?
                                                    <h4 className='text-center p-color'>No Banner Added</h4>
                                                    : <table className='cs-table'>
                                                        <thead>
                                                            <tr className='fs-sm-12'>
                                                                <th className='text-center'>Name</th>
                                                                <th className='text-center'>Image</th>
                                                                <th className='text-center'>Status</th>
                                                                <th className='text-center'>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                loader?.banner ?
                                                                    <tr>
                                                                        <td colSpan={4}>
                                                                            <div className='w-100 aspect-3-1 aspect-md-2-1'>
                                                                                <Loader />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    :
                                                                    (bannerList?.length > 0) &&
                                                                    bannerList?.map((item, index) => {
                                                                        return (

                                                                            <tr key={index}>
                                                                                <td className='text-center text-capitalize'>
                                                                                    {item?.name}
                                                                                </td>
                                                                                <td className='text-center flex-center-align'>
                                                                                    <div className='d-grid align-content-center' style={{
                                                                                        height: "100px",
                                                                                        width: "150px",
                                                                                        overflow: "hidden",
                                                                                        borderRadius: ".5rem"
                                                                                    }}>
                                                                                        <img src={item?.thumbnail}
                                                                                            className='w-100'
                                                                                            style={{
                                                                                                height: "auto",
                                                                                                objectFit: "contain"
                                                                                            }}
                                                                                            alt="" />
                                                                                    </div>
                                                                                </td>
                                                                                <td className='text-center'>
                                                                                    <label className="switch">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={(item.status === 1) ? true : false}
                                                                                            value={item.status}
                                                                                            onChange={() => handleBannerStatus(item)}
                                                                                        />
                                                                                        <span className="slider"></span>
                                                                                    </label>
                                                                                </td>
                                                                                <td>
                                                                                    <div className='flex-between-align custom-btns'>
                                                                                        <i className="fs-14 custom-btns-edit bi bi-pen-fill"
                                                                                            onClick={() => handleUpdateBanner(item)} />
                                                                                        <i className="fs-14 custom-btns-delete bi bi-trash-fill" onClick={() => deleteBanner(item)} />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                            }
                                                        </tbody>
                                                    </table>
                                            }

                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Tab>
                        <Tab eventKey="returnDetails" title="Return Details" className='p-2 p-sm-3'>
                            <div className='d-grid gap-2'>
                                <div className='d-grid gap-2 px-2'>
                                    <div className='d-flex justify-content-between'>
                                        <h4 className='fw-semibold m-0'>
                                            Return Product Handling
                                        </h4>
                                        <div className='seller-return-setting'>
                                            <label className="switch">
                                                <input type="checkbox" />
                                                <span className="slider"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <p className='fs-14 fs-sm-12 fs-lg-14 m-0 p-color'>
                                        As a seller, when a customer initiates a return request, you have the responsibility to manage the return process. You can choose to accept the product at your designated <span
                                            className='fw-semibold color-temp pointer'
                                            onClick={() => setTabHandler("updateDetails")}
                                            data-tooltip-id="my-shop"
                                            data-tooltip-content={`Your Shop Address :- ${activeStore?.addressFirstLine}, ${activeStore?.addressSecondLine}, ${activeStore?.city}, ${activeStore?.state}, ${activeStore?.country} - ${activeStore?.pinCode}`}

                                        >shop address</span> or redirect the return to the original provider. Please review the options carefully and decide the most convenient and efficient way to handle the return.
                                    </p>
                                    <Tooltip id="my-shop" place="right" />
                                </div>
                                <Container className='py-4'>
                                    <Row className='gy-4 position-relative'>
                                        <Col className='col-12'>
                                            <Card>
                                                <Card.Body>
                                                    <Card.Text className='d-flex gap-3 flex-wrap align-items-center check-holder'>
                                                        <input
                                                            type="checkbox"
                                                            name='isReturnToStore'
                                                            id='isReturnToStore'
                                                            checked={returnToStore == 1 ? true : false}
                                                            className={`ui-checkbox`}
                                                            onChange={(e) => {
                                                                // setStoreData((prevData) => ({
                                                                //     ...prevData,
                                                                //     isCodAvailable: e.target.checked ? 1 : 0
                                                                // }))
                                                                setReturnToStore(
                                                                    e.target.checked ? 1 : 0
                                                                )
                                                            }}
                                                            autoComplete='off' />
                                                        <label htmlFor="isReturnToStore">
                                                            Are You Sure to accept return to your shop address?
                                                        </label>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <div className='d-flex justify-content-center pt-3 pt-md-4'>
                                        <Link
                                            className={`next-button text-decoration-none fw-bold d-grid`}
                                            onClick={submitReturnDetails}>
                                            <button type="button" className={`button active-btn`}>
                                                <span className="button__text">Save</span>
                                                <span className="button__icon">
                                                    <MdKeyboardDoubleArrowRight className="svg" />
                                                </span>
                                            </button>
                                        </Link>
                                    </div>
                                </Container>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </Container >
            <ToastContainer />
        </div >
    )
}

export default SellerStoreSetting
