import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import { Label } from 'react-konva';
import Editor from 'react-simple-wysiwyg';
import axios from 'axios';
import { frontEnd_API, header, SERVER_URL, storage } from '../../../Config/Config';
import { toast, ToastContainer } from 'react-toastify';
import ProductPreviewImage from './ProductPreviewImageComponent';
import { generateImageFromData } from './GeneratePreviewImage';
import Redirection from '../../../Components/Redirection/Redirection';
import Loader from '../../../Components/Loader';
import Swal from 'sweetalert2';
import { Input } from 'reactstrap';

function ProductCreate() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const [platformObj, setPlatformObj] = useState({})
    const [brandingStatus, setBrandingStatus] = useState(false)
    const [product, setProduct] = useState(location?.state?.data)
    const [priceCountBoxStatus, setPriceCountBoxStatus] = useState(false)
    const [selectedVariantData, setSelectedVariantData] = useState([])
    const [colorVariantData, setColorVariantData] = useState([])
    const [currentMockupObj, setCurrentMockupObj] = useState({})
    const [postVariant, setPostVariant] = useState()
    const [primaryPreview, setPrimaryPreview] = useState(0)
    const params = useParams();

    // state section ------------
    const [productFormSubmitStatus, setProductFormSubmitStatus] = useState(false)
    const [productForm, setProductForm] = useState({})
    // ------------

    useEffect(() => {
        setProduct((prevData) => ({
            ...prevData
        }))
    }, [])

    useEffect(() => {
        console.log("product from canvas::", location);
    }, [location]);

    console.log("product?.product :: ', ", product)

    // function for generate/create product cartesian ------------
    const generateVariantCartesian = (arr = []) => {
        if (arr?.length > 0) {
            const cartesian = arr.reduce((acc, array) => {
                return acc.flatMap(a => array.map(b => [...a, b]));
            }, [[]])?.map(e => ({ variant: e, stock: 50, isAvailable: true }));
            return cartesian
        } else {
            return []
        }
    }
    // ------------

    // function for manage product form data ------------
    const manageProductFormData = async () => {
        const productData = { ...product.product, ...product.provider }
        console.log("productData 66 :: ", productData)
        productData.providerPrice = Number(productData?.productPrice)
        productData.designData = product?.canvas?.map(e => ({ ...e, isDesigned: e?.data?.objects?.length > 0 }))
        productData.providerDesignPrice = productData?.designData?.filter(e => e.isDesigned)?.reduce((sum, x) => sum + x?.printingPrice, 0)
        productData.providerPayble = productData?.productPrice + productData?.providerDesignPrice
        
        // productData.platformChargePercentage = 12
        // productData.platformChargeAmount = Math.round(Number(productData?.providerPayble) * productData.platformChargePercentage / 100)

        productData.platformChargePercentage = 0
        // productData.platformChargeAmount = parseInt(productData?.sellerCommissionAmount)
        productData.platformChargeAmount = parseInt(platformObj?.sellerPlatformChargeInAmount?? 0)
        
        productData.shippingCharge = 50
        productData.rtoCharge = 60
        
        productData.taxPercentage = 0
        productData.taxAmount = 0

        if (parseInt(productData?.gstTaxPercentage) > 0) {
            productData.taxPercentage = parseInt(productData?.gstTaxPercentage?? 0)
            productData.taxAmount = (productData.platformChargeAmount + productData?.providerPayble) * productData?.taxPercentage / 100
        }


        productData.payblePrice = productData.platformChargeAmount + productData?.providerPayble + productData.taxAmount
        productData.profitInPercentage = (location?.state?.data?.updateProduct !== null)
            ? parseInt(location?.state?.data?.updateProduct?.profitInPercentage)
            : 50
        productData.profitInAmount = Math.round(Number(productData?.payblePrice) * productData.profitInPercentage / 100)
        productData.price = productData.payblePrice + productData.profitInAmount
        let cartesianData = []
        if (product?.variation) {
            cartesianData = generateVariantCartesian(Object.values(product?.variation))
        }

        productData.cartesianData = cartesianData?.map(e => {
            const variantPrice = e?.variant?.reduce((sum, a) => sum + a?.providerPrice, 0)
            const providerPrice = Number(parseInt(variantPrice) + productData.providerPrice + productData.providerDesignPrice);
            // const payblePrice = Math.round(providerPrice + (providerPrice * productData.platformChargePercentage / 100) + productData.shippingCharge);
            const payblePrice = Math.round(providerPrice + productData.platformChargeAmount);
            const profitAmount = Math.round(payblePrice * productData.profitInPercentage / 100)
            const price = payblePrice + Math.round(Number(payblePrice) * productData.profitInPercentage / 100)
            const mrp = price
            return {
                ...e,
                providerPrice,
                payblePrice,
                profitPercentage: productData?.profitInPercentage,
                profitAmount,
                price,
                mrp,
                isPrimary: variantPrice <= 0
            }
        })

        if (location?.state?.data?.updateProduct !== null) {
            const updateProduct = location?.state?.data?.updateProduct;

            productData.name = updateProduct?.name;
            productData.shortDescription = updateProduct?.description;
        }

        if (product?.variation)
            setSelectedVariantData([...Object?.keys(product?.variation)])


        // variant data
        const data = product?.provider?.availableVariantData?.filter(e => Object?.keys(product.variation).includes(e?.name))?.map((x) => {
            return {
                ...x,
                data: product?.variation[x?.name]?.map((e) => ({ ...e, isPrimary: e?.providerPrice <= 0 }))
            }
        })
        productData.variantData = data

        setPostVariant(data);

        const previewObject = productData?.previewImagesData?.length > 0 ? productData?.previewImagesData[0] : '';
        const colorData = productData?.variantData?.find(e => ['color', 'colors', 'colour', 'colours']?.includes(e?.name?.toLowerCase()))?.data?.map(x => ({
            label: x?.label,
            code: x?.code,
            ...previewObject
        }))
        if (colorData?.length > 0) {
            setColorVariantData(colorData)
        }
        productData.defaultColor = colorData?.length > 0 ? colorData[0]?.code : ''

        setProductForm(
            {
                platformProviderCommissionPercentage:productData?.platformCommissionPercentage,
                masterProductId: productData?.id,
                providerId: productData?.providerId,
                providerBusinessId: productData?.providerBusinessId,
                providerProductId: productData?.providerProductId?? 0,
                defaultColor: productData?.defaultColor,
                productName: productData?.name,
                description: productData?.shortDescription,
                providerPrice: productData?.productPrice,
                providerDesignPrice: productData?.providerDesignPrice,
                providerPayble: productData?.providerPayble,
                platformChargePercentage: productData.platformChargePercentage,
                platformChargeAmount: productData.platformChargeAmount,
                shippingCharge: productData.shippingCharge,
                rtoCharge: productData.rtoCharge,
                taxPercentage: productData.taxPercentage,
                taxAmount: productData.taxAmount,
                payblePrice: productData.payblePrice,
                profitInPercentage: productData.profitInPercentage,
                previousProfitInPercentage: productData.profitInPercentage,
                profitInAmount: productData.profitInAmount,
                previousProfitInAmount: productData.profitInAmount,
                price: productData.price,
                mrp: productData.price,
                height: productData.height,
                width: productData.width,
                length: productData.length,
                weight: productData.weight,
                previousMrp: productData.price,
                profitPercentage: 20,
                variantProfitPercentage: 20,
                designData: productData?.designData ?? [],
                cartesianData: productData.cartesianData ?? [],
                variantData: productData?.variantData ?? [],
                previewImagesData: productData?.previewImagesData ?? []
            }
        )

        const tempIndex = location

        console.log("colorData :: ", colorData)
        manageMockupDetail(colorData?.length > 0 ? colorData[0] : {})
    }
    console.log('productForm :: ', productForm)
    // ------------

    // function for manage product price ------------
    const manageProductPrice = async (obj = { from: 'amt', val: 0 }) => {
        if (['amt','per'].includes(obj?.from)) {
            productForm.profitInPercentage = productForm.profitInAmount = 0
            productForm.price = productForm.payblePrice
            if (obj.from === 'amt') {
                productForm.profitInPercentage = Math.round(Number(obj.val * 100 / productForm.payblePrice))
                productForm.profitInAmount = obj.val
                productForm.previousProfitInPercentage = productForm.profitInPercentage
            }
            if (obj.from === 'per') {
                productForm.profitInPercentage = obj.val
                productForm.profitInAmount = Math.round(Number(productForm?.payblePrice) * obj.val / 100)
                productForm.previousProfitInAmount = productForm.profitInAmount
            }
            productForm.price = productForm.payblePrice + productForm.profitInAmount
            productForm.mrp = productForm.price
            console.log('sssss :: ', obj?.form)
        }
        if (obj.from == 'mrp')
            productForm.mrp = obj.val

        console.log('sssss 198 :: ', obj?.from, obj)

        setProductForm({ ...productForm })
    }
    // ------------

    // function for save product detail ------------
    const productFormSubmit = async () => {

        const data = product?.product?.previewImagesData?.map((row, index) => {
            const obj = productForm?.designData?.filter(e => e?.value === row?.value)?.map(e => ({
                ratioData: e?.ratioData,
                imgURL: e?.imgURL ?? null
            }));

            return {
                seq: index + 1,
                preview: row?.preview,
                ...(obj?.length > 0 ? obj[0] : {})
            }
        });

        // const formDataList = await Promise.all(data.map((row, index) => generateImageFromData(row, index)));

        // // Combine all FormData objects into one
        // const combinedFormData = new FormData();
        // formDataList.forEach(formData => {
        //     formData.forEach((value, key) => {
        //         combinedFormData.append(key, value);
        //     });
        // });
        // console.log('combinedFormData :: ', combinedFormData)
        // return

        const imagePromises = data.map((item, index) => generateImageFromData(item, index));

        // Wait for all promises to resolve and get the base64 images
        const base64Images = await Promise.all(imagePromises);

        productForm.previewImagesData = base64Images
            ? base64Images.map((link, index) => ({
                imgURL: link,
                isPrimary: (index == primaryPreview) ? 1 : 0
            }))
            : []
        console.log("productForm.previewImagesData :: ", productForm?.previewImagesData)
        // return
        try {
            
            delete productForm?.previousProfitInPercentage
            delete productForm?.previousProfitInAmount
            delete productForm?.previousMrp
            delete productForm?.desginPlatformCharge

            productForm.isBranding = brandingStatus ? 1 : 0

            console.log('productForm  :: ', productForm)
            // return
            const methodType = (location?.state?.data?.updateProduct == null) ? 'post' : 'put'
            const updateId = location?.state?.data?.updateProduct?.productId
            const response = await axios[methodType](`${frontEnd_API?.storeProduct}${(updateId) ? `/${updateId}` : ''}`, productForm, header);
            
            if (response?.status == 200) {
                setProductFormSubmitStatus(false)
                toast(response?.data?.message)
                navigate('/products')
            }
        } catch (error) {
            setProductFormSubmitStatus(false)
            toast('something is wrong')
        }
    }
    // ------------

    // function for update all variant price ------------
    const updateVariantPrice = (obj = {}) => {

        const data = productForm?.cartesianData?.map((row) => {

            if (['per', 'amt'].includes(obj.from)) {
                if (obj.from == 'per') {
                    row.profitPercentage = productForm.profitInPercentage
                    row.profitAmount = Math.round(Number(row?.payblePrice) * row.profitPercentage / 100)
                }
    
                if (obj.from == 'amt') {
                    row.profitAmount = productForm.profitInAmount
                    row.profitPercentage = Math.round(Number(row.profitAmount * 100 / row.payblePrice))
                }
    
                row.price = row.payblePrice + row.profitAmount
                row.mrp = row.price
            }
            if (obj.from == 'mrp')
                row.mrp = productForm.mrp

            return row
        })

        productForm.cartesianData = data
        setProductForm({ ...productForm })
    }
    // ------------

    // function for manage variant price ------------
    const manageVariantPrice = (obj = { from: 'amt' }) => {

        if (obj.from) {
            const row = productForm?.cartesianData[obj?.index]

            if (['amt', 'per'].includes(obj.from)) {
                if (obj.from == 'per') {
                    row.profitPercentage = obj.value
                    row.profitAmount = Math.round(Number(row?.payblePrice) * row.profitPercentage / 100)
                }
    
                if (obj.from == 'amt') {
                    row.profitAmount = obj.value
                    row.profitPercentage = Math.round(Number(row.profitAmount * 100 / row.payblePrice))
                }
                row.price = row.payblePrice + row.profitAmount
                row.mrp = row.price
            }
            if (obj.from == 'mrp')
                row.mrp = obj.value

            productForm.cartesianData[obj.index] = row

            setProductForm({ ...productForm })
        }
    }
    // ------------

    // function for manage product preview and mockup data ------------
    const manageMockupDetail = (obj = {}) => {

        const row = obj?.isPreview ? { ...currentMockupObj, ...obj } : obj;

        if (row?.preview) {
            const designData = productForm?.designData?.find(e => e?.value == obj?.value)?.data
            row.designData = designData ?? []
            setCurrentMockupObj({ ...row })
        }
    }
    // ------------

    const getPlatformConfigDetail = async () => {
        const { data, status } = await axios?.get(`${frontEnd_API?.home}/platformconfig`, header)
        setPlatformObj({...data?.data})
    }
    console.log("platformObj 363 :: ", platformObj)

    useEffect(() => {
        getPlatformConfigDetail()
        manageProductFormData()
    }, [product])

    return (
        <div className='custom-product-create position-relative'>

            {/* Loader For Product Create */}
            {
                productFormSubmitStatus &&
                <div className='position-absolute w-100 h-100'>
                    <div
                        className='position-sticky top-0 start-0 w-100 flex-center-align'
                        style={{
                            backgroundColor: 'rgba(255,255,255,1)',
                            zIndex: "9999",
                            height: "100vh"
                        }}
                    >
                        <div className='d-grid text-center text-success gap-1'>
                            <div className='w-100 aspect-2-1 aspect-md-2-1'>
                                <Loader />
                            </div>
                        </div>
                    </div>
                </div>
            }

            <Container className='py-4'>
                {
                    (location?.state?.data?.updateProduct == null) &&
                    <div className='flex-between-align custom-header mb-3'>
                        <h4 className='fw-semibold'>{product?.product?.name}</h4>
                        <Redirection
                            redirectValue={{
                                url: `/product/${params?.productId}/${params?.providerId}/canvas`,
                                data: { 'editCanvas': location?.state?.data?.canvas, 'canvasWidth': location?.state?.data?.canvasWidth, 'varientData': postVariant }
                            }}
                        >
                            <Link className='flex-center-align gap-2'>
                                <i className="bi bi-pencil-fill fs-14"></i>
                                Edit Design
                            </Link>
                        </Redirection>
                    </div>
                }
                <Row>
                    <Col xl='12'>
                        <Card className='rounded-0 mb-4'>
                            <CardHeader className='bg-transparent py-3'>
                                <h4 className='mb-0 fw-bolder'>Product Preview & Mockup</h4>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xl='5' md='6' xs='12'>
                                        <div
                                            className='preview-image border border-2 border-dashed'
                                        >
                                            {
                                                [currentMockupObj]?.map((row) => {
                                                    const imgData = productForm?.designData?.find(e => e?.value == row?.value);
                                                    return (
                                                        <ProductPreviewImage
                                                            colorCode={row?.code ?? '#ffffff'}
                                                            objectData={{
                                                                ratioData: imgData?.ratioData ?? {},
                                                                preview: row?.preview || null,
                                                                imgURL: imgData?.imgURL || null,
                                                            }}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    </Col>
                                    <Col xl='7' md='6' xs='12'>
                                        <Row className='gy-2'>
                                            <Col xl='12'>
                                                <div className='fs-20 fs-md-18 fs-sm-16 fw-semibold mb-2 mt-2 mt-md-0'>
                                                    <span>Color Images</span>
                                                </div>
                                                <div className='d-flex flex-wrap gap-2 gap-sm-3 mb-3'>
                                                {
                                                    colorVariantData?.map((row, index) => <>
                                                        <div
                                                            key={index}
                                                            className={`pointer`}
                                                            onClick={() => {
                                                                productForm.defaultColor = row?.code?? ''
                                                                setPrimaryPreview(0)
                                                                setProductForm({...productForm})
                                                                manageMockupDetail(row)
                                                            }}
                                                        >
                                                            <div className=' sm-preview-img position-relative'>
                                                                <img
                                                                    // src={SERVER_URL + row?.preview}
                                                                    src={row?.preview}
                                                                    style={{ objectFit: "cover", backgroundColor: `${row?.code ?? '#ffffff'}` }}
                                                                    alt={productForm?.productName + ' ' + row?.label}
                                                                    className={`w-100 h-100 rounded-1 border ${row?.code == currentMockupObj?.code ? 'border-2 border-dark' : ''}`}
                                                                />
                                                                { productForm?.defaultColor == row?.code && (
                                                                    <span className='position-absolute top-0 end-0 fs-10 badge bg-success border-0 rounded-0'>Default</span>
                                                                )}
                                                            </div>
                                                            <div
                                                                className='bg-transparent fs-12 fs-sm-10 fw-semibold text-center h-25 pt-1'
                                                                style={{ color: '#808c98' }}
                                                            >
                                                                {row?.label}
                                                            </div>
                                                        </div>
                                                    </>)
                                                }
                                                </div>
                                            </Col>
                                            <Col xl='12'>
                                                <div className='fs-20 fs-md-18 fs-sm-16 fw-semibold mb-2 mt-2 mt-md-0'>
                                                    <span>Mockup Images</span>
                                                </div>
                                                <div className='d-flex flex-wrap gap-2 gap-sm-3 mb-3'>
                                                    {
                                                        productForm?.previewImagesData?.map((row, index) => {
                                                            const imgData = productForm?.designData?.find(e => e?.value == row?.value);
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className={`position-relative sm-preview-img pointer rounded-1 border mt-2 ${row?.preview == currentMockupObj?.preview ? 'border-2 border-dark' : ''}`}
                                                                >
                                                                    <ProductPreviewImage
                                                                        isList={true}
                                                                        colorCode={currentMockupObj?.code ?? '#ffffff'}
                                                                        objectData={{
                                                                            ratioData: imgData?.ratioData ?? {},
                                                                            preview: row?.preview || null,
                                                                            imgURL: imgData?.imgURL || null,
                                                                            value: imgData?.value,
                                                                            index,
                                                                            label: imgData?.label
                                                                        }}
                                                                        // selectionData={index}
                                                                        onPrevSelect={(e) => {
                                                                            manageMockupDetail({ ...e, code: currentMockupObj?.code ?? '#ffffff', isPreview: true })
                                                                            setPrimaryPreview(e?.index)
                                                                        }}
                                                                    />
                                                                    { primaryPreview == index && (
                                                                    <span className='position-absolute top-0 end-0 fs-10 badge bg-success border-0 rounded-0'>Default</span>
                                                                )}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='rounded-0 mb-4'>
                            <CardHeader className='bg-transparent py-3'>
                                <h4 className='mb-0 fw-bolder'>Product Detail</h4>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xl='12'>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>title</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                placeholder='product title'
                                                value={productForm?.productName}
                                                required
                                                onChange={(e) => {
                                                    productForm.productName = e?.target?.value
                                                    setProductForm({ ...productForm })
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col xl='12'>
                                        <div className='form-group'>
                                            <Label className='form-label'>description</Label>
                                            <Editor
                                                value={productForm?.description}
                                                onChange={(e) => {
                                                    productForm.description = e?.target?.value
                                                    setProductForm({ ...productForm })
                                                }}
                                                style={{ height: '250px' }}
                                            />
                                        </div>
                                    </Col>
                                    {
                                        product?.provider?.brandingStatus > 0 && (
                                            <Col xl='12'>
                                                <div className='d-flex gap-1 flex-column my-3'>
                                                    <span className='fw-semibold'>Do you want to enable custom branding services for this product?</span>
                                                    <div>
                                                        <Input
                                                            id='branding'
                                                            type='checkbox'
                                                            checked={brandingStatus}
                                                            onChange={(e) => setBrandingStatus(!brandingStatus)}
                                                        />&nbsp;
                                                        <Label for="branding" className="mb-0 pointer">Yes</Label>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='rounded-0 mb-4'>
                            <CardHeader className='bg-transparent py-3'>
                                <h4 className='mb-0 fw-bolder'>Price & Variant</h4>
                            </CardHeader>
                            <CardBody>
                                
                                {/*
                                <Row>
                                    <Col xl='3' md='6' xs='12'>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>product price</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.providerPrice}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col xl='3' md='6' xs='12'>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>Design charges</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.providerDesignPrice}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col xl='3' md='6' xs='12'>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>provider Payble price</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.providerPayble}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col xl='3' md='6' xs='12'>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>platform charge</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.platformChargeAmount}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl='4' md='4' xs='12'>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>shipping charges</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.shippingCharge}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col xl='4' md='4' xs='12'>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>rto charges(ord return time)</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.rtoCharge}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col xl='4' md='4' xs='12'>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>payble amount</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.payblePrice}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                */}

                                <Row xl={5} md={3} xs={1}>
                                    <Col>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label d-flex justify-content-between align-items-center'>
                                                <span>Payable amount</span>
                                                <div className='position-relative'>
                                                    <span onClick={() => setPriceCountBoxStatus(!priceCountBoxStatus)} className="bi bi-info-circle-fill mr-1"></span>
                                                    {
                                                        (priceCountBoxStatus) && (
                                                            <div className='position-absolute border rounded-2 p-2 bg-white bottom-100 end-50 fs-12' style={{width:'250px!important;'}}>
                                                                <div className='d-flex gap-3 justify-content-between ml-2'>
                                                                    <span className='text-nowrap'>Provider price</span>
                                                                    <span className='fw-bold'>{productForm?.providerPrice}</span>
                                                                </div>
                                                                <div className='d-flex gap-3 justify-content-between ml-2'>
                                                                    <span className='text-nowrap'>Design charges</span>
                                                                    <span className='fw-bold'>{productForm?.providerDesignPrice}</span>
                                                                </div>
                                                                {
                                                                    productForm?.platformChargeAmount > 0 && (
                                                                        <div className='d-flex gap-3 justify-content-between ml-2'>
                                                                            <span className='text-nowrap'>Platform fee</span>
                                                                            <span className='fw-bold'>{productForm?.platformChargeAmount}</span>
                                                                        </div>
                                                                    )
                                                                }
                                                                {
                                                                    productForm?.taxPercentage > 0 && (
                                                                        <div className='d-flex gap-3 justify-content-between ml-2'>
                                                                            <span className='text-nowrap'>Tax ({productForm?.taxPercentage}%)</span>
                                                                            <span className='fw-bold'>{productForm?.taxAmount}</span>
                                                                        </div>
                                                                    )
                                                                }
                                                                <div className='d-flex gap-3 justify-content-between ml-2'>
                                                                    <span className='text-nowrap'>Shipping as per actual</span>
                                                                    {/* <span className='fw-bold'>{productForm?.shippingCharge}</span> */}
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.payblePrice}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>Profit (%)</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.profitInPercentage}
                                                onChange={(e) => manageProductPrice({ from: 'per', val: Number(e?.target?.value ?? 0) })}
                                                onBlur={(e) => {
                                                    if (productForm.profitInPercentage != productForm.previousProfitInPercentage) {
                                                        Swal.fire({
                                                            title: `Do you want to apply this change to all the variant??`,
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#12715B',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'Confirm',
                                                            cancelButtonText: 'No',
                                                        }).then(async (result) => {
                                                            if (result.isConfirmed) {
                                                                productForm.previousProfitInPercentage = productForm.profitInPercentage
                                                                setProductForm({ ...productForm })
                                                                updateVariantPrice({ from: 'per' })
                                                            } else {
                                                                manageProductPrice({ from: 'per', val: Number(productForm?.previousProfitInPercentage?? 0) })
                                                            }
                                                        });
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>Profit (&#8377;)</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.profitInAmount}
                                                onChange={(e) => manageProductPrice({ from: 'amt', val: Number(e?.target?.value ?? 0) })}
                                                onBlur={(e) => {
                                                    if (productForm.profitInAmount != productForm.previousProfitInAmount) {
                                                        Swal.fire({
                                                            title: `Do you want to apply this change to all variant??`,
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#12715B',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'Confirm',
                                                            cancelButtonText: 'No',
                                                        }).then(async (result) => {
                                                            if (result.isConfirmed) {
                                                                productForm.previousProfitInAmount = productForm.profitInAmount
                                                                setProductForm({ ...productForm })
                                                                updateVariantPrice({ from: 'amt' })
                                                            } else {
                                                                manageProductPrice({ from: 'amt', val: Number(productForm?.previousProfitInAmount?? 0) })
                                                            }
                                                        });
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>Selling Price</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.price}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>Set MRP</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.mrp}
                                                onChange={(e) => manageProductPrice({ from: 'mrp', val: Number(e?.target?.value ?? 0) })}
                                                onBlur={(e) => {
                                                    console.log('productForm.mrp != productForm.previousMrp :: ', productForm.mrp != productForm.previousMrp)
                                                    if (productForm.mrp != productForm.previousMrp) {

                                                        Swal.fire({
                                                            title: `Do you want to apply this MRP to all variant??`,
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#12715B',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'Confirm',
                                                            cancelButtonText: 'No',
                                                        }).then(async (result) => {
                                                            if (result.isConfirmed) {
                                                                productForm.previousMrp = productForm.mrp
                                                                setProductForm({ ...productForm })
                                                                updateVariantPrice({ from: 'mrp' })
                                                            } else {
                                                                manageProductPrice({ from: 'mrp', val: Number(productForm?.previousMrp?? 0) })
                                                            }
                                                        });
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    {
                                        productForm?.cartesianData && (
                                            <Col xl='12' className='cartesian'>
                                                <hr />
                                                <div className='table-responsive mb-4'>
                                                    <Table className='table table-centered table-hover align-middle table-nowrap mb-0'>
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={Object?.keys(productForm?.cartesianData)?.length + 1}>
                                                                    <h5 className='mb-0'>Product Variant Cartesian</h5>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                {selectedVariantData?.map((row) => <th className='fs-sm-14'>{row}</th>)}
                                                                <th className='fs-sm-14'>production cost</th>
                                                                <th className='fs-sm-14'>profit (%)</th>
                                                                <th className='fs-sm-14'>profit (&#8377;)</th>
                                                                <th className='fs-sm-14'>sale price</th>
                                                                <th className='fs-sm-14'>MRP</th>
                                                                {/* <th>Stock</th> */}
                                                                <th className='fs-sm-14'>Available</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                productForm?.cartesianData?.map((row, index) => <>
                                                                    <tr className={row?.isPrimary ? 'disabled' : ''}>
                                                                        {row?.variant?.map((val) => <td className='fs-sm-14'>{val?.label}</td>)}
                                                                        <th>
                                                                            <input
                                                                                className='form-control rounded-0 fs-sm-14'
                                                                                value={row?.payblePrice}
                                                                                style={{ width: '100px' }}
                                                                                disabled
                                                                            />
                                                                        </th>
                                                                        {
                                                                            row?.isPrimary ? (
                                                                                <>
                                                                                    <th>
                                                                                        <input
                                                                                            className='form-control rounded-0 fs-sm-14'
                                                                                            value={row?.profitPercentage}
                                                                                            style={{ width: '100px' }}
                                                                                            disabled
                                                                                        />
                                                                                    </th>
                                                                                    <th>
                                                                                        <input
                                                                                            className='form-control rounded-0 fs-sm-14'
                                                                                            value={row?.profitAmount}
                                                                                            style={{ width: '100px' }}
                                                                                            disabled
                                                                                        />
                                                                                    </th>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <th>
                                                                                        <input
                                                                                            type='number'
                                                                                            className='form-control rounded-0 fs-sm-14'
                                                                                            value={row?.profitPercentage}
                                                                                            style={{ width: '100px' }}
                                                                                            onChange={(e) => manageVariantPrice({ index, value: e?.target?.value > 0 ? Number(e?.target?.value) : 0, from: 'per' })}
                                                                                        />
                                                                                    </th>
                                                                                    <th>
                                                                                        <input
                                                                                            type='number'
                                                                                            className='form-control rounded-0 fs-sm-14'
                                                                                            min='0'
                                                                                            value={row?.profitAmount}
                                                                                            style={{ width: '100px' }}
                                                                                            onChange={(e) => manageVariantPrice({ index, value: e?.target?.value > 0 ? Number(e?.target?.value) : 0, from: 'amt' })}
                                                                                        />
                                                                                    </th>
                                                                                </>
                                                                            )
                                                                        }
                                                                        <th>
                                                                            <input
                                                                                className='form-control rounded-0 fs-sm-14'
                                                                                value={row?.price}
                                                                                style={{ width: '100px' }}
                                                                                disabled
                                                                            />
                                                                        </th>
                                                                        <th>
                                                                            <input
                                                                                className='form-control rounded-0 fs-sm-14'
                                                                                value={row?.mrp}
                                                                                style={{ width: '100px' }}
                                                                                onChange={(e) => manageVariantPrice({ index, value: e?.target?.value > 0 ? Number(e?.target?.value) : 0, from: 'mrp' })}
                                                                            />
                                                                        </th>
                                                                        <td>
                                                                            {
                                                                                !row?.isPrimary && (
                                                                                    <label className="switch">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={row?.isAvailable}
                                                                                            value={row?.isAvailable}
                                                                                            onChange={(e) => {
                                                                                                productForm.cartesianData[index].isAvailable = !row?.isAvailable
                                                                                                setProductForm({ ...productForm })
                                                                                            }}
                                                                                        />
                                                                                        <span className="slider"></span>
                                                                                    </label>
                                                                                )
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                </>)
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Col>
                                        )
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='rounded-0 mb-4'>
                            <CardBody>
                                <div className='d-flex gap-2 align-items-center justify-conten-end'>
                                    <Button
                                        className='text-capitalize btn btn-success rounded-0 py-2 px-3 fs-6'
                                        color='success'
                                        onClick={() => {
                                            setProductFormSubmitStatus(true)
                                            productFormSubmit()
                                        }}
                                        disabled={productFormSubmitStatus}
                                        style={{ minWidth: "139px" }}
                                    >
                                        {
                                            productFormSubmitStatus
                                                ? <Spinner animation="border" size="sm" />
                                                : "Save product"
                                        }
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </div>
    )
}

export default ProductCreate